import './devotionals.css';
import Nav from '../nav/nav';
import Footer from '../footer/footer';
import BackgroundImage from '../assets/blogs-image.jpg';
import { useEffect } from 'react';
import { apiErrorHandler, requests } from '../../api';
import { useState } from 'react';
import Loading from '../loading/loading';
import { useHistory } from 'react-router';
import jsPDF from 'jspdf';

const Devotional = () => {

    const history = useHistory();

    const [ loading, setLoading ] = useState(false);
    const [ devotionals, setDevotionals ] = useState([]);
    const [ pageSize, setPageSize ] = useState(40);

    useEffect( () => {
        
        window.scrollTo(0, 0);


        const getDevotionalsHandler = async () => {
            try{
                const response = await requests.getDevotional(1, pageSize);
                setDevotionals(response.docs)
            }catch(error){
                const { message } = apiErrorHandler(error);
            }finally{
                setLoading(true)
            }
        }

        getDevotionalsHandler();

    }, [pageSize]);

    const downloadDevotionalHandler = (devotional) => {

        const title = devotional.title;
        const image = new Image();
        const content = devotional.description;
        
        const doc = new jsPDF('landscape', 'pt', 'a4', false);
        const wrappedContent = doc.splitTextToSize(content, 700);
        const pageWidth = doc.internal.pageSize.getWidth();
        doc.addImage(BackgroundImage, 'PNG', 50, 60, 700, 400);
        doc.text( title, pageWidth / 2 , 450, 'center');
        doc.text(60, 500, wrappedContent);
        doc.save(`${title + '.pdf'}`);
    }

    return(
        <div className = 'Forms'>
            <Nav />

            <header>
                <img src = { BackgroundImage } className = 'Header-image' alt = 'The header image' />
                <h1 style = {{ backgroundColor: 'transparent' }} id = 'header-text' className = 'Expressions-header-text'>Our devotional</h1>
            </header>
            
            <div id = 'sermons-content'>

                <div id = 'sermon-buttons-holder'>
                    <button 
                        onClick = { () => {
                            history.push('/sermons');
                        }}
                        className = 'Sermons-button'
                    >
                        <i id = 'sermon-icon' className = 'fas fa-book-open'></i>
                        Sermon Library
                    </button>

                    <button 
                        onClick = { () => {
                            history.push('/music');
                        }}
                        className = 'Sermons-button'>
                        <i id = 'sermon-icon' className = 'fas fa-music'></i>
                        Music Lounge
                    </button>

                    <button 
                        onClick = { () => {
                            history.push('/devotional');
                        }} 
                        className = 'Sermons-button'
                    >
                        <i id = 'sermon-icon' className = 'fas fa-download'></i>
                        Download our 40-Day Devotional
                    </button>
                </div>

                <div id = 'sermon-main-content'>
                    <div id = 'sermon-main-content-top'>
                        <p>Our Devotional</p>
                        <div>
                            <input id = 'sermon-search' type = 'search' placeholder = 'Search' />
                            <button id = 'sermon-search-button' className = 'Nav-item Radial-button'>
                                <i id = 'sermon-icon' className = 'fas fa-search'></i>
                                Search
                            </button>
                        </div>
                    </div>
                </div>

                <div id = 'devotionals-holder'>

                    {
                        devotionals?.length > 0 && devotionals.map( devotional => {
                            return  <div 
                                        className = 'Devotional' 
                                        key = { devotional.id }
                                    >
                                        <img className = 'Devotional-image' src = { devotional.coverUri } alt = 'pictorial attachment to the devotional' />
                                        <div className = 'Devotional-sub'>
                                            <h3 className = 'Devotional-title'>{ devotional.title }</h3>
                                            <p className = 'Devotional-content'>{ devotional.description.slice(0, 500) }...</p>
                                        </div>

                                        <div id = 'download-icon-holder'>
                                            <i
                                                onClick = { () =>{
                                                //   setDownloadDevotional(devotional);
                                                downloadDevotionalHandler(devotional);
                                                }}
                                                className = 'fas fa-download Devotional-download-icon' 
                                            ></i>
                                        </div>
                                    </div>
                        } )
                    }
                    
                </div>

                <div id = 'devotional-more-holder'>
                    <button id = 'devotional-more-button' onClick = { () => setPageSize( () => pageSize + 1 ) }>
                        Show more
                    </button>
                </div>

            </div>

            <Footer />

            {
                !loading ?

                <Loading />

                : ''
            }

        </div>
    )
}

export default Devotional;