import './prayer-request.css';
import Nav from '../nav/nav';
import Footer from '../footer/footer';
import BackgroundImage from '../assets/prayer-background.PNG';
import { useEffect } from 'react';

const PrayerRequest = () => {


    useEffect( () => {
        
        window.scrollTo(0, 0);

    }, [])


    return(
        <div className = 'Contact-us'>
            <Nav />

            <header>
                <img src = { BackgroundImage } className = 'Header-image' alt = 'The header image' />
                <h1 className = 'Header-text'>Let's pray with you!</h1>
            </header>

            <div id = 'prayer-form-holder'>

                <div className = 'Prayer-input-holder'>
                    <input className = 'Prayer-input' type = 'text' placeholder = 'Name' />
                </div>

                <div className = 'Prayer-input-holder'>
                    <input className = 'Prayer-input' type = 'text' placeholder = 'Phone Number' />
                </div>

                <div id = 'textarea-holder'>
                    <textarea placeholder = 'Type your message here' id = 'contact-textarea' rows = '5' />
                </div>

                <button id = 'contact-button' className = 'Nav-item Radial-button'>SUBMIT</button>

            </div>

            <Footer />
        </div>
    )
}

export default PrayerRequest;