import './music.css';
import Footer from '../footer/footer';
import Nav from '../nav/nav';
import BackgroundImage from '../assets/music image.jpg';
import { useHistory } from 'react-router';
import { useEffect } from 'react';


const Music = () => {

    const history  = useHistory();
    
    useEffect( () => {
        
        window.scrollTo(0, 0);
        
    }, [])


    return(
        <div className = 'Sermons'>
            <Nav />

            <header>
                <img src = { BackgroundImage } className = 'Header-image' alt = 'The header image' />
                <h1 className = 'About-header-text'>Be Edified!</h1>
            </header>

            <div id = 'sermons-content'>
                <div id = 'sermon-buttons-holder'>
                    <button 
                        onClick = { () => {
                            history.push('/sermons');
                        }}
                        className = 'Sermons-button'
                    >
                        <i id = 'sermon-icon' className = 'fas fa-book-open'></i>
                        Sermon Library
                    </button>

                    <button 
                        onClick = { () => {
                            history.push('/music');
                        }}
                        className = 'Sermons-button'>
                        <i id = 'sermon-icon' className = 'fas fa-music'></i>
                        Music Lounge
                    </button>

                    <button 
                        onClick = { () => {
                            history.push('/devotional')
                        }}
                        className = 'Sermons-button'
                    >
                        <i id = 'sermon-icon' className = 'fas fa-download'></i>
                        Download our 40-Day devotional
                    </button>
                </div>

                <div id = 'sermon-main-content'>
                    <div id = 'sermon-main-content-top'>
                        <p>Featured Music</p>
                        <div>
                            <input id = 'sermon-search' type = 'search' placeholder = 'Search' />
                            <button id = 'sermon-search-button' className = 'Nav-item Radial-button'>
                                <i id = 'sermon-icon' className = 'fas fa-search'></i>
                                Search
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}


export default Music;