import './verse-of-the-day.css';
import Nav from '../../nav/nav';
import Footer from '../../footer/footer';
import { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { apiErrorHandler, requests } from '../../../api';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactHTMLParser from 'react-html-parser';
import Loading from '../../loading/loading';
import BlankModal from '../../modal/blank-modal/blank-modal';
import { useEffect } from 'react';
import { useHistory } from 'react-router';


const VerseOfTheDay = ({ user }) => {


    const [ month, setMonth ] = useState([]);

    let newMonth;
    let monthSet = useRef(new Set());
    let monthId = useRef(new Array);
    let textInput = useRef();
    let referenceInput = useRef();
    const history = useHistory();
    const monthInputRef = useRef(null);
    const [ loading, setLoading ] = useState(false);
    const [text, setText ] = useState('');
    const [reference, setReference ] = useState('');
    const [ tags, setTags ] = useState(null);
    const [ verseAdded, setVerseAdded ] = useState(false);
    const [ verseNotAdded, setVerseNotAdded ] = useState(false);


    const verseUploadHandler = async (tag) => {
        try{
            const response = await requests.postVerse({ text, reference, tags: tag });
            setVerseAdded(true);
        }catch(error){
            const { message } = apiErrorHandler(error);
            setVerseNotAdded(true);
        }finally{
            setLoading(false);
            textInput.current.value = '';
            referenceInput.current.value = '';
            setMonth([]);
        }
    }

    useEffect( () => {
        
        window.scrollTo(0, 0);

    }, []);

    return(
        <div className = 'Verse-of-the-day'>
            <Nav />

            <div id = 'user-holder'>
                <button className = 'Admin-back-button' onClick = { () => history.goBack() }>Back</button>
                <p id = 'user'>Hi, { user.firstName }</p>
            </div>

            <div className = 'Admin-content'>
                <h2 className = 'Admin-title'>Verse of the day</h2>
                {/* <textarea
                    onChange = { (e) => setText( e.target.value ) }
                    placeholder = 'Enter Verse here'
                    className = 'Admin-textarea'
                    rows = '3'
                /> */}
                <label className = 'Admin-label' htmlFor = 'first name'>Bible Verse</label>
                <CKEditor 
                    editor = { ClassicEditor }
                    onChange = { (e, editor) => {
                        const data = editor.getData();
                        // setText(data) ;
                        setText(ReactHTMLParser(data)[0]?.props?.children[0]);
                    }}
                    ref = { textInput }
                />
                <label className = 'Admin-label' htmlFor = 'first name'>Bible Reference</label>
                <input
                    onChange = { (e) => setReference(e.target.value) } 
                    className = 'Input'
                    placeholder = 'Enter bible reference here' 
                    ref = { referenceInput }
                />
                <label className = 'Admin-label' htmlFor = 'first name'>Months To Display(Add a month at a time)</label>
                <div className = 'Admin-multiple-holder'>
                    <input
                        onChange = { (e) => {
                            newMonth = e.target.value;
                        }}
                        ref = {monthInputRef}
                        className = 'Input' 
                        placeholder = 'Enter month for verse here'
                    />
                    <button
                        onClick = { (e) => {
                            if(newMonth.toLowerCase() === 'january'){
                                monthSet.current.add({ month: 'January', id: 1});
                            }else if(newMonth.toLowerCase() === 'february'){
                                monthSet.current.add({ month: 'February', id: 2});
                            }else if(newMonth.toLowerCase() === 'march'){
                                monthSet.current.add({ month: 'March', id: 3});
                            }else if(newMonth.toLowerCase() === 'april'){
                                monthSet.current.add({ month: 'April', id: 4});
                            }else if(newMonth.toLowerCase() === 'may'){
                                monthSet.current.add({ month: 'May', id: 5});
                            }else if(newMonth.toLowerCase() === 'june'){
                                monthSet.current.add({ month: 'June', id: 6});
                            }else if(newMonth.toLowerCase() === 'july'){
                                monthSet.current.add({ month: 'July', id: 7});
                            }else if(newMonth.toLowerCase() === 'august'){
                                monthSet.current.add({ month: 'August', id: 8});
                            }else if(newMonth.toLowerCase() === 'september'){
                                monthSet.current.add({ month: 'September', id: 9});
                            }else if(newMonth.toLowerCase() === 'october'){
                                monthSet.current.add({ month: 'October', id: 10});
                            }else if(newMonth.toLowerCase() === 'november'){
                                monthSet.current.add({ month: 'November', id: 11});
                            }else if(newMonth.toLowerCase() === 'december'){
                                monthSet.current.add({ month: 'December', id: 12});
                            }
                            let myArray = Array.from(monthSet.current);
                            setMonth(myArray);
                            monthId.current.push(myArray[myArray.length-1].id);
                            setTags(monthId);
                            monthInputRef.current.value = '';
                        }}
                        id = 'multiple-button' className = 'Admin-button'
                    >
                        Add
                    </button>
                </div>
                <div id = 'months-holder'>
                    {
                        month.map( (month, i) => {
                            return  <div className = 'Month-display' key = { i }>
                                        <p>{ month.month }</p>
                                        <i
                                            onClick = { () => {
                                                monthSet.current.delete(month);
                                                setMonth([ ...monthSet.current ])
                                                let currentMonth = [];
                                                currentMonth.push(monthSet.current);
                                                setTags( ...currentMonth );
                                            }}
                                            className = 'fas fa-times Delete-icon'>
                                        </i>
                                    </div>
                                    
                        })
                    }
                </div>
                <div className = 'Admin-button-holder'>
                    <button
                        onClick ={ () => {
                            verseUploadHandler(tags?.current);
                            setLoading(true);
                        }}
                        className = 'Admin-button'
                        disabled = { month.length < 1 || text === '' || reference === '' }
                        style = {  month.length < 1 || text === '' || reference === '' ? { opacity: '0.4', cursor: 'unset' } : {} }
                    >
                            Post
                    </button>
                </div>
            </div>

            <div  className = 'Admin-content'>
                <h3>Update verse</h3>
                <input className = 'Input' placeholder = 'Enter bible reference here' />
                <div className = 'Admin-button-holder'>
                    <button className = 'Admin-button'>Post</button>
                </div>
            </div>

            <Footer />

            {
                loading == true ?

                <Loading />

                : ''
            }

            {
                verseAdded == true ?

                <BlankModal>
                    <i style = {{ color: 'green' }} className = 'fas fa-check-circle Modal-icon'></i>
                    <h3 style = {{ marginTop: '1rem', marginBottom: '1rem' }}>verse Added successfully</h3>
                    <button 
                        className = 'Admin-button'
                        onClick = { () => {
                            setVerseAdded(false);
                        } }
                    >
                        close
                    </button>
                </BlankModal>

                : ''
            }

            {
                verseNotAdded == true ?

                <BlankModal>
                    <i style = {{ color: 'red' }} className = 'fas fa-exclamation Modal-icon'></i>
                    <h3 style = {{ marginTop: '1rem', marginBottom: '1rem' }}>Oooop! Error creating verse. Please try again.</h3>
                    <button 
                        className = 'Admin-button'
                        onClick = { () => {
                            setVerseNotAdded(false);
                        } }
                    >
                        close
                    </button>
                </BlankModal>

                : ''
            }
        </div>
    )
}

const mapStateToProps = ( state ) => {
        return{
            user: state.user
        }
    }

export default connect(mapStateToProps)(VerseOfTheDay);