import './naming-ceremony.css';
import Nav from '../../nav/nav';
import Footer from '../../footer/footer';
import BackgroundImage from '../../assets/naming\ ceremony\ header.jpg';
import { useEffect } from 'react';

const NamingCeremony = () => {

    
    useEffect( () => {
        
        window.scrollTo(0, 0);
        
    }, [])




    return(
        <div className = 'Naming-ceremony'>
            <Nav />

            <header>
                <img src = { BackgroundImage } className = 'Header-image' alt = 'The header image' />
                <h1 className = 'Naming-header-text'>Naming ceremony booking form</h1>
            </header>
            
            <div className = 'Form-parent'>
                <p className = 'Form-greeting'>
                    We say congratulations to you on arrival of your baby and is our Prayer that God 
                    will continue to bless and protect every member of your family in Jesus name.
                    Kindly provide us with the following details below. Thank you
                </p>

                <form className = 'Forms-form' onSubmit = { e => e.preventDefault() }>
                    <div className = 'Forms-input-holder'>
                        <input className = 'Contact-input' type = 'text' placeholder = 'Full name of the father' />
                    </div>

                    <div className = 'Forms-input-holder'>
                        <input className = 'Contact-input' type = 'text' placeholder = 'Phone number of the father' />
                    </div>

                    <div className = 'Forms-input-holder'>
                        <input className = 'Contact-input' type = 'text' placeholder = 'Full name of the mother' />
                    </div>

                    <div className = 'Forms-input-holder'>
                        <input className = 'Contact-input' type = 'text' placeholder = 'Phone number of the mother' />
                    </div>
                    
                    <div className = 'Naming-inputs-holder'>
                        <div className = 'Small-input-holder'>
                            <input className = 'Contact-input' type = 'text' placeholder = 'Date of naming ceremony' />
                        </div>
                        <div className = 'Small-input-holder-right'>
                            <input className = 'Contact-input' type = 'text' placeholder = 'Time of naming ceremony' />
                        </div>
                    </div>

                    <div className = 'Forms-input-holder'>
                        <textarea className = 'Contact-input' type = 'text' placeholder = 'Address of naming ceremony' />
                    </div>

                    <div className = 'Naming-checkbox-holder'>
                        <h3>Baby's sex:</h3> 
                        <div>
                            <input className = 'Naming-checkbox' type = 'radio' name = 'gender' />
                            <p>Male</p>
                        </div>
                        <div>
                            <input className = 'Naming-checkbox' type = 'radio' name = 'gender' />
                            <p>Female</p>
                        </div>
                    </div>

                    <p className = 'Form-greeting'>Baby's names and meaning.</p>

                    <div className = 'Naming-inputs-holder'>
                        <div className = 'Small-input-holder'>
                            <input className = 'Contact-input' type = 'text' placeholder = 'Name' />
                        </div>
                        <div className = 'Small-input-holder-right'>
                            <input className = 'Contact-input' type = 'text' placeholder = 'Meaning' />
                        </div>
                    </div>

                    <div className = 'Naming-inputs-holder'>
                        <div className = 'Small-input-holder'>
                            <input className = 'Contact-input' type = 'text' placeholder = 'Name' />
                        </div>
                        <div className = 'Small-input-holder-right'>
                            <input className = 'Contact-input' type = 'text' placeholder = 'Meaning' />
                        </div>
                    </div>

                    <div className = 'Naming-inputs-holder'>
                        <div className = 'Small-input-holder'>
                            <input className = 'Contact-input' type = 'text' placeholder = 'Name' />
                        </div>
                        <div className = 'Small-input-holder-right'>
                            <input className = 'Contact-input' type = 'text' placeholder = 'Meaning' />
                        </div>
                    </div>

                    <button className = 'Experience-button' id = 'naming-submit-button'>Submit</button>

                    
                </form>
            </div>

            <Footer />
        </div>
    )
}

export default NamingCeremony;