import './become-a-member.css';
import Nav from '../../nav/nav';
import Footer from '../../footer/footer';
import BackgroundImage from '../../assets/become a member image.jpg';
import { useEffect } from 'react';

const BecomeAMember = () => {


    useEffect( () => {
        
        window.scrollTo(0, 0);
        
    }, [])


    return(
        <div className = 'Baby-dedication'>
            <Nav />

            <header>
                <img src = { BackgroundImage } className = 'Header-image' alt = 'The header image' />
                <h1 className = 'Forms-header-text'>We are glad to recieve you</h1>
            </header>
            
            <div className = 'Form-parent'>
                <p className = 'Form-greeting'>
                    Welcome to Jubilee Christian Center. We are a parish of the redeemed christian church of God. We are glad you have 
                    decided to be a member of the family.
                </p>

                <form className = 'Forms-form' onSubmit = { e => e.preventDefault() }>
                    <div className = 'Forms-input-holder'>
                        <input className = 'Contact-input' type = 'text' placeholder = 'Name' />
                    </div>

                    <div className = 'Forms-input-holder'>
                        <input className = 'Contact-input' type = 'text' placeholder = 'example@gmail.com' />
                    </div>

                    <div className = 'Forms-input-holder'>
                        <input className = 'Contact-input' type = 'text' placeholder = 'Phone number' />
                    </div>

                    <div className = 'Forms-input-holder'>
                        <textarea className = 'Contact-input' type = 'text' placeholder = 'House address' />
                    </div>

                    <div className = 'Naming-checkbox-holder'>
                        <h3>Gender:</h3>
                        <div>
                            <input className = 'Naming-checkbox' type = 'radio' name = 'gender' />
                            <p>Male</p>
                        </div>
                        <div>
                            <input className = 'Naming-checkbox' type = 'radio' name = 'gender' />
                            <p>Female</p>
                        </div>
                    </div>



                    <button className = 'Experience-button' id = 'naming-submit-button'>Submit</button>

                    
                </form>
            </div>

            <Footer />
        </div>
    )
}

export default BecomeAMember;