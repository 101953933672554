import './accept-admin-invite.css';
import Nav from '../../nav/nav';
import Footer from '../../footer/footer';
import { useState} from 'react';
import { apiErrorHandler, auth } from '../../../api';
import Loading from '../../loading/loading';
import BlankModal from '../../modal/blank-modal/blank-modal';
import { useHistory } from 'react-router';
import { useEffect } from 'react';


const AccepptAdminInvitation = (props) => {

    const history = useHistory();

    const [ loading, setLoading ] = useState(false);
    const [ acceptLoading, setAcceptLoading ] = useState(false);
    const [ verifiedInvitation, setVerifiedInvitation ] = useState(false);
    const [ accepted, setAccepted ] = useState(false);
    const [ notAccepted, setNotAccepted ] = useState(false);
    const [ verifyBody ] = useState({
        email: props?.match?.params?.email,
        token: props?.match?.params?.token
    });
    const [ acceptInvitationBody, setAcceptInvitationBody ] = useState({
        token: props?.match?.params?.token,
        firstName: "",
        lastName: "",
        email: props?.match?.params?.email,
        password: "",
        mobilePhone: ""
    })
    const [ confirmPassword, setConfirmPassword ] = useState('');
 
    useEffect( () => {

        window.scrollTo(0, 0);


        const verifyInviteHandler = async () => {
            try{
                const response = await auth.verifyInvite(verifyBody);
            }catch(error){
                const { message } = apiErrorHandler(error);
                setVerifiedInvitation(true);
            }finally{
                setLoading(true);
            }
        }

        verifyInviteHandler();

    }, [])

    const acceptInvitationHandler = async () => {
        try{
            const response = await auth.acceptInvitation(acceptInvitationBody);
            setAccepted(true);
        }catch(error){
            const { message } = apiErrorHandler(error);
            setNotAccepted(true);
        }finally{
            setAcceptLoading(false);
        }
    }


    return(
        <div className = 'Verse-of-the-day'>
            <Nav />

            <div className = 'Admin-content'>
                <h2 className = 'Admin-title'>Accept Invitation</h2>
                <input
                    onChange = { (e) => {
                        setAcceptInvitationBody({
                            ...acceptInvitationBody, firstName: e.target.value
                        })
                        
                    } } 
                    className = 'Input'
                    placeholder = 'Enter first name' 
                />
                <input
                    onChange = { (e) =>{
                        setAcceptInvitationBody({
                            ...acceptInvitationBody, lastName: e.target.value
                        })

                    } } 
                    className = 'Input'
                    placeholder = 'Enter last name' 
                />
                <input
                    onChange = { (e) =>{
                        setAcceptInvitationBody({
                            ...acceptInvitationBody, mobilePhone: e.target.value
                        })

                    } } 
                    className = 'Input'
                    placeholder = 'Enter mobile number' 
                    type = 'text'
                />
                <input
                    onChange = { (e) =>{
                        setAcceptInvitationBody({
                            ...acceptInvitationBody, password: e.target.value
                        })

                    } } 
                    className = 'Input'
                    placeholder = 'Enter password' 
                    type = 'password'
                />
                <input
                    onChange = { (e) => {
                        setConfirmPassword(e.target.value);
                    } } 
                    className = 'Input'
                    type = 'password'
                    placeholder = 'Confirm password' 
                />
               
                <div className = 'Admin-button-holder'>
                    <button
                        onClick ={ () => {
                            acceptInvitationHandler();
                            setAcceptLoading(true);

                        }}
                        disabled = { confirmPassword !== acceptInvitationBody.password || acceptInvitationBody.password === '' }
                        style = { confirmPassword !== acceptInvitationBody.password || acceptInvitationBody.password === '' ? { opacity: '0.4' } : {} }
                        className = 'Admin-button'>Accept Invitation</button>
                </div>
            </div>




            <Footer />

            {
                !loading ?

                <Loading />

                : ''
            }

            {
                acceptLoading == true ?

                <Loading />

                : ''
            }

            {
                accepted == true ?

                <BlankModal>
                    <i style = {{ color: 'green' }} className = 'fas fa-check-circle Modal-icon'></i>
                    <h3 style = {{ marginTop: '1rem', marginBottom: '1rem' }}>Invitation accepted successfully</h3>
                    <button 
                        className = 'Admin-button'
                        onClick = { () => {
                            history.push('/adminlogin')
                        } }
                    >
                        Proceed to login
                    </button>
                </BlankModal>

                : ''
            }

            {
                notAccepted == true ?

                <BlankModal>
                    <i style = {{ color: 'red' }} className = 'fas fa-exclamation Modal-icon'></i>
                    <h3 style = {{ marginTop: '1rem', marginBottom: '1rem' }}>Oooop! Error accepting invitation. Please try again.</h3>
                    <button 
                        className = 'Admin-button'
                        onClick = { () => {
                            setNotAccepted(false);
                        } }
                    >
                        close
                    </button>
                </BlankModal>

                : ''
            }

            
            {
                verifiedInvitation === true ?

                <BlankModal>
                    <i style = {{ color: 'red' }} className = 'fas fa-exclamation Modal-icon'></i>
                    <h3 style = {{ marginTop: '1rem', marginBottom: '1rem' }}>Oooop! Error verifying invitation.</h3>
                    <button 
                        className = 'Admin-button'
                        onClick = { () => {
                            // history.push('/')
                        } }
                    >
                        Resend email
                    </button>
                </BlankModal>

                : ''
            }
        </div>
    )
}

export default AccepptAdminInvitation