import './events.css';
import Nav from '../../nav/nav';
import Footer from '../../footer/footer';
import { useState } from 'react';
import { useRef } from 'react';
import { apiErrorHandler, requests } from '../../../api';
import Loading from '../../loading/loading';
import BlankModal from '../../modal/blank-modal/blank-modal';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { useHistory } from 'react-router';


const AdminEvents = ({user}) => {

    let eventNameInput = useRef();
    let eventDescriptionInput = useRef();
    let eventVenueInput = useRef();
    let eventDateInput = useRef();
    let eventTimeInput = useRef();
    let eventTagInput = useRef();
    let eventScheduleInput = useRef();
    let orderDateInput = useRef();
    let orderTitleInput = useRef();
    let orderTimeInput = useRef();
    let orderDurationInput = useRef();
    const history = useHistory();
    const [ loading, setLoading ] = useState(false);
    const [ eventAdded, setEventAdded ] = useState(false);
    const [ date, setDate ] = useState('');
    const [ orderDate, setOrderDate ] = useState('');
    const [ eventRepeat ] = useState([
        'daily', 'weekly', 'monthly', 'yearly', 'none'
    ]);
    const [ eventTag] = useState([
        'church service', 'comedy show', 'stage play', 'worship service',
         'christmas carol service', 'digging deep', 'prayer meeting'
    ]);
    const [ eventNotAdded, setEventNotAdded ] = useState(false);
    const [ myOrders, setMyOrders ] = useState([]);
    const [ orderObject, setOrderObject ] = useState({
        title: '',
        time: '',
        duration: null
    })
    const [ eventBody, setEventBody ] = useState({
        name: "",
        description: "",
        venue: "",
        date: '',
        tag: "",
        schedule: {
          repeat: ""
        },
        order: []
    });


    const eventUploadHandler = async () => {
        try{
            const response = await requests.PostEvent(eventBody);
            setEventAdded(true);
        }catch(error){
            const { message } = apiErrorHandler(error);
            setEventNotAdded(true);
        }finally{
            setLoading(false);
            eventNameInput.current.value = '';
            eventDescriptionInput.current.value = '';
            eventVenueInput.current.value = '';
            eventDateInput.current.value = '';
            eventTimeInput.current.value = '';
            eventTagInput.current.value = '';
            eventScheduleInput.current.value = '';
            orderDateInput.current.value = '';    
            orderTimeInput.current.value = '';    
        }
    }

    useEffect( () => {
        
        window.scrollTo(0, 0);

    }, []);


    return(
        <div className = 'Verse-of-the-day'>
            <Nav />

            <div id = 'user-holder'>
                <button className = 'Admin-back-button' onClick = { () => history.goBack() }>Back</button>
                <p id = 'user'>Hi, { user?.firstName }</p>
            </div>

            <div className = 'Admin-content'>
                <h2 className = 'Admin-title'>Jubilee events</h2>
                <label className = 'Admin-label' htmlFor = 'first name'>Name</label>
                <input
                    onChange = { (e) => setEventBody({ ...eventBody, name: e.target.value}) } 
                    className = 'Input'
                    placeholder = 'Enter event name here' 
                    ref = { eventNameInput }
                />
                <label className = 'Admin-label' htmlFor = 'first name'>Description</label>
                <input
                    onChange = { (e) => setEventBody({ ...eventBody, description: e.target.value}) } 
                    className = 'Input'
                    placeholder = 'Enter description here' 
                    ref = { eventDescriptionInput }
                />
                <label className = 'Admin-label' htmlFor = 'first name'>Venue</label>
                <input
                    onChange = { (e) => setEventBody({ ...eventBody, venue: e.target.value}) } 
                    className = 'Input'
                    placeholder = 'Enter venue here' 
                    ref = { eventVenueInput }
                />
                <label className = 'Admin-label' htmlFor = 'first name'>Date</label>
                <input
                    onChange = { (e) => setDate(e.target.value)} 
                    className = 'Input'
                    type = 'date'
                    ref = { eventDateInput }
                />
                <label className = 'Admin-label' htmlFor = 'first name'>Time</label>
                <input
                    onChange = { (e) => {
                        let myTime = new Date(date + ' ' + e.target.value + ' ' + 'UTC');
                        setEventBody({
                            ...eventBody, date: myTime.toISOString()
                        })
                    }} 
                    className = 'Input'
                    type = 'time'
                    ref = { eventTimeInput }
                />
                <label className = 'Admin-label' htmlFor = 'first name'>Tag</label>
                <div id = 'drop-down'>
                    <div id = 'select-holder'>
                        
                            <select name = 'cards' className = 'Input'
                                onChange = { (e) => {
                                    setEventBody({ ...eventBody, tag: e.target.value})  
                                }}
                                ref = { eventTagInput }
                            >
                                    <option value= '' >Select event tag</option>
                                    { 
                                        eventTag?.length > 0 && eventTag.map( (tag, index) => (
                                            <option
                                                key={ index }
                                                value={ tag }
                                            >
                                                { tag }
                                            </option>
                                        ))
                                    }
                            </select>

                        
                    </div>
                    <div id = 'arrow'></div>
                </div>
                <label className = 'Admin-label' htmlFor = 'first name'>Re-occurence</label>
                <div id = 'drop-down'>
                    <div id = 'select-holder'>
                        
                            <select name = 'cards' className = 'Input'
                                onChange = { (e) => {
                                    setEventBody({ ...eventBody, schedule: {
                                        repeat: e.target.value
                                    }})  
                                }}
                                ref = { eventScheduleInput }
                            >
                                    <option value= '' >Select event re-occurence</option>
                                    { 
                                        eventRepeat?.length > 0 && eventRepeat.map( (repeat, index) => (
                                            <option
                                                key={ index }
                                                value={ repeat }
                                            >
                                                { repeat }
                                            </option>
                                        ))
                                    }
                            </select>

                        
                    </div>
                    <div id = 'arrow'></div>
                </div>

                <div id = 'event-order'>
                    <h5 id = 'event-order-title'>Order of event</h5>
                    
                    <label className = 'Admin-label' htmlFor = 'first name'>Order</label>
                    <input
                        onChange = { (e) => {
                            setOrderObject({
                                ...orderObject, title: e.target.value
                            })
                        }}
                        className = 'Input'
                        placeholder = 'Enter order title here' 
                        ref = { orderTitleInput }
                    />
                    
                    <label className = 'Admin-label' htmlFor = 'first name'>Date</label>
                    <input
                        onChange = { (e) => setOrderDate(e.target.value)} 
                        className = 'Input'
                        type = 'date'
                        ref = { orderDateInput }
                    />

                    <label className = 'Admin-label' htmlFor = 'first name'>Time</label>
                    <input
                        onChange = { (e) => {
                            let myTime = new Date(orderDate + ' ' + e.target.value + ' ' + 'UTC');
                            setOrderObject({
                                ...orderObject, time: myTime.toISOString()
                            })
                        }} 
                        className = 'Input'
                        type = 'time'
                        ref = { orderTimeInput }
                    />

                    <label className = 'Admin-label' htmlFor = 'first name'>Duration</label>   
                    <input
                        onChange = { (e) => setOrderObject({ ...orderObject, duration: Number(e.target.value) }) } 
                        className = 'Input'
                        type = "number"
                        placeholder = 'Enter duration in minutes' 
                        ref = { orderDurationInput }
                    />

                    <div className = 'Admin-button-holder'>
                        <button
                            onClick ={ () => {
                                setMyOrders([
                                    ...myOrders, orderObject
                                ])
                                setEventBody({
                                    ...eventBody, order: [
                                        myOrders
                                    ]
                                });
                                orderTitleInput.current.value = '';      
                                orderDurationInput.current.value = '';    
                            }}
                            id = 'multiple-button'
                            className = 'Admin-button'
                        >
                            Add order item
                        </button>
                    </div>

                </div>

                <div className = 'Admin-button-holder'>
                    <button
                        onClick ={ () => {
                            eventUploadHandler();
                            setLoading(true);

                        }}
                        className = 'Admin-button'
                        disabled = { eventBody.name === '' || eventBody.description === '' || eventBody.venue === '' || eventBody.date === '' || eventBody.tag === '' || eventBody.schedule.repeat === '' }
                        style = { eventBody.name === '' || eventBody.description === '' || eventBody.venue === '' || eventBody.date === '' || eventBody.tag === '' || eventBody.schedule.repeat === '' ? { opacity: '0.4', cursor: 'unset' } : {} }
                    >
                        Schedule Event
                    </button>
                </div>
            </div>


            <Footer />

            {
                loading == true ?

                <Loading />

                : ''
            }

            {
                eventAdded == true ?

                <BlankModal>
                    <i style = {{ color: 'green' }} className = 'fas fa-check-circle Modal-icon'></i>
                    <h3 style = {{ marginTop: '1rem', marginBottom: '1rem' }}>Event created successfully!</h3>
                    <button 
                        className = 'Admin-button'
                        onClick = { () => {
                            setEventAdded(false);
                        } }
                    >
                        close
                    </button>
                </BlankModal>

                : ''
            }

            {
                eventNotAdded == true ?

                <BlankModal>
                    <i style = {{ color: 'red' }} className = 'fas fa-exclamation Modal-icon'></i>
                    <h3 style = {{ marginTop: '1rem', marginBottom: '1rem' }}>Oooop! Error creating event. Please try again.</h3>
                    <button 
                        className = 'Admin-button'
                        onClick = { () => {
                            setEventNotAdded(false);
                        } }
                    >
                        close
                    </button>
                </BlankModal>

                : ''
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return{
        user: state.user
    }
}

export default connect(mapStateToProps)(AdminEvents);