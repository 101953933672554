import './baby-dedication.css';
import Nav from '../../nav/nav';
import Footer from '../../footer/footer';
import BackgroundImage from '../../assets/baby\ dedication\ header.jpg';
import { useEffect, useState } from 'react';
import { requests, apiErrorHandler } from '../../../api';

const BabyDedication = () => {

    const [ loader, setLoader ] = useState(false);
    const [ dateJoinedJCCType, setDateJoinedJCCType ] = useState('text');
    const [ dateOfDedicationType, setDateOfDedicationType ] = useState('text');
    const [ babyName, setBabyName ] = useState({
        name: '',
        meaning: ''
    })
    const [ dedicationData, setDedicationData ] = useState({
        father: {
            name: "",
            phone: ""
          },
        mother: {
            name: "",
            phone: ""
        },
        sex: "",
        hasInvitees: true,
        marriageType: "",
        names: [],
        groupLeader: {
            name: "",
            phone: ""
        },
        isLegallyMarried: true
    })

    useEffect( () => {
        
        // This will let the component load from the top rather than from bottom
        window.scrollTo(0, 0);
        
    }, [])

    const babyDedicationHandler = async () => {
        setLoader(true);
        try{
            const response = await requests.postForms('BabyDedication', dedicationData)
        }catch(error){
            const { message } = apiErrorHandler(error);
        }finally{
            setLoader(false);
        }
    }


    return(
        <div className = 'Baby-dedication'>
            <Nav />

            <header>
                <img src = { BackgroundImage } className = 'Header-image' alt = 'The header image' />
                <h1 className = 'Forms-header-text'>Baby dedication booking form</h1>
            </header>
            
            <div className = 'Form-parent'>
                <p className = 'Form-greeting'>
                    We say congratulations to you on arrival of your baby and is our Prayer that God will
                    continue to bless and protect every member of your family in Jesus name. Kindly provide 
                    us with the following details below. Thank you.
                </p>

                <form className = 'Forms-form' onSubmit = { e => e.preventDefault() }>
                    <div className = 'Forms-input-holder'>
                        <input 
                            onChange = { (e) => {
                                setDedicationData({
                                    ...dedicationData, father: {
                                        name: e.target.value
                                    }
                                })
                            }}
                            className = 'Contact-input' 
                            type = 'text' 
                            placeholder = 'Full name of the father' 
                        />
                    </div>

                    <div className = 'Forms-input-holder'>
                        <input
                            onChange = { (e) => {
                                setDedicationData({
                                    ...dedicationData, mother: {
                                        name: e.target.value
                                    }
                                })
                            }}
                            className = 'Contact-input' 
                            type = 'text' 
                            placeholder = 'Full name of the mother' 
                        />
                    </div>

                    <p className = 'Form-greeting Form-extra-title'>Baby's names and meaning.</p>

                    <div className = 'Naming-inputs-holder'>
                        <div className = 'Small-input-holder'>
                            <input
                                onChange = { (e) => {
                                    setDedicationData({
                                        ...dedicationData, names: [
                                            
                                        ]
                                    })
                                }}
                                className = 'Contact-input' 
                                type = 'text' 
                                placeholder = 'Name' 
                            />
                        </div>
                        <div className = 'Small-input-holder-right'>
                            <input className = 'Contact-input' type = 'text' placeholder = 'Meaning' />
                        </div>
                    </div>

                    <div className = 'Naming-inputs-holder'>
                        <div className = 'Small-input-holder'>
                            <input className = 'Contact-input' type = 'text' placeholder = 'Name' />
                        </div>
                        <div className = 'Small-input-holder-right'>
                            <input className = 'Contact-input' type = 'text' placeholder = 'Meaning' />
                        </div>
                    </div>

                    <div className = 'Naming-inputs-holder'>
                        <div className = 'Small-input-holder'>
                            <input className = 'Contact-input' type = 'text' placeholder = 'Name' />
                        </div>
                        <div className = 'Small-input-holder-right'>
                            <input className = 'Contact-input' type = 'text' placeholder = 'Meaning' />
                        </div>
                    </div>

                    <div className = 'Naming-checkbox-holder'>
                        <h3>Baby's sex:</h3>
                        <div>
                            <input 
                                onChange = { (e) => {
                                    setDedicationData({
                                        ...dedicationData, sex: 'Male'
                                    })
                                }}
                                className = 'Naming-checkbox' 
                                type = 'radio' 
                                name = 'gender' 
                            />
                            <p>Male</p>
                        </div>
                        <div>
                            <input 
                                onChange = { (e) => {
                                    setDedicationData({
                                        ...dedicationData, sex: 'Female'
                                    })
                                }}
                                className = 'Naming-checkbox' 
                                type = 'radio' 
                                name = 'gender' 
                            />
                            <p>Female</p>
                        </div>
                    </div>

                    <p className = 'Form-greeting'>Other information.</p>

                    <div className = 'Forms-input-holder'>
                        <input 
                            onFocus = { () => {
                                setDateOfDedicationType('date');
                            }}
                            className = 'Contact-input' 
                            type = { dateOfDedicationType }
                            placeholder = 'Date of dedication' 
                        />
                    </div>

                    <div className = 'Forms-input-holder'>
                        <input 
                            onChange = { (e) => {
                                setDedicationData({
                                    ...dedicationData, father: {
                                        phone: e.target.value
                                    }
                                })
                            }}
                            className = 'Contact-input'
                            type = 'text'
                            placeholder = 'Phone number of father'
                        />
                    </div>

                    <div className = 'Forms-input-holder'>
                        <input 
                            onChange = { (e) => {
                                setDedicationData({
                                    ...dedicationData, mother: {
                                        phone: e.target.value
                                    }
                                })
                            }}
                            className = 'Contact-input' 
                            type = 'text' 
                            placeholder = 'Phone number of mother' 
                        />
                    </div>

                    <div className = 'Forms-input-holder'>
                        <input 
                            onFocus = { () => {
                                setDateJoinedJCCType('date');
                            }}
                            className = 'Contact-input' 
                            type = { dateJoinedJCCType } 
                            placeholder = 'Date of joining jubilee christian center'
                        />
                    </div>

                    <div className = 'Forms-input-holder'>
                        <input 
                            onChange = { (e) => {
                                setDedicationData({
                                    ...dedicationData, groupLeader: {
                                        name: e.target.value
                                    }
                                })
                            }}
                            className = 'Contact-input' 
                            type = 'text' 
                            placeholder = 'Name of connect group leader' 
                        />
                    </div>

                    <div className = 'Forms-input-holder'>
                        <input 
                            onChange = { (e) => {
                                setDedicationData({
                                    ...dedicationData, groupLeader: {
                                        phone: e.target.value
                                    }
                                })
                            }}
                            className = 'Contact-input' 
                            type = 'text' 
                            placeholder = 'Phone number of connect group leader' 
                        />
                    </div>
                    



                    <div className = 'Naming-checkbox-holder'>
                        <h3>Are you legally married:</h3>
                        <div>
                            <input 
                                onChange = { (e) => {
                                    setDedicationData({
                                        ...dedicationData, isLegallyMarried: true
                                    })
                                }}
                                className = 'Naming-checkbox'
                                type = 'radio' 
                                name = 'gender' 
                            />
                            <p>Yes</p>
                        </div>
                        <div>
                            <input 
                                onChange = { (e) => {
                                    setDedicationData({
                                        ...dedicationData, isLegallyMarried: false
                                    })
                                }}
                                className = 'Naming-checkbox'
                                type = 'radio' 
                                name = 'gender' 
                            />
                            <p>No</p>
                        </div>
                    </div>

                    <div className = 'Naming-checkbox-holder'>
                        <h3>Type of marriage:</h3>
                        <div>
                            <input  
                                onChange = { () => {
                                    setDedicationData({
                                        ...dedicationData, marriageType: 'Church'
                                    });
                                }}
                                className = 'Naming-checkbox' 
                                type = 'radio'
                                name = 'gender' 
                            />
                            <p>Church</p>
                        </div>
                        <div>
                            <input  
                                onChange = { () => {
                                    setDedicationData({
                                        ...dedicationData, marriageType: 'Traditional'
                                    });
                                }}
                                className = 'Naming-checkbox' 
                                type = 'radio' 
                                name = 'gender' 
                            />
                            <p>Traditional</p>
                        </div>
                        <div>
                            <input 
                                onChange = { () => {
                                    setDedicationData({
                                        ...dedicationData, marriageType: 'Other'
                                    });
                                }}
                                className = 'Naming-checkbox' 
                                type = 'radio' 
                                name = 'gender' 
                            />
                            <p>Other</p>
                        </div>
                    </div>

                    <div className = 'Naming-checkbox-holder'>
                        <h3>Do you have invitees for the dedication:</h3>
                        <div>
                            <input 
                                onChange = { () => {
                                    setDedicationData({
                                        ...dedicationData, hasInvitees: true
                                    });
                                }}
                                className = 'Naming-checkbox' 
                                type = 'radio' 
                                name = 'gender' 
                            />
                            <p>Yes</p>
                        </div>
                        <div>
                            <input 
                                onChange = { () => {
                                    setDedicationData({
                                        ...dedicationData, hasInvitees: false
                                    });
                                }}
                                className = 'Naming-checkbox' 
                                type = 'radio' 
                                ame = 'gender' 
                            />
                            <p>No</p>
                        </div>
                    </div>


                    <button 
                        onClick = { () => {
                            babyDedicationHandler();
                        }}
                        className = 'Experience-button' 
                        id = 'naming-submit-button'
                    >
                        Submit
                    </button>

                    
                </form>
            </div>

            <Footer />
        </div>
    )
}

export default BabyDedication;