import { SET_USER, LOGOUT } from '../constants/constants';

const initialState = {
    user: null
}

const reducer = ( state = initialState, action ) => {
    switch (action.type) {
        case SET_USER:
          return{
              ...state, user: action.payload
          };
        case LOGOUT:
        return { ...initialState };
        default:
            return state ;
    }

}

export default reducer;