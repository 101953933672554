import './blog.css';
import Nav from '../../nav/nav';
import Footer from '../../footer/footer';
import { useState, useEffect } from 'react';
import { useRef } from 'react';
import { apiErrorHandler, requests } from '../../../api';
import Loading from '../../loading/loading';
import BlankModal from '../../modal/blank-modal/blank-modal';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactHTMLParser from 'react-html-parser';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';


const Blog = ({user}) => {

    let categoryInput = useRef();
    let blogTitleInput = useRef();
    let blogContentInput = useRef();
    let blogDescriptionInput = useRef();
    let blogAuthorInput = useRef();
    let blogCategoryInput = useRef();
    let blogImageInput = useRef();
    const history = useHistory();
    const [ image, setImage ] = useState();
    const [ blogCategory, setBlogCategory ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ imageError , setImageError ] = useState(false);
    const [ blogAdded, setBlogAdded ] = useState(false);
    const [ blogNotAdded, setBlogNotAdded ] = useState(false);
    const [ blogCategoryData, setBlogCategoryData ] = useState({
        title: ''
    });
    const [ blogBody, setBlogBody ] = useState({
        title: "",
        content: "",
        category: "",
        coverUri: "",
        description: "",
        author: ''
    });

    let mediaType = 'cover';
    let categoryList;

    useEffect( () => {

        window.scrollTo(0, 0);

        const getBlogCategoryHandler = async () => {
            try{
                const response = await requests.getBlogPostCategory();
                categoryList = response.docs;
                setBlogCategory(categoryList);
            }catch(error){  
                const { message } = apiErrorHandler(error)
            }
        }

        getBlogCategoryHandler();
    }, []);

    const blogUploadHandler = async () => {
        try{
            const response = await requests.postBlog(blogBody);
            setBlogAdded(true);
        }catch(error){
            const { message } = apiErrorHandler(error);
            setBlogNotAdded(true);
        }finally{
            setLoading(false);
            categoryInput.current.value = '';
            blogTitleInput.current.value = '';
            blogContentInput.current.value = '';
            blogDescriptionInput.current.value = '';
            blogImageInput.current.value = '';
            blogAuthorInput.current.value = '';
            blogCategoryInput.current.value = '';
        }
    }

    const blogCategoryUploadHandler = async () => {
        try{
            const response = await requests.PostBlogCategory(blogCategoryData);
        }catch(error){
            const { message } = apiErrorHandler(error)
        }finally{
            categoryInput.current.value = '';
        }
    }

    const generateMediaURiHandler = async (image) => {

        const formData = new FormData()
            formData.append(
            "file",
            image
        )

        try{
            const response = await requests.generateMediaUri(mediaType, formData)
            setBlogBody({ ...blogBody, coverUri: response.data.resourceUrl });
            setImageError(false);
        }catch(error){
            const { message } = apiErrorHandler(error);
            setImageError(true);
        }
    }


    return(
        <div className = 'Verse-of-the-day'>
            <Nav />

            <div id = 'user-holder'>
                <button className = 'Admin-back-button' onClick = { () => history.goBack() }>Back</button>
                <p id = 'user'>Hi, { user?.firstName }</p>
            </div>

            <div className = 'Admin-content'>
                <h2 className = 'Admin-title'>Jubilee Blog</h2>
                <label className = 'Admin-label' htmlFor = 'first name'>Title</label>
                <input
                    onChange = { (e) => setBlogBody({ ...blogBody, title: e.target.value}) } 
                    className = 'Input'
                    placeholder = 'Enter blog title here' 
                    ref = { blogTitleInput }
                />
                {/* <textarea
                    onChange = { (e) => setBlogBody({ ...blogBody, content: e.target.value}) }
                    placeholder = 'Enter blog content here'
                    className = 'Admin-textarea'
                    rows = '10'
                    ref = { blogContentInput }
                /> */}
                <label className = 'Admin-label' htmlFor = 'first name'>Content</label>
                <CKEditor 
                    editor = { ClassicEditor }
                    onChange = { (e, editor) => {
                        const data = editor.getData();
                        setBlogBody({ ...blogBody, content: ReactHTMLParser(data)[0]?.props?.children[0]}) ;
                    }}
                />
                <label className = 'Admin-label' htmlFor = 'first name'>Category</label>
                <div id = 'drop-down'>
                    <div id = 'select-holder'>
                        
                            <select name = 'cards' className = 'Input'
                                onChange = { (e) => {
                                    setBlogBody({ ...blogBody, category: e.target.value });
                                }}
                                
                                ref = { blogCategoryInput }
                            >
                                    <option value= '' >Select category...</option>
                                    { 
                                        blogCategory?.length > 0 && blogCategory.map( category => (
                                            <option
                                                key={ category._id }
                                                value={ category._id }
                                            >
                                                { category.title }
                                            </option>
                                        ))
                                    }
                            </select>

                        
                    </div>
                    <div id = 'arrow'></div>
                </div>
                <label className = 'Admin-label' htmlFor = 'first name'>Author</label>
                <input
                    onChange = { (e) => setBlogBody({ ...blogBody, author: e.target.value}) } 
                    className = 'Input'
                    placeholder = 'Enter author here' 
                    ref = { blogAuthorInput }
                />
                <label className = 'Admin-label' htmlFor = 'first name'>Description</label>
                <input
                    onChange = { (e) => setBlogBody({ ...blogBody, description: e.target.value}) } 
                    className = 'Input'
                    placeholder = 'Enter description here' 
                    ref = { blogDescriptionInput }
                />
                <label className = 'Admin-label' htmlFor = 'first name'>Cover image</label>
                <div className = 'Admin-multiple-holder'>
                    <input
                        onChange = { (e) => setImage( e.target.files[0] )}
                        className = 'Input' 
                        type = 'file'
                        ref = { blogImageInput }
                    />
                    <button
                        onClick = { () => {
                          generateMediaURiHandler(image);
                        }}
                        id = 'multiple-button' className = 'Admin-button'
                    >
                        Upload
                    </button>
                </div>
                {
                    imageError === true ?

                    <p id = 'fellowship-image-error'>Image size should not exceed 2MB.</p>

                    : ''
                }
                <p style = {{ textAlign: 'start', fontSize: 'inherit' }}>{ `${ blogBody.coverUri !== '' ?  '1 Image added' : 'No image added' }`}</p>
                { blogBody.coverUri}
                <div className = 'Admin-button-holder'>
                    <button
                        onClick ={ () => {
                            blogUploadHandler();
                            setLoading(true);

                        }}
                        className = 'Admin-button'
                        disabled = { blogBody.title === '' || blogBody.category === '' || blogBody.author === '' || blogBody.description === '' || blogBody.content === '' || blogBody.coverUri === '' }
                        style = { blogBody.title === '' || blogBody.category === '' || blogBody.author === '' || blogBody.description === '' || blogBody.content === '' || blogBody.coverUri === '' ? { opacity: '0.4', cursor: 'unset' } : {} }
                    >
                        Post blog 
                    </button>
                </div>
            </div>




            <div className = 'Admin-content-sub'>
                <h2 className = 'Admin-title'>Blog Category</h2>
                <input
                    ref = { categoryInput }
                    onChange = { (e) => setBlogCategoryData({ ... blogCategoryData, title: e.target.value}) } 
                    className = 'Input'
                    placeholder = 'Enter category title here' 
                />
                <div className = 'Admin-button-holder'>
                    <button
                        onClick ={ () => {
                            blogCategoryUploadHandler();
                        }}
                        className = 'Admin-button'
                        disabled = { blogCategoryData.title === '' }
                        style = { blogCategoryData.title === '' ? { opacity: '0.4', cursor: 'unset' } : {} }
                    >
                        Post blog category
                    </button>
                </div>
            </div>

            <Footer />

            {
                loading == true ?

                <Loading />

                : ''
            }

            {
                blogAdded == true ?

                <BlankModal>
                    <i style = {{ color: 'green' }} className = 'fas fa-check-circle Modal-icon'></i>
                    <h3 style = {{ marginTop: '1rem', marginBottom: '1rem' }}>Blog Added successfully</h3>
                    <button 
                        className = 'Admin-button'
                        onClick = { () => {
                            setBlogAdded(false);
                        } }
                    >
                        close
                    </button>
                </BlankModal>

                : ''
            }

            {
                blogNotAdded == true ?

                <BlankModal>
                    <i style = {{ color: 'red' }} className = 'fas fa-exclamation Modal-icon'></i>
                    <h3 style = {{ marginTop: '1rem', marginBottom: '1rem' }}>Oooop! Error creating blog. Please try again.</h3>
                    <button 
                        className = 'Admin-button'
                        onClick = { () => {
                            setBlogNotAdded(false);
                        } }
                    >
                        close
                    </button>
                </BlankModal>

                : ''
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return{
        user: state.user
    }
}

export default connect(mapStateToProps)(Blog);