import './gallery-slider.css';
import { useState, useEffect } from 'react';
import { apiErrorHandler, requests } from '../../../api';

const GallerySlider = () => {

    const [ showGallery, setShowGallery ] = useState(true);
    const [ images, setImages ] = useState([]);
    const [ index, setIndex ] = useState(0);

    useEffect( () => {

        const getImagesHandler = async () => {
            try{
                const response = await requests.getGallery();
                setImages(response.docs)
            }catch(error){
                const { message } = apiErrorHandler(error);
            }
        }

        getImagesHandler();

    }, [])

    const nextImageHandler = () => {
        let nextImage = images.shift();
        images.push(nextImage);
        setIndex( index + 1 );
    }



    const previousImageHandler = () => {
        let previousImage = images.pop();
        images.unshift(previousImage);
        setIndex( index + 1 );
    }
    

    return(
        <div className = 'Gallery-slider'
            onMouseEnter = { () => {
                setShowGallery(false);
            } }
            onMouseLeave = { () => {
                setShowGallery(true);
            } }
        >
            <div 
                id = 'gallery-images-holder'
            >

                {
                    images?.length > 0 && images.map( (image, i) => {
                        return  <img 
                                    key = { i } 
                                    className = 'Gallery-image' 
                                    src = { image.uri } 
                                    alt = 'Gallery image' 
                                    style = { !images ? { display: 'none' } : {}}
                                />
                    })
                }

            </div>

            <div id = 'gallery-navigator-holder'>
                <i onClick = { previousImageHandler } id = 'gallery-navigator-icon-left' className = 'fas fa-chevron-left Gallery-navigator-icon'></i>
                <i onClick = { nextImageHandler } id = 'gallery-navigator-icon-right' className = 'fas fa-chevron-right Gallery-navigator-icon'></i>
            </div>

            {
                showGallery ?

                <div id = 'gallery-layer'>
                    <h1>From Our Gallery</h1>
                </div>

                : ''
            }

        </div>
    )
}

export default GallerySlider;