import './google-map.css';
import  { GoogleMap, withGoogleMap, withScriptjs } from 'react-google-maps';


const Map = () => {
    return(
        <div>
            <GoogleMap 
                defaultZoom = { 10 }
                defaultCenter = {{ lat: 6.49242922397002, lng: 3.3453826764275805 }}
            />
        </div>
    )
}


export default withScriptjs(withGoogleMap(Map));