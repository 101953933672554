import Nav from '../../nav/nav';
import Footer from '../../footer/footer';
import { useState} from 'react';
import { useRef } from 'react';
import { apiErrorHandler, auth } from '../../../api';
import Loading from '../../loading/loading';
import BlankModal from '../../modal/blank-modal/blank-modal';
import { useHistory } from 'react-router';
import { useEffect } from 'react';

const RequestToken = () => {

    const history = useHistory();

    let emailInput = useRef();
    const [ loading, setLoading ] = useState(false);
    const [ tokenSent, setTokenSent] = useState(false);
    const [ tokenNotSent, setTokenNotSent ] = useState(false);
    const [ requestTokenBody, setRequestTokenBody ] = useState({
        email: ''
    })

    const RequestTokenHandler = async () => {
        try{
            const response = await auth.requestToken(requestTokenBody);
            setTokenSent(true);
        }catch(error){
            const { message } = apiErrorHandler(error);
            setTokenNotSent(true);
        }finally{
            emailInput.current.value = '';
            setLoading(false);
        }
    }
    
    useEffect( () => {
        
        window.scrollTo(0, 0);

    }, []);

    return(
        <div className = 'Verse-of-the-day'>
            <Nav />

            <div className = 'Admin-content'>
                <h2 className = 'Admin-title'>Password Reset</h2>
                <input
                    onChange = { (e) => {
                        setRequestTokenBody({
                            ...requestTokenBody, email: e.target.value
                        })
                    } } 
                    className = 'Input'
                    placeholder = 'Enter email here' 
                    ref = { emailInput }
                />
                <div className = 'Admin-button-holder'>
                    <button
                        onClick ={ () => {
                            RequestTokenHandler();
                            setLoading(true);

                        }}
                        disabled = { requestTokenBody.email  === '' }
                        style = { requestTokenBody.email  === '' ? { opacity: '0.4' } : {} }
                        className = 'Admin-button'>Request Token</button>
                </div>
            </div>




            <Footer />

            {
                loading == true ?

                <Loading />

                : ''
            }

            {
                tokenSent == true ?

                <BlankModal>
                    <i style = {{ color: 'green' }} className = 'fas fa-check-circle Modal-icon'></i>
                    <h3 style = {{ marginTop: '1rem', marginBottom: '1rem' }}>Password reset request successful</h3>
                    <p>An email has been sent to your mailbox</p>
                    <button 
                        className = 'Admin-button'
                        onClick = { () => {
                            history.push('/adminlogin')
                        } }
                    >
                        close
                    </button>
                </BlankModal>

                : ''
            }

            {
                tokenNotSent == true ?

                <BlankModal>
                    <i style = {{ color: 'red' }} className = 'fas fa-exclamation Modal-icon'></i>
                    <h3 style = {{ marginTop: '1rem', marginBottom: '1rem' }}>Oooop! Error requesting password reset. Please try again.</h3>
                    <button 
                        className = 'Admin-button'
                        onClick = { () => {
                            setTokenNotSent(false);
                        } }
                    >
                        close
                    </button>
                </BlankModal>

                : ''
            }

        </div>
    )
}

export default RequestToken;