export default class UserRequests {

    #httpClient;
  
    constructor(baseClient) {
      this.#httpClient = baseClient;
    }
  
    async getPastors() {
      return this.#httpClient.get('/pastorate/');
    }

    async createPastor(payload) {
        return this.#httpClient.post('/pastorate', payload);
    }

    async generateMediaUri(type, payload) {
        return this.#httpClient.post(`/media/upload/${type}`, payload);
    }

    async makePayment(payload) {
        return this.#httpClient.post('/payments/initiate', payload);
    }

    async getOfferingAccounts() {
        return this.#httpClient.get('/settlements/accounts');
    }

    async getBlogPostCategory() {
        return this.#httpClient.get('/blogCategories');
    }

    async postBlog(payload) {
        return this.#httpClient.post('/blogs', payload);
    }

    async getBlogPosts() {
        return this.#httpClient.get('/blogs');
    }

    async PostBlogCategory(payload) {
        return this.#httpClient.post('/blogCategories', payload);
    }

    async PostFellowships(payload) {
        return this.#httpClient.post('/fellowships', payload);
    }

    async getFellowships() {
        return this.#httpClient.get('/fellowships');
    }

    async getMediaOfTheWeek(type) {
        return this.#httpClient.get(`/mediaClips/ofTheWeek/${type}`);
    }

    async postGallery(payload) {
        return this.#httpClient.post('/gallery', payload);
    }

    async getGallery() {
        return this.#httpClient.get('/gallery');
    }

    async getGallery() {
        return this.#httpClient.get('/gallery');
    }

    async PostEvent(payload) {
        return this.#httpClient.post('/events', payload);
    }

    async getEvent() {
        return this.#httpClient.get('/events');
    }
  
    async postDevotional(payload) {
        return this.#httpClient.post('/devotionals', payload);
    }

    async getDevotional(page, pageSize) {
        return this.#httpClient.get(`/devotionals?page=${page}&pageSize=${pageSize}`);
    }

    async postVerse(payload) {
        return this.#httpClient.post('/bibleVerses', payload);
    }

    async getAllUsers() {
        return this.#httpClient.get(`/admin/users`);
    }

    async postForms(category, payload) {
        return this.#httpClient.post(`/forms/${category}`, payload);
    }
}