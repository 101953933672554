import './giving.css';
import Nav from '../nav/nav';
import Footer from '../footer/footer';
import BackgroundImage from '../assets/giving background.jpg';
import { useHistory } from 'react-router-dom';
import { apiErrorHandler, requests } from '../../api';
import BlankModal from '../modal/blank-modal/blank-modal';
import { useState } from 'react';
import { useEffect } from 'react';
import Loading from '../loading/loading';

const Giving = () => {

    const history = useHistory();
    const [ modalSwitch, setModalSwitch ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ accounts, setAccounts ] = useState([]);
    const [ clicked, setClicked ] = useState(false);
    const [ paymentDetail, setPaymentDetail ] = useState({
        amount: 0,
        accountId: "",
        email: "",
        name: ""
    })

    useEffect( () => {
        
        window.scrollTo(0, 0);


        const getAccountsHandler = async () => {
            try{
                const response = await requests.getOfferingAccounts();
                setAccounts(response.data);
            }catch(error){
                const { message } = apiErrorHandler(error);
            }finally{
                setLoading(true);
            }
        }

        getAccountsHandler();

    }, [])

    const makePaymentHandler = async () => {
        try{
            const response = await requests.makePayment(paymentDetail);
            const route = response.data.instrument.action;
            let url = '';
            window.location.replace(route);
        }catch(error){
            const { message } = apiErrorHandler(error);
        }
    }

    return(
        <div className = 'Forms'>
            <Nav />

            <header>
                <img src = { BackgroundImage } className = 'Header-image' alt = 'The header image' />
                <h1 style = {{ color: '#3A2490' }} className = 'Expressions-header-text'>Open heavens!</h1>
            </header>
            
            <div id = 'form-holder-parent'>

                {
                    accounts?.length > 0 && accounts.map( (account, index) => {
                        return  <div 
                                    onClick = { () => {
                                        setPaymentDetail({
                                            ...paymentDetail, accountId: account.accountId
                                        });
                                        setModalSwitch(true);
                                    }}
                                    key = { index } 
                                    className = 'Sermons-button'
                                >
                                    {
                                        account.name.toLowerCase().includes('offering') ?
                                        
                                        <i id = 'sermon-icon' className = 'fas fa-hand-holding-usd'></i>

                                        : 

                                        account.name.toLowerCase().includes('project') ?
                                        
                                        <i id = 'sermon-icon' className = 'fas fa-building'></i>

                                        :

                                        account.name.toLowerCase().includes('tithe') ?

                                        <i id = 'sermon-icon' className = 'fas fa-donate'></i>

                                        : 

                                        account.name.toLowerCase().includes('covenant') ?

                                        <i id = 'sermon-icon' className = 'fas fa-archive'></i>

                                        : ''
                                    }
                                    { account.name }
                                </div>
                    })
                }

            </div>

            <Footer />

            {
                modalSwitch == true ?

                <BlankModal>
                    <div id = 'giving-close-icon-holder'>
                        <i id = 'giving-close-icon' onClick = { () => setModalSwitch(false) } className = 'fas fa-times'></i>
                    </div>
                    <h3 id = 'giving-payment-title'>Payment Details</h3>
                    <div id = 'giving-forms-holder'>
                        <div className = 'Forms-input-holder'>
                            <input
                                onChange = { (e) => {
                                    setPaymentDetail( {
                                    ...paymentDetail, amount: Number(e.target.value)
                                    }) 
                                }} 
                                className = 'Contact-input' 
                                type = 'number' 
                                placeholder = 'Amount (NGN)' 
                            />
                        </div>

                        <div className = 'Forms-input-holder'>
                            <input 
                                onChange = { (e) => {
                                    setPaymentDetail( {
                                    ...paymentDetail, email: e.target.value
                                    }) 
                                }}
                                className = 'Contact-input' 
                                type = 'text' 
                                placeholder = 'example@gmail.com'
                            />
                        </div>
                    </div>

                    <button
                        onClick = { () => {
                            makePaymentHandler();
                            setClicked(true);
                        }}
                        disabled = { paymentDetail.email === '' || paymentDetail.amount === 0 || clicked == true }
                        style = { paymentDetail.email === '' || paymentDetail.amount === 0 || clicked == true ? { opacity: '0.4' } :  {} }
                        className = 'Experience-button' 
                        id = 'naming-submit-button'
                    >
                        Submit
                    </button>
                </BlankModal>

                : ''
            }
            

            {
                !loading ?

                <Loading />

                : ''
            }
        </div>
    )
}

export default Giving;