import './users.css';
import Nav from '../../nav/nav';
import Footer from '../../footer/footer';
import { connect } from 'react-redux';
import Loading from '../../loading/loading';
import BlankModal from '../../modal/blank-modal/blank-modal';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { apiErrorHandler, requests } from '../../../api';


const AdminUsers = ({ user }) => {

    const history = useHistory();

    const [ userRemoved, setUserRemoved ] = useState(false);
    const [ userNotRemoved, setUserNotRemoved ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ users, setUsers ] = useState([]);
    const [ removeUser, setRemoveUser ] = useState(false);
    const [ removeUserLoader, setRemoveUserLoader ] = useState(false);


    const removeUserHandler = async () => {
        try{

        }catch(error){
            const message = apiErrorHandler(error);
        }finally{
            setRemoveUserLoader(false);
        }
    }

    useEffect( () => {
        
        window.scrollTo(0, 0);

        const getUsersHandler = async () => {
            try{
                const response = await requests.getAllUsers();
                setUsers(response.docs);
            }catch(error){
                const { message } = apiErrorHandler(error);
            }finally{
                setLoading(true);
            }
        }

        getUsersHandler();

    }, []);

    return(
        <div className = 'Verse-of-the-day'>
            <Nav />

            <div id = 'user-holder'>
                <button className = 'Admin-back-button' onClick = { () => history.goBack() }>Back</button>
                <p id = 'user'>Hi, { user?.firstName }</p>
            </div>

            <div className = 'Admin-content'>
                <div id = 'users-header'>
                    <p className = 'Name-holder'>NAME</p>
                    <p className = 'Mobile-holder'>PHONE NUMBER</p>
                    <button className = 'Delete-holder' style = {{ visibility: 'hidden' }}></button>
                </div>
                {
                    users.length > 0 && users.map( user => {
                        return  <div 
                                    key = {user.id}
                                    className = 'Admin-user'
                                >
                                    <p className = 'Name-holder'>{ `${user.firstName} ${user.lastName}` }</p>

                                    <p className = 'Mobile-holder'>{ user.profile.mobilePhone }</p>

                                    <button 
                                        onClick = { () => setRemoveUser(true) }
                                        className = 'Remove-user-button Delete-holder'
                                    >
                                        Remove
                                    </button>
                                </div>
                    } )
                }
            </div>

            <Footer />

            {
                !loading ?

                <Loading />

                : ''
            }

            {
                removeUserLoader === true ?

                <Loading />

                : ''
            }

            {
                userRemoved == true ?

                <BlankModal>
                    <i style = {{ color: 'green' }} className = 'fas fa-check-circle Modal-icon'></i>
                    <h3 style = {{ marginTop: '1rem', marginBottom: '1rem' }}>User profile deleted successfully</h3>
                    <button 
                        className = 'Admin-button'
                        onClick = { () => {
                            setUserRemoved(false);
                        } }
                    >
                        close
                    </button>
                </BlankModal>

                : ''
            }

            {
                userNotRemoved == true ?

                <BlankModal>
                    <i style = {{ color: 'red' }} className = 'fas fa-exclamation Modal-icon'></i>
                    <h3 style = {{ marginTop: '1rem', marginBottom: '1rem' }}>Oooop! Error deleting user profile. Please try again.</h3>
                    <button 
                        className = 'Admin-button'
                        onClick = { () => {
                            setUserNotRemoved(false);
                        } }
                    >
                        close
                    </button>
                </BlankModal>

                : ''
            }

            {
                removeUser === true ?

                <BlankModal>
                    <p>Are you sure you want to remove user?</p>
                    <div id = 'user-modal-buttons-holder'>
                        <button
                            onClick = { () => setRemoveUser(false) }
                            className = 'Remove-user-button'
                        >
                            No
                        </button>
                        <button 
                            onClick = { () => {
                                removeUserHandler();
                                setRemoveUser(false);
                                setRemoveUserLoader(true);
                            }}
                            id = 'yes-remove-user'
                        >
                            Yes, I am
                        </button>
                    </div>
                </BlankModal>

                : ''
            }
        </div>
    )
}

const mapStateToProps = ( state ) => {
    return{
        user: state.user
    }
}

export default connect(mapStateToProps)(AdminUsers);