import './workers.css';
import Nav from '../../nav/nav';
import Footer from '../../footer/footer';
import BackgroundImage from '../../assets/forms\ header.jpg';
import { useEffect, useState, useRef } from 'react';
import { apiErrorHandler, requests } from '../../../api';

const PotentialWorker = () => {

    const [ maritalStatus ] = useState([
        'Single', 'Divorced', 'Married', 'Separated', 'Widowed', 'Engaged'
    ]);
    const [ otherQualifications, setOtherQualifications ] = useState([ '' ]);
    const [ talent, setTalent ] = useState([]);
    const [ qualificationRemoved, setQualificationRemoved ] = useState(false);
    const [ tags, setTags ] = useState(null);
    const [ newWorker, setNewWorker ] = useState({
        firstName: "",
        lastName: "",
        otherNames: "",
        nationality: "",
        occupation: "",
        officeAddress: "",
        email: "",
        mobilePhone: "",
        telephone: "",
        maritalStatus: "",
        residentialAddress: "",
        spouse: {
            name: "",
            occupation: "",
            yearsOfMarriage: 0
        },
        nextOfKin: {
            name: "",
            relationship: ""
        },
        education: {
            primary: {
            institution: "",
            qualification: ""
            },
            secondary: {
            institution: "",
            qualification: ""
            },
            tertiary: {
            institution: "",
            qualification: ""
            },
            others: [
                {
                    institution: "",
                    qualification: ""
                }
            ]
        },
        isSaved: true,
        salvationExperience: "",
        isSaisBaptizedInWaterImmersion: true,
        isBaptizedInHolyGhost: true,
        attendedOtherChurches: true,
        attendedChurches: [
            {
                name: "",
                role: ""
            }
        ],
        giftsAndTallents: [
            ""
        ],
        personalities: [
            ""
        ]
    });

    let newTalent;
    let talentSet = useRef(new Set());
    let talentId = useRef(new Array);
    const talentInputRef = useRef(null);

    useEffect( () => {
        
        window.scrollTo(0, 0);
        
    }, []);

    useEffect( () => {
        //This will rerender the page on removing a qualification
    }, [qualificationRemoved]);

    const postWorker = async () => {
        try{
            const response = await requests.postForms('', newWorker);
        }catch(error){
            const message = apiErrorHandler(error);
        }
    }



    return(
        <div className = 'Baby-dedication'>
            <Nav />

            <header>
                <img src = { BackgroundImage } className = 'Header-image' alt = 'The header image' />
                <h1 className = 'Forms-header-text'>We are glad to recieve you!</h1>
            </header>
            
            <div className = 'Form-parent'>
                <p className = 'Form-greeting'>
                    Welcome to the work force of RCCG Jubilee Christian Center.
                </p>

                <form className = 'Forms-form' onSubmit = { e => e.preventDefault() }>

                    <p className = 'Form-greeting'>Personal details</p>

                    <div className = 'Naming-inputs-holder'>
                        <div className = 'Small-input-holder'>
                            <input 
                                onChange = { e => setNewWorker({ ...newWorker, lastName: e.target.value }) }
                                className = 'Contact-input' type = 'text' placeholder = 'Surname' 
                            />
                        </div>
                        <div className = 'Small-input-holder-right'>
                            <input 
                                onChange = { e => setNewWorker({ ...newWorker, firstName: e.target.value }) }
                                className = 'Contact-input' type = 'text' placeholder = 'Given names' 
                            />
                        </div>
                    </div>

                    {/* Joshua did not create a value for DOB */}
                    <div style = {{ width: '100%' }} className = 'Naming-checkbox-holder'>
                        <h3>Date of birth:</h3>
                        <div className = 'Forms-input-holder'>
                            <input className = 'Contact-input' type = 'date' />
                        </div>
                    </div>

                    {/* Joshua did not create a value for place of birth */}
                    <div className = 'Naming-inputs-holder'>
                        <div className = 'Small-input-holder'>
                            <input className = 'Contact-input' type = 'text' placeholder = 'Place of birth' />
                        </div>
                        <div className = 'Small-input-holder-right'>
                            <input 
                                onChange = { e => setNewWorker({ ...newWorker, nationality: e.target.value }) }
                                className = 'Contact-input' type = 'text' placeholder = 'Nationality' 
                            />
                        </div>
                    </div>

                    <div className = 'Forms-input-holder'>
                        <input 
                            onChange = { e => setNewWorker({ ...newWorker, occupation: e.target.value }) }
                            className = 'Contact-input' type = 'text' placeholder = 'Occupation' 
                        />
                    </div>

                    <div className = 'Forms-input-holder'>
                        <textarea 
                            onChange = { e => setNewWorker({ ...newWorker, officeAddress: e.target.value }) }
                            className = 'Contact-input' type = 'text' placeholder = 'Office address' 
                        />
                    </div>

                    <div className = 'Forms-input-holder'>
                        <input 
                            onChange = { e => setNewWorker({ ...newWorker, email: e.target.value }) }
                            className = 'Contact-input' type = 'text' placeholder = 'Email address' 
                        />
                    </div>

                    <div className = 'Naming-inputs-holder'>
                        <div className = 'Small-input-holder'>
                            <input 
                                onChange = { e => setNewWorker({ ...newWorker, telephone: e.target.value }) }
                                className = 'Contact-input' type = 'number' placeholder = 'Telephone number (Landline)' 
                            />
                        </div>
                        <div className = 'Small-input-holder-right'>
                            <input 
                                onChange = { e => setNewWorker({ ...newWorker, mobilePhone: e.target.value }) }
                                className = 'Contact-input' type = 'number' placeholder = 'Mobile number' 
                            />
                        </div>
                    </div>

                    <div className = 'Forms-input-holder'>
                        <textarea 
                            onChange = { e => setNewWorker({ ...newWorker, residentialAddress: e.target.value }) }
                            className = 'Contact-input' type = 'text' placeholder = 'Residential address' 
                        />
                    </div>

                    <p className = 'Form-greeting Form-extra-title'>Family</p>
                    
                    <div className = 'Naming-checkbox-holder'>
                        <h3>Marital status:</h3>
                        {
                            maritalStatus?.length > 0 && maritalStatus.map( status => {
                                return  <div>
                                            <input 
                                                onChange = { () => setNewWorker({ ...newWorker, maritalStatus: status }) }
                                                className = 'Naming-checkbox' type = 'radio' name = 'gender' 
                                            />
                                            <p>{ status }</p>
                                        </div>
                            })
                        }
                    </div>

                    {
                        newWorker.maritalStatus.toLowerCase() === '' ?

                        ''

                        :

                        newWorker.maritalStatus.toLowerCase() === 'married' ?

                        <div style = {{ width: '100%' }}>

                            <div className = 'Forms-input-holder'>
                                <input 
                                    onChange = { e => setNewWorker({ ...newWorker, spouse: {
                                        ...newWorker.spouse, name: e.target.value
                                    } }) }
                                    className = 'Contact-input' type = 'text' placeholder = 'Name of spouse' 
                                />
                            </div>
        
                            <div className = 'Forms-input-holder'>
                                <input 
                                    onChange = { e => setNewWorker({ ...newWorker, spouse: {
                                        ...newWorker.spouse, occupation: e.target.value
                                    } }) }
                                    className = 'Contact-input' type = 'text' placeholder = "Spouse's Occupation" 
                                />
                            </div>
                            <div className = 'Forms-input-holder'>
                                <input 
                                    onChange = { e => setNewWorker({ ...newWorker, spouse: {
                                        ...newWorker.spouse, yearsOfMarriage: Number(e.target.value)
                                    } }) }
                                    className = 'Contact-input' type = 'number' placeholder = 'If married, for how long?' 
                                />
                            </div>

                            {/*  Joshua did not create value for how many children the person has  */}
                            <div className = 'Forms-input-holder'>
                                <input className = 'Contact-input' type = 'text' placeholder = 'Do you have children? If yes, how many?' />
                            </div>

                        </div>

                        :

                        <div style = {{ width: '100%' }}>

                            <div className = 'Forms-input-holder'>
                                <input 
                                    onChange = { e => setNewWorker({ ...newWorker, nextOfKin: {
                                        ...newWorker.nextOfKin, name: e.target.value
                                    } }) }
                                    className = 'Contact-input' type = 'text' placeholder = 'If not married, please give name of next of kin' 
                                />
                            </div>

                            <div className = 'Forms-input-holder'>
                                <input 
                                    onChange = { e => setNewWorker({ ...newWorker, nextOfKin: {
                                        ...newWorker.nextOfKin, relationship: e.target.value
                                    } }) }
                                    className = 'Contact-input' type = 'text' placeholder = 'Relationship to next of kin ( sibling, cousin, friend etc. )' 
                                />
                            </div>

                        </div>
                    }



                    <p className = 'Form-greeting Form-extra-title'>Educational background.</p>
                    

                    <div className = 'Naming-inputs-holder'>
                        <div className = 'Small-input-holder'>
                            <input 
                                onChange = { e => setNewWorker({ ...newWorker, education: {
                                    ...newWorker.education, primary: {
                                        ...newWorker.education.primary, institution: e.target.value
                                    }
                                } }) }
                                className = 'Contact-input' type = 'text' placeholder = 'Primary school' 
                            />
                        </div>

                        {/* Joshua did not provide value for the year attended/graduated */}
                        <div className = 'Small-input-holder-right'>
                            <input className = 'Contact-input' type = 'text' placeholder = 'Year attended ( Start to End )' />
                        </div>
                    </div>

                    <div className = 'Naming-inputs-holder'>
                        <div className = 'Small-input-holder'>
                            <input 
                                onChange = { e => setNewWorker({ ...newWorker, education: {
                                    ...newWorker.education, secondary: {
                                        ...newWorker.education.secondary, institution: e.target.value
                                    }
                                } }) }
                                className = 'Contact-input' type = 'text' placeholder = 'Secondary school' 
                            />
                        </div>

                        {/* Joshua did not provide value for the year attended/graduated */}
                        <div className = 'Small-input-holder-right'>
                            <input className = 'Contact-input' type = 'text' placeholder = 'Year attended ( Start to End )' />
                        </div>
                    </div>

                    <div className = 'Naming-inputs-holder'>
                        <div className = 'Small-input-holder'>
                            <input 
                                onChange = { e => setNewWorker({ ...newWorker, education: {
                                    ...newWorker.education, tertiary: {
                                        ...newWorker.education.tertiary, institution: e.target.value
                                    }
                                } }) }
                                className = 'Contact-input' type = 'text' placeholder = 'Tertiary institution' 
                            />
                        </div>

                        {/* Joshua did not provide value for the year attended/graduated */}
                        <div className = 'Small-input-holder-right'>
                            <input className = 'Contact-input' type = 'text' placeholder = 'Year attended ( Start to End )' />
                        </div>
                    </div>

                    <p className = 'Form-greeting Form-extra-title'>Please include other institutions attended with qualifications.</p>
                    
                    <div style = {{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                        {
                            otherQualifications?.length > 0 && otherQualifications.map( (qualification, i) => {
                                return  <div style = {{ width: '100%' }}>
                                            <div className = 'Naming-inputs-holder'>
                                                <div className = 'Small-input-holder'>
                                                    <input className = 'Contact-input' type = 'text' placeholder = 'Tertiary institution' />
                                                </div>
                                                <div className = 'Small-input-holder-right'>
                                                    <input className = 'Contact-input' type = 'text' placeholder = 'Qualification' />
                                                </div>
                                            </div>
                                            {
                                                otherQualifications.length > 1 ?

                                                <p 
                                                    onClick = { () => {
                                                        otherQualifications.splice(i, 1);
                                                        setQualificationRemoved(!qualificationRemoved);
                                                    }}
                                                    id = 'minus-button' 
                                                >Remove</p>

                                                : ''

                                            }
                                        </div>
                            } )
                        }

                        <i 
                            onClick = { () => {
                                setOtherQualifications([
                                    ...otherQualifications, ''
                                ])
                            }}
                            id = 'add-button' 
                            className = 'fas fa-plus'
                        ></i>
                    </div>

                    {/* <div className = 'Naming-inputs-holder'>
                        <div className = 'Small-input-holder'>
                            <input className = 'Contact-input' type = 'text' placeholder = 'Tertiary institution' />
                        </div>
                        <div className = 'Small-input-holder-right'>
                            <input className = 'Contact-input' type = 'text' placeholder = 'Qualification' />
                        </div>
                    </div>
                    
                    <div className = 'Naming-inputs-holder'>
                        <div className = 'Small-input-holder'>
                            <input className = 'Contact-input' type = 'text' placeholder = 'Tertiary institution' />
                        </div>
                        <div className = 'Small-input-holder-right'>
                            <input className = 'Contact-input' type = 'text' placeholder = 'Qualification' />
                        </div>
                    </div>
                    
                    <div className = 'Naming-inputs-holder'>
                        <div className = 'Small-input-holder'>
                            <input className = 'Contact-input' type = 'text' placeholder = 'Tertiary institution' />
                        </div>
                        <div className = 'Small-input-holder-right'>
                            <input className = 'Contact-input' type = 'text' placeholder = 'Qualification' />
                        </div>
                    </div> */}

                    <p className = 'Form-greeting Form-extra-title'>Information about spiritual life.</p>

                    <div className = 'Naming-checkbox-holder'>
                        <h3>Have you recieved Jesus as your personal lord and savior?</h3>
                        <div>
                            <input 
                                onChange = { () => setNewWorker({ ...newWorker, isSaved: true }) }
                                className = 'Naming-checkbox' type = 'radio' name = 'saved' 
                            />
                            <p>Yes</p>
                        </div>
                        <div>
                            <input 
                                onChange = { () => setNewWorker({ ...newWorker, isSaved: false }) }
                                className = 'Naming-checkbox' type = 'radio' name = 'saved' 
                            />
                            <p>No</p>
                        </div>
                    </div>

                    <div className = 'Forms-input-holder'>
                        <input className = 'Contact-input' type = 'text' placeholder = 'If yes, when?' />
                    </div>

                    <div className = 'Forms-input-holder'>
                        <textarea 
                            onChange = { e => setNewWorker({ ...newWorker, salvationExperience: e.target.value }) }
                            className = 'Contact-input' type = 'text' placeholder = 'Briefly articulate your salvation experience' 
                        />
                    </div>

                    <div className = 'Naming-checkbox-holder'>
                        <h3>Have you been baptized in water by immersion?</h3>
                        <div>
                            <input 
                                onChange = { () => setNewWorker({ ...newWorker, isSaisBaptizedInWaterImmersion: true }) }
                                className = 'Naming-checkbox' type = 'radio' name = 'baptized by water immersion'
                            />
                            <p>Yes</p>
                        </div>
                        <div>
                            <input 
                                onChange = { () => setNewWorker({ ...newWorker, isSaisBaptizedInWaterImmersion: false }) }
                                className = 'Naming-checkbox' type = 'radio' name = 'baptized by water immersion' 
                            />
                            <p>No</p>
                        </div>
                    </div>

                <div className = 'Naming-checkbox-holder'>
                    <h3>Are you baptized in the holy ghost with the evidence of speaking in tongues?</h3>
                    <div>
                        <input 
                            onChange = { () => setNewWorker({ ...newWorker, isBaptizedInHolyGhost: true }) }
                            className = 'Naming-checkbox' type = 'radio' name = 'baptized in the holyghost' 
                        />
                        <p>Yes</p>
                    </div>
                    <div>
                        <input 
                            onChange = { () => setNewWorker({ ...newWorker, isBaptizedInHolyGhost: false }) }
                            className = 'Naming-checkbox' type = 'radio' name = 'baptized in the holyghost'
                        />
                        <p>No</p>
                    </div>
                </div>

                {/* Joshua did not provide value for this */}
                <div className = 'Forms-input-holder'>
                    <input className = 'Contact-input' type = 'text' placeholder = 'When did you join Jubilee Christian center?' />
                </div>

                <div className = 'Naming-checkbox-holder'>
                    <h3>Have you attended other churches other than Jubilee?</h3>
                    <div>
                        <input 
                            onChange = { () => setNewWorker({ ...newWorker, attendedOtherChurches: true }) }
                            className = 'Naming-checkbox' type = 'radio' name = 'attended other churches' 
                        />
                        <p>Yes</p>
                    </div>
                    <div>
                        <input 
                            onChange = { () => setNewWorker({ ...newWorker, attendedOtherChurches: false }) }
                            className = 'Naming-checkbox' type = 'radio' name = 'attended other churches' 
                        />
                        <p>No</p>
                    </div>
                </div>

                <p className = 'Form-greeting Form-extra-title'>If yes, please fill in the following details.</p>

                <div className = 'Naming-inputs-holder'>
                    <div className = 'Small-input-holder'>
                        <input className = 'Contact-input' type = 'text' placeholder = 'Church' />
                    </div>
                    <div className = 'Small-input-holder-right'>
                        <input className = 'Contact-input' type = 'text' placeholder = 'Year' />
                    </div>
                    <div className = 'Small-input-holder-right'>
                        <input className = 'Contact-input' type = 'text' placeholder = 'Role' />
                    </div>
                </div>

                <div className = 'Naming-inputs-holder'>
                    <div className = 'Small-input-holder'>
                        <input className = 'Contact-input' type = 'text' placeholder = 'Church' />
                    </div>
                    <div className = 'Small-input-holder-right'>
                        <input className = 'Contact-input' type = 'text' placeholder = 'Year' />
                    </div>
                    <div className = 'Small-input-holder-right'>
                        <input className = 'Contact-input' type = 'text' placeholder = 'Role' />
                    </div>
                </div>

                <div className = 'Naming-inputs-holder'>
                    <div className = 'Small-input-holder'>
                        <input className = 'Contact-input' type = 'text' placeholder = 'Church' />
                    </div>
                    <div className = 'Small-input-holder-right'>
                        <input className = 'Contact-input' type = 'text' placeholder = 'Year' />
                    </div>
                    <div className = 'Small-input-holder-right'>
                        <input className = 'Contact-input' type = 'text' placeholder = 'Role' />
                    </div>
                </div>

                <div className = 'Naming-inputs-holder'>
                    <div className = 'Small-input-holder'>
                        <input className = 'Contact-input' type = 'text' placeholder = 'Church' />
                    </div>
                    <div className = 'Small-input-holder-right'>
                        <input className = 'Contact-input' type = 'text' placeholder = 'Year' />
                    </div>
                    <div className = 'Small-input-holder-right'>
                        <input className = 'Contact-input' type = 'text' placeholder = 'Role' />
                    </div>
                </div>

                <div className = 'Forms-input-holder'>
                    <input 
                        className = 'Contact-input' type = 'text' placeholder = 'Which specific giftings and/or talents do you possess?' 
                    />
                    <div id = 'months-holder'>
                        {
                            talent.map( (talent, i) => {
                                return  <div className = 'Month-display' key = { i }>
                                            <p>{ talent }</p>
                                            <i
                                                onClick = { () => {
                                                    talentSet.current.delete(talent);
                                                    setTalent([ ...talentSet.current ])
                                                    let currentTalent = [];
                                                    currentTalent.push(talentSet.current);
                                                    setTags( ...currentTalent );
                                                }}
                                                className = 'fas fa-times Delete-icon'>
                                            </i>
                                        </div>
                                        
                            })
                        }
                    </div>
                    <i
                        onClick = { () => {
                            let myArray = Array.from(talentSet.current);
                            setTalent(myArray);
                            talentId.current.push(myArray[myArray.length-1].id);
                            setTags(talentId);
                            talentInputRef.current.value = '';
                        }}
                        className = 'fas fa-plus'
                        id = 'add-button'
                    > 
                    </i>
                </div>

                <p className = 'Form-greeting Form-extra-title'>Please tick any of the following words that you believe best describe you.</p>

                <div className = 'Personality-checkbox-holder'>
                    <div>
                        <input className = 'Personality-checkbox' type = 'checkbox' />
                        <p>Active</p>
                    </div>
                    <div>
                        <input className = 'Personality-checkbox' type = 'checkbox' />
                        <p>Active</p>
                    </div>
                    <div>
                        <input className = 'Personality-checkbox' type = 'checkbox' />
                        <p>Impulsive</p>
                    </div>
                    <div>
                        <input className = 'Personality-checkbox' type = 'checkbox' />
                        <p>Easy-going</p>
                    </div>
                    <div>
                        <input className = 'Personality-checkbox' type = 'checkbox' />
                        <p>Shy</p>
                    </div>
                    <div>
                        <input className = 'Personality-checkbox' type = 'checkbox' />
                        <p>Thick-skinned</p>
                    </div>
                    <div>
                        <input className = 'Personality-checkbox' type = 'checkbox' />
                        <p>Calm</p>
                    </div>
                    <div>
                        <input className = 'Personality-checkbox' type = 'checkbox' />
                        <p>Impatient</p>
                    </div>
                    <div>
                        <input className = 'Personality-checkbox' type = 'checkbox' />
                        <p>Serious</p>
                    </div>
                    <div>
                        <input className = 'Personality-checkbox' type = 'checkbox' />
                        <p>Quiet</p>
                    </div>
                    <div>
                        <input className = 'Personality-checkbox' type = 'checkbox' />
                        <p>Leader</p>
                    </div>
                    <div>
                        <input className = 'Personality-checkbox' type = 'checkbox' />
                        <p>Hardworking</p>
                    </div>
                    <div>
                        <input className = 'Personality-checkbox' type = 'checkbox' />
                        <p>Likable</p>
                    </div>
                    <div>
                        <input className = 'Personality-checkbox' type = 'checkbox' />
                        <p>Lonely</p>
                    </div>
                    <div>
                        <input className = 'Personality-checkbox' type = 'checkbox' />
                        <p>Nervous</p>
                    </div>
                    <div>
                        <input className = 'Personality-checkbox' type = 'checkbox' />
                        <p>Imaginative</p>
                    </div>
                    <div>
                        <input className = 'Personality-checkbox' type = 'checkbox' />
                        <p>Extrovert</p>
                    </div>
                    <div>
                        <input className = 'Personality-checkbox' type = 'checkbox' />
                        <p>Self-conscious</p>
                    </div>
                    <div>
                        <input className = 'Personality-checkbox' type = 'checkbox' />
                        <p>Persistent</p>
                    </div>
                    <div>
                        <input className = 'Personality-checkbox' type = 'checkbox' />
                        <p>Excitable</p>
                    </div>
                    <div>
                        <input className = 'Personality-checkbox' type = 'checkbox' />
                        <p>Introvert</p>
                    </div>
                    <div>
                        <input className = 'Personality-checkbox' type = 'checkbox' />
                        <p>Sensitive</p>
                    </div>
                    <div>
                        <input className = 'Personality-checkbox' type = 'checkbox' />
                        <p>Submissive</p>
                    </div>
                    <div>
                        <input className = 'Personality-checkbox' type = 'checkbox' />
                        <p>Self-confident</p>
                    </div>
                    <div>
                        <input className = 'Personality-checkbox' type = 'checkbox' />
                        <p>Often blue</p>
                    </div>
                    <div>
                        <input className = 'Personality-checkbox' type = 'checkbox' />
                        <p>Good-natured</p>
                    </div>
                    <div>
                        <input className = 'Personality-checkbox' type = 'checkbox' />
                        <p>Ambitious</p>
                    </div>
                    <div>
                        <input className = 'Personality-checkbox' type = 'checkbox' />
                        <p>Moody</p>
                    </div>
                </div>

                <button 
                    onClick = { postWorker }
                    className = 'Experience-button' 
                    id = 'naming-submit-button'
                >
                    Submit
                </button>

                    
                </form>

            </div>

            <Footer />
        </div>
    )
}

export default PotentialWorker;