import './statements.css';
import FaithImage from '../../assets/faith.jpg';
import VisionImage from '../../assets/vision.jpg';
import MissionImage from '../../assets/mission.jpg';

const Statements = () => {


    return(
        <div className = 'Statement'>
            <div id = 'statement-content-holder'>
                <div className = 'Statement-holder'>
                    <img className = 'Statement-image' src = { FaithImage } alt = 'statement image' />
                    <h2>Our statement of Faith</h2>
                    <p>
                    <b>Changing Lives 1 X 1</b>
                    <br />
                    <br />
                    We share the word of God uncompromisingly, believing that as we do His word, we will 
                    begin to discover and fulfill the purpose for which God created and apprehended us in 
                    addition to being a light and a source of refreshing in the community we find ourselves.
                    <br />
                    <br />
                    We see every family represented in our church becoming strong, founded on Christian
                    values and true love. 
                    <br />
                    <br />
                    Come, let us experience Christ together, Let us “Experience Jubilee”.
                    <br />
                    <br />
                    <br id = 'extension-1' />
                    <br id = 'extension-2' />
                    <br id = 'extension-3' />

                    </p>
                </div>

                <div className = 'Statement-holder'>
                    <img className = 'Statement-image' src = { VisionImage } alt = 'statement image' />
                    <h2>Our Vision</h2>
                    <p>
                        The following are our vision and goals as members of The Redeemed Christian Church of God: 
                        <br />
                        <br />
                        It is our goal to make heaven. 
                        <br />
                        It is our goal to take as many people as possible with us. 
                        <br />
                        In order to accomplish our goals, holiness will be our 		lifestyle. 
                        <br />
                        In order to take as many people with us as possible, we will plant churches within five minutes walking distance in every city and town of developing countries; and within five minutes driving distance in every city and town of developed countries. 
                        <br />
                        We will pursue these objectives until every nation in the world is reached for Jesus Christ our Lord.
                    </p>
                </div>

                <div className = 'Statement-holder'>
                    <img className = 'Statement-image' src = { MissionImage } alt = 'statement image' />
                    <h2>Our Mission</h2>
                    <p>
                        The following are our vision and goals as members of The Redeemed Christian Church of God: 
                        <br />
                        <br />
                        It is our goal to make heaven. 
                        <br />
                        It is our goal to take as many people as possible with us. 
                        <br />
                        In order to accomplish our goals, holiness will be our 		lifestyle. 
                        <br />
                        In order to take as many people with us as possible, we will plant churches within five minutes walking distance in every city and town of developing countries; and within five minutes driving distance in every city and town of developed countries. 
                        <br />
                        We will pursue these objectives until every nation in the world is reached for Jesus Christ our Lord.

                    </p>
                </div>
            </div>
{/* 
            <div id = 'statement-button-holder'>
                <button id = 'statement-button'>Learn More</button>
            </div> */}

        </div>
    )
}

export default Statements;