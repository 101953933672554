import './expressions.css';
import Nav from '../nav/nav';
import Footer from '../footer/footer';
import BackgroundImage from '../assets/expressions background.jpg';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

const Expressions = () => {

    const history = useHistory();

    
    useEffect( () => {
        
        window.scrollTo(0, 0);
        
    }, [])


    return(
        <div className = 'Forms'>
            <Nav />

            <header>
                <img src = { BackgroundImage } className = 'Header-image' alt = 'The header image' />
                <h1 className = 'Expressions-header-text'>Expressions!</h1>
            </header>
            
            <div id = 'expressions-holder-parent'>

                <div className = 'Form-holder'>
                    <div id = 'light-factory'></div>
                    <div className = 'Form-content'>
                        <p className = 'Form-title'>The Light Factory</p>
                        <button 
                            onClick = { () => {
                                
                            }}
                            className = 'Experience-button'>
                            Learn more
                            <i className = 'fas fa-chevron-right Form-icon'></i>
                        </button>
                    </div>
                </div>

                
                <div className = 'Form-holder'>
                    <div id = 'connect-group'></div>
                    <div className = 'Form-content'>
                        <p className = 'Form-title'>Connect Group</p>
                        <button 
                            onClick = { () => {
                                
                            }}
                            className = 'Experience-button'>
                            Learn more
                            <i className = 'fas fa-chevron-right Form-icon'></i>
                        </button>
                    </div>
                </div>

            </div>

            <Footer />
        </div>
    )
}

export default Expressions;