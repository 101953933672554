import './social-media.css';

const SocialMedia = () => {
    return(
        <div className = 'Social-media'>
            <a className = 'Social' href = '#'>
                <i id = 'social-icon' className = 'fab fa-facebook-f' ></i>
            </a>
            <a className = 'Social' href = '#'>
                <i id = 'social-icon' className = 'fab fa-instagram' ></i>
            </a>
            <a className = 'Social' href = '#'>
                <i id = 'social-icon' className = 'fab fa-twitter' ></i>
            </a>
        </div>
    )
}

export default SocialMedia;