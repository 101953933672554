import './blog.css';
import Nav from '../nav/nav';
import Footer from '../footer/footer';
import BackgroundImage from '../assets/blogs-image.jpg';
import { useEffect } from 'react';
import { apiErrorHandler, requests } from '../../api';
import { useState } from 'react';

const Blog = () => {

    const [ blogs, setBlogs ] = useState([]);
    const [ showBlog, setShowBlog ] = useState([]);
    const [ searchValue, setSearchValue ] = useState('');
    const [ searchResult, setSearchResult ] = useState([]);
    const [ categories, setCategories ] = useState([]);
    const [ searchedBlogs, setSearchedBlogs ] = useState([]);

    useEffect( () => {
        
        window.scrollTo(0, 0);


        const getBlogsHandler = async () => {
            try{
                const response = await requests.getBlogPosts();
                setBlogs(response.docs)
            }catch(error){
                const { message } = apiErrorHandler(error);
            }
        }

        const getCategoriesHandler = async () => {
            try{
                const response = await requests.getBlogPostCategory();
                setCategories(response.docs);
            }catch(error){
                const { message } = apiErrorHandler(error);
            }
        }

        getBlogsHandler();
        getCategoriesHandler();

    }, [])

    return(
        <div className = 'Forms'>
            <Nav />

            <header>
                <img src = { BackgroundImage } className = 'Header-image' alt = 'The header image' />
                <h1 style = {{ backgroundColor: 'transparent' }} id = 'header-text' className = 'Expressions-header-text'>Build yourself on <br /> your most holy faith...</h1>
            </header>
            
            <div className = 'Main-blog-holder-parent'>
                {
                    showBlog?.length < 1 ?

                    <div id = 'main-blogs-holder'>
                        {
                            blogs?.length > 0 && blogs.map( (blog, index) => {
                                return  <div
                                            onClick = { () => setShowBlog(blog) }
                                            key = { index }
                                            className = 'Main-blog-item'
                                        >
                                            <div className = 'Blog-image-holder'>
                                                <img className = 'Blog-cover-image' src = { blog.coverUri } alt = 'fellowship picture' />
                                            </div>
                                            <div className = 'Blog-item-bottom-holder'>
                                                <h3 className =  'Fellowship-name'>{ blog.title }</h3>
                                                <p className = 'Blog-content'>{ `${blog.content.slice(0, 40) + '...'}` }</p>
                                                <button
                                                    onClick = { () => {
                                                        setSearchValue('')
                                                    }}
                                                    style = {{ border: 'none' }} 
                                                    className = 'Nav-item Radial-button'
                                                >
                                                    Read more 
                                                    <i style = {{ marginLeft: '1rem' }} className = 'fas fa-chevron-right'></i>
                                                </button>
                                            </div>

                                        </div>
                            })
                        }

                    </div>

                    : 

                    <div id = 'detailed-fellowship'>
                        <div id = 'show-blog-left'>
                            <img className = 'Blog-cover-image' src = { showBlog.coverUri } alt = 'fellowship picture' />
                            <h3 className = 'Blog-show-item-title'>{ showBlog.title }</h3>
                            <p id = 'list-fellowship-description'>{ showBlog.content }</p>
                            <p className = 'Blog-author'>{`Author: ${showBlog.author}`}</p>
                            <div id = 'fellowship-prev-button-holder'>
                                <button 
                                    onClick = { () => {
                                        setShowBlog([]);
                                        setSearchValue('')
                                    }}
                                    id = 'find-us-button'
                                >
                                    <i style = {{ marginRight: '1rem' }} className = 'fas fa-chevron-left'></i>  
                                    Prev
                                </button>
                            </div>
                        </div>
                    </div>
                }

                
                <div id = 'detailed-fellowship-right'>
                    <div className = 'List-fellowship-search-holder'>
                        <div id = 'search-holder'>
                            <input
                                onChange = { (e) => {
                                    setSearchValue( e.target.value );
                                    const searchInputResult = [];
                                    for(let i = 0; i < blogs?.length ; i++){
                                        if(blogs[i].title.toLowerCase().includes(searchValue)){
                                            searchInputResult.push(blogs[i]);
                                            setSearchResult(searchInputResult);
                                        }   
                                    }
                                }}
                                className = 'List-fellowship-search-input' 
                                type = 'search' 
                                placeholder = 'Search' 
                            />
                            <i className = 'fas fa-search List-fellowship-search-icon'></i>
                        </div>
                    </div>  

                    <div className = 'List-fellowship-item-holder'>
                        <h3 id = 'fellowship-search-title'>Categories</h3>
                        {
                            categories?.length > 0 && categories.map( (category, index) => {
                                return  <div 
                                            className = 'List-fellowship-item'
                                            key = { index }
                                        >
                                            <li
                                                onClick = { () => {
                                                    const categoryResult = [];
                                                    setSearchValue(category.title);
                                                    for(let i = 0; i < blogs?.length ; i++){
                                                        if(blogs[i].category.toLowerCase().includes(searchValue)){
                                                            categoryResult.push(blogs[i]);
                                                            setSearchedBlogs(categoryResult);
                                                        }
                                                    }
                                                }}
                                                className = 'Category-title'
                                            >
                                                { category.title }
                                            </li>
                                        </div>
                            })
                        }
                    </div>

                    <div className = 'List-fellowship-item-holder'>
                        <h3 id = 'fellowship-search-title'>Popular posts</h3>
                        {
                            searchValue == '' ?

                            <div>
                                {
                                    blogs?.length > 0 && blogs.map( (blog, index) => {
                                        return  <div 
                                                    onClick = { () => setShowBlog(blog) }
                                                    className = 'List-fellowship-item'
                                                    key = { index }
                                                >
                                                        <img className = 'Fellowship-list-cover-image' src = { blog.coverUri } alt = 'fellowship picture' />
                                                        <h5 className = 'Blog-main-title'>{ blog.title }</h5>
                                                </div>
                                    })
                                }
                            </div>

                            : 

                            <div>
                                {
                                    searchResult?.length > 0 && searchResult.map( (result, index) => {
                                        return  <div 
                                                    onClick = { () => setShowBlog(result) }
                                                    className = 'List-fellowship-item'
                                                    key = { index }
                                                >
                                                        <img className = 'Fellowship-list-cover-image' src = { result.coverUri } alt = 'fellowship picture' />
                                                        <h5>{ result.title }</h5>
                                                </div>
                                    })
                                }
                            </div>
                            
                        }
                    </div>
                </div>
                
            </div>

            <Footer />
        </div>
    )
}

export default Blog;