import './add-admin.css';
import Nav from '../../nav/nav';
import Footer from '../../footer/footer';
import { useState} from 'react';
import { useRef } from 'react';
import { apiErrorHandler, auth } from '../../../api';
import Loading from '../../loading/loading';
import BlankModal from '../../modal/blank-modal/blank-modal';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { useHistory } from 'react-router';


const AddAdmin = ({ user }) => {

    let emailInput = useRef();
    let roleInput = useRef();
    const history = useHistory();
    const [ roles ] = useState([
        'super_admin', 'admin'
    ]);
    const [ loading, setLoading ] = useState(false);
    const [ inviteSent, setInviteSent ] = useState(false);
    const [ inviteNotSent, setInviteNotSent ] = useState(false);
    const [ adminBody, setAdminBody ] = useState({
        email: "",
        role: "",
        client: ""
    });

    const sendInviteHandler = async () => {
        try{
            const response = await auth.sendAdminInvite(adminBody);
            setInviteSent(true);
        }catch(error){
            const { message } = apiErrorHandler(error);
            setInviteNotSent(true);
        }finally{
            setLoading(false);
            emailInput.current.value = '';
            roleInput.current.value = '';
            // clientInput.current.value = '';
        }
    }

    useEffect( () => {
        
        window.scrollTo(0, 0);

    }, []);


    return(
        <div className = 'Verse-of-the-day'>
            <Nav />

            <div id = 'user-holder'>
                <button className = 'Admin-back-button' onClick = { () => history.goBack() }>Back</button>
                <p id = 'user'>Hi, { user?.firstName }</p>
            </div>

            <div className = 'Admin-content'>
                <h2 className = 'Admin-title'>Add Admin</h2>
                <label className = 'Admin-label' htmlFor = 'first name'>Email</label>
                <input
                    onChange = { (e) => setAdminBody({ ...adminBody, email: e.target.value}) } 
                    className = 'Input'
                    placeholder = 'Enter email' 
                    ref = { emailInput }
                />
                <label className = 'Admin-label' htmlFor = 'first name'>Role</label>
                <div id = 'drop-down'>
                    <div id = 'select-holder'>
                        
                            <select name = 'cards' className = 'Input'
                                onChange = { (e) => {
                                    setAdminBody({ ...adminBody, role: e.target.value })
                                }}
                                ref = { roleInput }
                            >
                                    <option value= '' >Select category...</option>
                                    { 
                                        roles?.length > 0 && roles.map( (role, index) => (
                                            <option
                                                key={ index }
                                                value={ role}
                                            >
                                                { role }
                                            </option>
                                        ))
                                    }
                            </select>

                        
                    </div>
                    <div id = 'arrow'></div>
                </div>
                <div className = 'Admin-button-holder'>
                    <button
                        onClick ={ () => {
                            sendInviteHandler();
                            setLoading(true);

                        }}
                        className = 'Admin-button'
                        disabled = { adminBody.email === '' || adminBody.role === '' }
                        style = { adminBody.email === '' || adminBody.role === '' ? { opacity: '0.4', cursor: 'unset' } : {}  }
                    >
                        Send Invite 
                    </button>
                </div>
            </div>




            <Footer />

            {
                loading == true ?

                <Loading />

                : ''
            }

            {
                inviteSent == true ?

                <BlankModal>
                    <i style = {{ color: 'green' }} className = 'fas fa-check-circle Modal-icon'></i>
                    <h3 style = {{ marginTop: '1rem', marginBottom: '1rem' }}>Invite sent successfully</h3>
                    <button 
                        className = 'Admin-button'
                        onClick = { () => {
                            setInviteSent(false);
                        } }
                    >
                        close
                    </button>
                </BlankModal>

                : ''
            }

            {
                inviteNotSent == true ?

                <BlankModal>
                    <i style = {{ color: 'red' }} className = 'fas fa-exclamation Modal-icon'></i>
                    <h3 style = {{ marginTop: '1rem', marginBottom: '1rem' }}>Oooop! Error sending invite. Please try again.</h3>
                    <button 
                        className = 'Admin-button'
                        onClick = { () => {
                            setInviteNotSent(false);
                        } }
                    >
                        close
                    </button>
                </BlankModal>

                : ''
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return{
        user: state.user
    }
}

export default connect(mapStateToProps)(AddAdmin);