import './App.css';
import Home from './components/home/home';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import OurPastorate from './components/about-us/our-pastorate/our-pastorate';
import OurChurch from './components/about-us/our-church/our-church';
import AdminLogin from './components/admin/login';
import AdminPage from './components/admin/admin-page';
import ContactUs from './components/contact-us/contact-us';
import VerseOfTheDay from './components/admin/verse-of-the-day/verse-of-the-day';
import AdminBlog from './components/admin/blog/blog';
import { Provider } from 'react-redux';
import { store, persistor} from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import pastorate from './components/admin/pastorate/pastorate';
import Forms from './components/forms/forms';
import NamingCeremony from './components/forms/naming-ceremony/naming-ceremony';
import BabyDedication from './components/forms/baby-dedication/baby-dedication';
import Volunteering from './components/forms/volunteering/volunteering';
import PotentialWorker from './components/forms/workers/workers';
import Sermons from './components/sermons/sermons';
import Music from './components/music/music';
import Expressions from './components/expressions/expressions';
import Fellowships from './components/fellowships/fellowships';
import Giving from './components/giving/giving';
import BecomeAMember from './components/forms/become-a-member/become-a-member';
import AdminFellowship from './components/admin/fellowship/fellowship';
import AdminGallery from './components/admin/gallery/gallery';
import Blog from './components/blog/blog';
import IntentionToWed from './components/forms/intention-to-wed/intention-to-wed';
import AdminEvent from './components/admin/events/events';
import AddAdmin from './components/admin/add-admin/add-admin';
import AccepptAdminInvitation from './components/admin/accept-admin-invite/accept-admin-invite';
import RequestToken from './components/admin/forgot-password/request-token';
import ResetPassword from './components/admin/forgot-password/reset-password';
import AdminDevotional from './components/admin/devotional/devotional';
import Devotional from './components/devotionals/devotionals';
import PrayerRequest from './components/prayer-request/prayer-request';
import AdminUsers from './components/admin/users/users';
import { useEffect } from 'react';

export const ScrollToTop = ({ location, children }) => {

  useEffect( () => {
    window.scrollTo(0, 0);
  }, [ location?.pathname ])

  return children;

}


const  App = () => {
  return (
    <Provider store = { store }>
      <Router>
        <ScrollToTop path = '/'>
          <PersistGate loading = { null } persistor = { persistor }>
            <div className="App">

              <Route path = '/' exact strict component = { Home } />

              <Route path = '/ourpastorate' exact strict component = { OurPastorate } />

              <Route path = '/ourchurch' exact strict component = { OurChurch } />

              <Route path = '/adminlogin' exact strict component = { AdminLogin } />

              <Route path = '/adminpage' exact strict component = { AdminPage } />

              <Route path = '/addadmin' exact strict component = { AddAdmin } />

              <Route path = '/resetpassword/:token/:id/:email?' exact strict component = { ResetPassword } />

              <Route path = '/requesttoken' exact strict component = { RequestToken } />

              <Route path = '/contactus' exact strict component = { ContactUs } />

              <Route path = '/prayerrequest' exact strict component = { PrayerRequest } />

              <Route path = '/adminverseupload' exact strict component = { VerseOfTheDay } />

              <Route path = '/adminblogupload' exact strict component = { AdminBlog } />

              <Route path = '/adminscheduleevent' exact strict component = { AdminEvent } />

              <Route path = '/adminusers' exact strict component = { AdminUsers } />

              <Route path = '/acceptadmininvitation/:email/:token?' exact strict component = { AccepptAdminInvitation } />

              <Route path = '/adminpastorupload' exact strict component = { pastorate } />

              <Route path = '/forms' exact strict component = { Forms } />

              <Route path = '/namingceremony' exact strict component = { NamingCeremony } />

              <Route path = '/babydedication' exact strict component = { BabyDedication } />

              <Route path = '/intentiontowed' exact strict component = { IntentionToWed } />

              <Route path = '/volunteering' exact strict component = { Volunteering } />

              <Route path = '/potentialworker' exact strict component = { PotentialWorker } />

              <Route path = '/sermons' exact strict component = { Sermons } />

              <Route path = '/music' exact strict component = { Music } />

              <Route path = '/expressions' exact strict component = { Expressions } />

              <Route path = '/fellowships' exact strict component = { Fellowships } />

              <Route path = '/blogs' exact strict component = { Blog } />

              <Route path = '/adminfellowship' exact strict component = { AdminFellowship } />

              <Route path = '/admingallery' exact strict component = { AdminGallery } />

              <Route path = '/give' exact strict component = { Giving } />

              <Route path = '/becomeamember' exact strict component = { BecomeAMember} />

              <Route path = '/jubileeadmindevotional' exact strict component = { AdminDevotional} />

              <Route path = '/devotional' exact strict component = { Devotional } />
              
            </div>
          </PersistGate>
        </ScrollToTop>
      </Router>
    </Provider>
  );
}

export default App;
