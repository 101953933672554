import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { apiErrorHandler, requests } from '../../../api';
import './jubilee-blog.css';

const JubileeBlog = () => {

    const [ allBlogs, setAllBlogs ] = useState([]);
    let blogs = allBlogs?.slice(-4);
    const history = useHistory();

    useEffect( () => {

        const getBlogPostHandler = async () => {
            try{
                const response = await requests.getBlogPosts();
                setAllBlogs(response.docs);
            }catch(error){
                const { message } = apiErrorHandler(error);
            }
        }

        getBlogPostHandler();

    }, [])


    return(
        <div className = 'Jubilee-blog'>
            <h1 id = 'jubilee-blog-heading'>From Jubilee Blog</h1>
            <div id = 'jubilee-blog-content'>
                {
                    allBlogs?.length > 0 && blogs.map( (blog, index) => {
                        return  <div
                                    key = { index }
                                    className = 'Blog-item'
                                >
                                    <div className = 'Blog-image-holder'>
                                        <img className = 'Blog-cover-image' src = { blog.coverUri } alt = 'blog picture' />
                                    </div>
                                    <div className = 'Blog-item-bottom-holder'>
                                        <h3 className =  'Blog-title'>{ blog.title }</h3>
                                        <p className = 'Blog-content'>{ `${blog.content.slice(0, 40) + '...'}` }</p>
                                        <button style = {{ border: 'none' }} className = 'Nav-item Radial-button'>Read more <i style = {{ marginLeft: '1rem' }} className = 'fas fa-chevron-right'></i></button>
                                    </div>

                                </div>
                    })
                }
            </div>
            <button onClick = { () => history.push('/blogs') } id = 'jubilee-blog-button'>More From Jubilee Blog</button>
        </div>
    )
}

export default JubileeBlog;