export default class Auth {

    #httpClient;
  
    constructor(baseClient) {
      this.#httpClient = baseClient;
    }
  
    async login(payload) {
      return this.#httpClient.post('/auth/login', payload);
    }

    async sendAdminInvite(payload) {
      return this.#httpClient.post('/auth/invite', payload);
    }

    async verifyInvite(payload) {
        return this.#httpClient.post('/auth/verifyInvite', payload);
    }

    async acceptInvitation(payload) {
        return this.#httpClient.post('/auth/acceptInvite', payload);
    }

    async requestToken(payload) {
        return this.#httpClient.post('/auth/forgotPassword', payload);
    }

    async resetPassword(payload) {
        return this.#httpClient.post('/auth/reset', payload);
    }

    async verifyToken(token, id) {
        return this.#httpClient.get(`/auth/token?token=${token}&id=${id}`);
    }

  
  }