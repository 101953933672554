import './loading.css';
import { BounceLoader } from 'react-spinners';



const Loading = ({ children }) => {

    return(
        <div className = 'Check'>
            <div className = 'Check-background'>
                <div id = 'precheck-svg-pad'>
                    <BounceLoader color = '#EA3C6C' size = { 60 } />
                </div>
                { children }
            </div>
        </div>
    )
}

export default Loading;