import './intention-to-wed.css';
import Nav from '../../nav/nav';
import Footer from '../../footer/footer';
import BackgroundImage from '../../assets/ring.PNG';
import { apiErrorHandler, requests } from '../../../api';
import { useState } from 'react';
import Loading from '../../loading/loading';
import { useHistory } from 'react-router';
import BlankModal from '../../modal/blank-modal/blank-modal';

const IntentionToWed = () => {

    const history = useHistory();
    const [ loader, setLoader ] = useState(false);
    const [ formSuccess, setFormSuccess ] = useState(false);
    const [ submitted, setSubmitted ] = useState(false);
    const [ formData, setFormData ] = useState({
        weddingInvitation: {
            bachelor: {
                name: "",
                phone: "",
                email: "",
                residentialAddress: "",
                stateOfOrigin: "",
                occupation: "",
                placeOfWorship: ""
            },
            spinster: {
                name: "",
                phone: "",
                email: "",
                residentialAddress: "",
                stateOfOrigin: "",
                occupation: "",
                placeOfWorship: ""
            },
            proposedWeddingDate: ''
        }
    });

    const postForm = async () => {
        setLoader(true);
        try{
            const response = await requests.postForms('weddingInvitation', formData)
           setFormSuccess(true);
        }catch(error){
            const message = apiErrorHandler(error);
            setFormSuccess(false);
        }finally{
            setLoader(false);
            setSubmitted(true);
        }
    }

    return(
        <div className = 'Baby-dedication'>
            <Nav />

            <header>
                <img src = { BackgroundImage } className = 'Header-image' alt = 'The header image' />
                <h1 style = {{ color: '#3A2490'}} className = 'Forms-header-text'>Intention to wed booking form!</h1>
            </header>
            
            <div className = 'Form-parent'>
                <p className = 'Form-greeting'>
                    We say congratulations to you on your intention to be a couple.
                </p>

                <form className = 'Forms-form' onSubmit = { e => e.preventDefault() }>
                    
                    <p className = 'Form-greeting Form-extra-title'>Bachelor's details.</p>

                    <div className = 'Forms-input-holder'>
                        <input 
                            onChange = { e => {
                                setFormData({
                                    ...formData, weddingInvitation: { ...formData.weddingInvitation, bachelor: { ...formData.weddingInvitation.bachelor,
                                        name: e.target.value
                                    }}
                                })
                            }}
                            className = 'Contact-input' type = 'text' placeholder = 'Name' 
                        />
                    </div>

                    <div className = 'Forms-input-holder'>
                        <input 
                            onChange = { e => {
                                setFormData({
                                    ...formData, weddingInvitation: { ...formData.weddingInvitation, bachelor: { ...formData.weddingInvitation.bachelor,
                                        phone: e.target.value
                                    }}
                                })
                            }}
                            className = 'Contact-input' type = 'text' placeholder = 'Phone number' 
                        />
                    </div>

                    <div className = 'Forms-input-holder'>
                        <input 
                            onChange = { e => {
                                setFormData({
                                    ...formData, weddingInvitation: { ...formData.weddingInvitation, bachelor: { ...formData.weddingInvitation.bachelor,
                                        email: e.target.value
                                    }}
                                })
                            }}
                            className = 'Contact-input' type = 'text' placeholder = 'Email address' 
                        />
                    </div>
                    
                    <div className = 'Forms-input-holder'>
                        <textarea 
                            onChange = { e => {
                                setFormData({
                                    ...formData, weddingInvitation: { ...formData.weddingInvitation, bachelor: { ...formData.weddingInvitation.bachelor,
                                        residentialAddress: e.target.value
                                    }}
                                })
                            }}
                            className = 'Contact-input' type = 'text' placeholder = 'Residential address' 
                        />
                    </div>

                    <div className = 'Naming-inputs-holder'>
                        <div className = 'Small-input-holder'>
                            <input 
                                onChange = { e => {
                                    setFormData({
                                        ...formData, weddingInvitation: { ...formData.weddingInvitation, bachelor: { ...formData.weddingInvitation.bachelor,
                                            stateOfOrigin: e.target.value
                                        }}
                                    })
                                }}
                                className = 'Contact-input' type = 'text' placeholder = 'State of origin' 
                            />
                        </div>
                        <div className = 'Small-input-holder-right'>
                            <input 
                                onChange = { e => {
                                    setFormData({
                                        ...formData, weddingInvitation: { ...formData.weddingInvitation, bachelor: { ...formData.weddingInvitation.bachelor,
                                            occupation: e.target.value
                                        }}
                                    })
                                }} 
                                className = 'Contact-input' type = 'text' placeholder = 'occupation' 
                            />
                        </div>
                    </div>

                    <div className = 'Forms-input-holder'>
                        <input 
                            onChange = { e => {
                                setFormData({
                                    ...formData, weddingInvitation: { ...formData.weddingInvitation, bachelor: { ...formData.weddingInvitation.bachelor,
                                        placeOfWorship: e.target.value
                                    }}
                                })
                            }}
                            className = 'Contact-input' type = 'text' placeholder = 'Place of worship' 
                        />
                    </div>

                    <p className = 'Form-greeting Form-extra-title'>Spinster's details.</p>

                    <div className = 'Forms-input-holder'>
                        <input 
                            onChange = { e => {
                                setFormData({
                                    ...formData, weddingInvitation: { ...formData.weddingInvitation, spinster: { ...formData.weddingInvitation.spinster,
                                        name: e.target.value
                                    }}
                                })
                            }}
                            className = 'Contact-input' type = 'text' placeholder = 'Name' 
                        />
                    </div>

                    <div className = 'Forms-input-holder'>
                        <input 
                            onChange = { e => {
                                setFormData({
                                    ...formData, weddingInvitation: { ...formData.weddingInvitation, spinster: { ...formData.weddingInvitation.spinster,
                                        phone: e.target.value
                                    }}
                                })
                            }}
                            className = 'Contact-input' type = 'text' placeholder = 'Phone number' 
                        />
                    </div>

                    <div className = 'Forms-input-holder'>
                        <input 
                            onChange = { e => { 
                                setFormData({
                                    ...formData, weddingInvitation: { ...formData.weddingInvitation, spinster: { ...formData.weddingInvitation.spinster,
                                        email: e.target.value
                                    }}
                                })
                            }}
                            className = 'Contact-input' type = 'text' placeholder = 'Email address' />
                    </div>
                    
                    <div className = 'Forms-input-holder'>
                        <textarea 
                            onChange = { e => {
                                setFormData({
                                    ...formData, weddingInvitation: { ...formData.weddingInvitation, spinster: { ...formData.weddingInvitation.spinster,
                                        residentialAddress: e.target.value
                                    }}
                                })
                            }}
                            className = 'Contact-input' type = 'text' placeholder = 'Residential address' 
                        />
                    </div>

                    <div className = 'Naming-inputs-holder'>
                        <div className = 'Small-input-holder'>
                            <input 
                                onChange = { e => {
                                    setFormData({
                                        ...formData, weddingInvitation: { ...formData.weddingInvitation, spinster: { ...formData.weddingInvitation.spinster,
                                            stateOfOrigin: e.target.value
                                        }}
                                    })
                                }}
                                className = 'Contact-input' type = 'text' placeholder = 'State of origin' 
                            />
                        </div>
                        <div className = 'Small-input-holder-right'>
                            <input 
                                onChange = { e => {
                                    setFormData({
                                        ...formData, weddingInvitation: { ...formData.weddingInvitation, spinster: { ...formData.weddingInvitation.spinster,
                                            occupation: e.target.value
                                        }}
                                    })
                                }}
                                className = 'Contact-input' type = 'text' placeholder = 'occupation' 
                            />
                        </div>
                    </div>

                    <div className = 'Forms-input-holder'>
                        <input 
                            onChange = { e => {
                                setFormData({
                                    ...formData, weddingInvitation: { ...formData.weddingInvitation, spinster: { ...formData.weddingInvitation.spinster,
                                        placeOfWorship: e.target.value
                                    }}
                                })
                            }}
                            className = 'Contact-input' type = 'text' placeholder = 'Place of worship' 
                        />
                    </div>

                    <div style = {{ width: '100%' }} className = 'Naming-checkbox-holder'>
                        <h3>Proposed wedding date:</h3>
                        <div className = 'Forms-input-holder'>
                            <input 
                                onChange = { e => {
                                    let date = new Date(e.target.value);
                                    setFormData({
                                        ...formData, weddingInvitation: { ...formData.weddingInvitation,
                                             proposedWeddingDate: date.toISOString()
                                        }
                                    })
                                } }
                                className = 'Contact-input' type = 'date' 
                            />
                        </div>
                    </div>

                    <button 
                        onClick = { () => {
                            postForm();
                        }}
                        className = 'Experience-button' 
                        id = 'naming-submit-button'
                    >
                        Submit
                    </button>

                    
                </form>
            </div>

            <Footer />

            {
                formSuccess && submitted ?

                <BlankModal>
                    <i style = {{ color: 'green' }} className = 'fas fa-check-circle Modal-icon'></i>
                    <h3 style = {{ marginTop: '1rem', marginBottom: '1rem' }}>Form submitted successfully.</h3>
                    <button 
                        className = 'Admin-button'
                        onClick = { () => {
                            history.push('/forms');
                        } }
                    >
                        close
                    </button>
                </BlankModal>

                : !formSuccess && submitted ?

                <BlankModal>
                    <i style = {{ color: 'red' }} className = 'fas fa-exclamation Modal-icon'></i>
                    <h3 style = {{ marginTop: '1rem', marginBottom: '1rem' }}>Oooop! Error submitting form. Please try again later.</h3>
                    <button 
                        className = 'Admin-button'
                        onClick = { () => {
                            history.push('/forms');
                        } }
                    >
                        close
                    </button>
                </BlankModal>

                : ''

            }

            {
                loader === true ?

                <Loading />

                : ''
            }

        </div>
    )
}

export default IntentionToWed;