import './verse.css';

const Verse = ({ onClick }) => {
    return(
        <div className = 'Verse'>
            <div id = 'verse-holder'>
                <h3>Verse of the day</h3>
                <div>
                    <p id = 'verse-item'>
                        "The lord is my shepherd, I shall not want"
                    </p>
                </div>
            </div>

            
            <button id = 'verse-button'> Continue Reading </button>
            <i
                onClick = { onClick }
             id = 'exit-icon' className = 'fas fa-times' ></i>
        </div>
    )
}

export default Verse;