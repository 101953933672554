import './admin-login.css';
import Nav from '../nav/nav';
import Footer from '../footer/footer';
import { useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { apiErrorHandler, auth } from '../../api';
import Loading from '../loading/loading';


const AdminLogin = ({ dispatch }) => {

    const history = useHistory();
    const [ loading, setLoading ] = useState(false);
    const [ failedLogin, setFailedLogin ] = useState(false);
    const [ loginDetail, setLoginDetail ] = useState({
        email: '',
        password: ''
    });

    const loginHandler = async () => {
        try{
            const response = await auth.login(loginDetail);
            dispatch({ type: 'SET_USER', payload: response.data});
            if(response.data.status === 'verified' && (response.data.role === 'super_admin' || response.data.role === 'admin')){
                history.push('/adminpage');
            }
        }catch(error){
            const { message } = apiErrorHandler(error);
            setFailedLogin(true);
        }finally{
            setLoading(false);
        }
    }

    
    useEffect( () => {
        
        window.scrollTo(0, 0);
        
    }, [])


    return(
        <div className = 'Admin-login'>
            <Nav/>

            <div id = 'login-content'>
                <h1>Admin Login</h1>
                <form id = 'admin-login-form' onSubmit = { (e) => e.preventDefault() }>

                    <div className = 'Admin-input-holder'>
                        <label htmlFor = 'Email'>Email</label>
                        <input
                            onChange = {(e) => setLoginDetail({...loginDetail, email: e.target.value})}
                            type = 'email' 
                            className = 'Input' 
                            placeholder = 'Enter email here' 
                            name = 'Email' />
                    </div>

                    <div className = 'Admin-input-holder'>
                        <label htmlFor = 'Password'>Password</label>
                        <input
                            onChange = {(e) => setLoginDetail({...loginDetail, password: e.target.value})}
                            type = 'password' 
                            className = 'Input' 
                            placeholder = 'Enter password here' 
                            name = 'Password' />
                    </div>
                    
                    {
                        failedLogin === true ?

                        <div id = 'failed-login-holder'>
                            <p style = { { color: 'red' } }>Invalid login credentials</p>
                        </div>

                        : ''
                    }

                    <button onClick = { (e) => {
                        e.preventDefault();
                        loginHandler();
                        setLoading(true);
                    } }

                        className = 'Admin-button'
                    >
                        Login
                    </button>
                    <button
                        onClick = { () => history.push('/requesttoken')}
                        id = 'login-forgot-password'
                    >
                        Forgot Password
                    </button>
                </form>
            </div>

            <Footer />

            {
                loading == true ?

                <Loading />

                : ''
            }
            
        </div>
    )
}

const mapStateToProps = (state) => {
    return{

    }
}

export default connect(mapStateToProps)(AdminLogin);