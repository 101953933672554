import './nav.css';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import Logo from '../assets/jubilee-logo-red.png';
import { connect } from 'react-redux';
import { logoutUser } from '../../redux/authentication/actions';

const Nav = ({ logoutUser, user, style }) => {

    const [ aboutHover, setAboutHover ] = useState(false);
    const [ contactHover, setContactHover ] = useState(false);
    const [ showMenu, setShowMenu ] = useState(false);

    return(
        <div 
            style = { style }
            className = 'Nav'
        >
            <div id = 'logo-holder'>
                <img id = 'logo' src = { Logo } alt = 'The logo' />
            </div>
            <div id = 'nav-item-holder'>

                <Link to = '/' className = 'Nav-item'>HOME</Link>

                <div>
                    <Link
                        onMouseEnter = { () => setAboutHover(true)}
                        onMouseLeave = { () => {
                                setAboutHover(false);
                        }}
                        to = '#' 
                        className = 'Nav-item Drop'
                    >
                        ABOUT US
                        <i id = 'down-icon' className = 'fas fa-angle-down' ></i>
                    </Link>
                    {
                        aboutHover ? 

                        <div 
                            onMouseEnter = { () => setAboutHover(true)}
                            onMouseLeave = { () => {
                                    setAboutHover(false);
                            }}
                            className = 'Drop-down'
                        >
                            <Link className = 'Drop-down-item' to = '/ourchurch'>Our Church</Link>
                            <Link className = 'Drop-down-item' to = '/ourpastorate'>Our Pastorate</Link>
                        </div>

                        : ''
                    }
                </div>

                <Link
                to = '/sermons' className = 'Nav-item'>MESSAGES & MORE</Link>

                <div>
                    <Link
                        onMouseEnter = { () => setContactHover(true)}
                        onMouseLeave = { () => {
                                setContactHover(false);
                        }}
                        to = '#' 
                        className = 'Nav-item Drop'
                    >
                        CONTACT US
                        <i id = 'down-icon' className = 'fas fa-angle-down' ></i>
                    </Link>
                    {
                        contactHover ? 

                        <div 
                            onMouseEnter = { () => setContactHover(true)}
                            onMouseLeave = { () => {
                                    setContactHover(false);
                            }}
                            className = 'Drop-down'
                        >
                            <Link className = 'Drop-down-item' to = '/contactus'>Get in touch</Link>
                            <Link className = 'Drop-down-item' to = '/prayerrequest'>Prayer request</Link>
                        </div>

                        : ''
                    }

                </div>

                <Link
                    to = '/' className = 'Nav-item Radial-button'
                >
                    LIVE STREAM
                </Link>

                <Link to = '/give' className = 'Nav-item Radial-button'>GIVE</Link>

                {
                    user !== null ?

                    <Link to = '/adminpage' className = 'Nav-item Radial-button'>ADMIN</Link>

                    : ''
                }

                
                {
                    user !== null ?

                    <Link
                        style = {{ backgroundColor: 'black' }}
                        onClick ={ () => {
                            logoutUser();
                        }}
                        to = '/' 
                        className = 'Nav-item Radial-button'
                    >
                        LOGOUT
                    </Link>

                    :

                    <Link to = '/adminlogin' className = 'Nav-item Radial-button'>ADMIN</Link>
                }

                


                {/* <i id = 'search-icon' className = 'fas fa-search' ></i> */}
            </div>

            <div
                onClick = { () => setShowMenu(!showMenu)}
                id = 'hambugger'
            >
                <div id = { showMenu === false ? 'ham1' : 'ham1-alt' }></div>
                <div id = { showMenu === false ? 'ham2' : 'ham2-alt' }></div>
                <div id = { showMenu === false ? 'ham3' : 'ham3-alt' }></div>
            </div>

            {
                showMenu ?

                <div id = 'ham-menu'>
                    <Link to = '/' className = 'Nav-item'>.HOME</Link>

                    <div>
                        <Link
                            onMouseEnter = { () => setAboutHover(true)}
                            // onMouseLeave = { () => setAboutHover(false)}
                            to = '#' 
                            className = 'Nav-item Drop'
                        >
                            .ABOUT US
                            <i id = 'down-icon' className = 'fas fa-angle-down' ></i>
                        </Link>
                        {
                            aboutHover ? 

                            <div className = 'Drop-down'>
                                <Link className = 'Drop-down-item' to = '/ourchurch'>Our Church</Link>
                                <Link className = 'Drop-down-item' to = '/ourpastorate'>Our Pastorate</Link>
                            </div>

                            : ''
                        }
                    </div>

                    <Link
                    to = '/sermons' className = 'Nav-item'>.MESSAGES & MORE</Link>

                    <div>
                        <Link
                            onMouseEnter = { () => setContactHover(true)}
                            // onMouseLeave = { () => {
                            //         setContactHover(false);
                            // }}
                            to = '#' 
                            className = 'Nav-item Drop'
                        >
                            CONTACT US
                            <i id = 'down-icon' className = 'fas fa-angle-down' ></i>
                        </Link>

                        {
                            contactHover ? 

                            <div className = 'Drop-down'>
                                <Link className = 'Drop-down-item' to = '/contactus'>Get in touch</Link>
                                <Link className = 'Drop-down-item' to = '/prayerrequest'>Prayer request</Link>
                            </div>

                            : ''
                        }

                    </div>

                    <Link
                        to = '/' className = 'Nav-item'
                    >
                        .LIVE STREAM
                    </Link>

                    <Link to = '/give' className = 'Nav-item'>.GIVE</Link>

                    {
                        user !== null ?

                        <Link to = '/adminpage' className = 'Nav-item'>ADMIN</Link>

                        : ''
                    }

                    {
                        user !== null ?

                        <Link
                            onClick ={ () => {
                                logoutUser();
                            }}
                            to = '/' 
                            className = 'Nav-item'
                        >
                            .LOGOUT
                        </Link>

                        :

                        <Link to = '/adminlogin' className = 'Nav-item'>.ADMIN</Link>
                    }


                    {/* <i id = 'search-icon' className = 'fas fa-search' ></i> */}

                </div>
                : ''
            }

        </div>
    )
}

const mapStateToProps = (state) => {
    return{
        user: state.user
    }
}

export default connect(mapStateToProps, { logoutUser })(Nav);