import './volunteering.css';
import Nav from '../../nav/nav';
import Footer from '../../footer/footer';
import BackgroundImage from '../../assets/volunteer.jpg';
import { useState } from 'react';
import { requests, apiErrorHandler } from '../../../api';
import Loading from '../../loading/loading';
import BlankModal from '../../modal/blank-modal/blank-modal';

const Volunteering = () => {

    const [ loader, setLoader ] = useState(false);
    const [ modal, setModal ] = useState(false);
    const [ formData, setFormData ] = useState({
        fullName: "",
        juniorChurchRepresentative: ""
    });

    const postForm = async () => {
        setLoader(true);
        try{
            const response = await requests.postForms('volunteer', formData)
        }catch(error){
            const message = apiErrorHandler(error);
        }finally{
            setLoader(false);
            setModal(true);
        }
    }

    return(
        <div className = 'Volunteering'>
            <Nav />

            <header>
                <img src = { BackgroundImage } className = 'Header-image' alt = 'The header image' />
                <h1 className = 'Forms-header-text'>We are always glad to have you!</h1>
            </header>
            
            <div className = 'Form-parent'>
                <p className = 'Form-greeting'>
                    Thank you for showing interest as a volunteer to do God's work.
                </p>

                <form className = 'Forms-form' onSubmit = { e => e.preventDefault() }>
                    <div className = 'Forms-input-holder'>
                        <input 
                            onChange = { e => {
                                setFormData({
                                    ...formData, fullName: e.target.value
                                })
                            }}
                            className = 'Contact-input' type = 'text' placeholder = 'Full name' 
                        />
                    </div>

                    <p className = 'Volunteer-text'>
                        I, hereby wish to confirm my willingness to commit my services to the Junior Church of RCC, Jubilee Christian Centre as a Volunteer.
                        This commitment is made without any coercion or persuasion and I will carry out my duties to the best of my abilities and to the glory of God.
                        This agreement serves to assure you of our appreciation of your services and indicates our commitment to ensuring your experience as a Volunteer is both productive and rewarding.
                        The terms and conditions of your engagement as a Volunteer are as follows:
                    </p>
                    
                    <p className = 'Form-greeting'>VOLUNTEER Commitment & Obligations.</p>

                    <p className = 'Volunteer-text'>
                        I elect to serve as a volunteer and I commit to:
                        <br />
                        <br />
                        -Abiding by the terms and conditions contained in this agreement and the policies and procedures  of RCCG Jubilee Christian Centre.
                        <br />
                        -Assisting the Junior Church of RCCG Jubilee Christian Centre to fulfill its aims and objectives through the provision of my services as a Volunteer.
                        <br />
                        -Meeting mutually agreed time and duty commitments and to provide adequate notice so that alternative arrangements can be made when this is not possible.
                        <br />
                        -Participating in induction and training programs as required.
                        <br />
                        -Working as part of a team.
                        <br />
                        -Accepting guidance and direction from supervisors.
                        <br />
                        -Informing a Junior Church representative if I am unable to attend to designated duty.
                        <br />
                        -Work for the next six months.
                    </p>

                    
                    <div className = 'Forms-input-holder'>
                        <input className = 'Contact-input' type = 'text' placeholder = 'Full name' />
                    </div>

                    <p className = 'Volunteer-text'>
                        I have read, understood and accept the terms of this commitment form.
                    </p>

                    <div className = 'Naming-inputs-holder'>
                        <div className = 'Small-input-holder'>
                            <input className = 'Contact-input' type = 'text' placeholder = 'Full name' />
                        </div>
                        <div className = 'Small-input-holder-right'>
                            <input className = 'Contact-input' type = 'date' />
                        </div>
                    </div>

                    <div className = 'Naming-inputs-holder'>
                        <div className = 'Small-input-holder'>
                            <input 
                                onChange = { e => {
                                    setFormData({
                                        ...formData, juniorChurchRepresentative: e.target.value
                                    })
                                }}
                                className = 'Contact-input' type = 'text' placeholder = "Junior church representative's name"
                            />
                        </div>
                        <div className = 'Small-input-holder-right'>
                            <input className = 'Contact-input' type = 'date' />
                        </div>
                    </div>


                    <button
                        onClick = { postForm }
                        className = 'Experience-button' id = 'naming-submit-button'
                    >
                        Submit
                    </button>

                    
                </form>
            </div>

            <Footer />

            {
                modal === true ? 

                <BlankModal>
                    
                </BlankModal>

                : ''
            }

            {
                loader === true ?

                <Loading />

                : ''
            }
        </div>
    )
}

export default Volunteering;