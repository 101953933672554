import './devotional.css';
import Nav from '../../nav/nav';
import Footer from '../../footer/footer';
import { useState } from 'react';
import { useRef } from 'react';
import { apiErrorHandler, requests } from '../../../api';
import Loading from '../../loading/loading';
import BlankModal from '../../modal/blank-modal/blank-modal';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactHTMLParser from 'react-html-parser';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { useHistory } from 'react-router';


const AdminDevotional = ({user}) => {

    let devotionalTitleInput = useRef();
    let devotionalDescriptionInput = useRef();
    let devotionalImageInput = useRef();
    let devotionalValidityPeriodInput = useRef();
    let devotionalValidityFromInput = useRef();
    const history = useHistory();
    const [ image, setImage ] = useState();
    const [ loading, setLoading ] = useState(false);
    const [ devotionalAdded, setDevotionalAdded ] = useState(false);
    const [ devotionalNotAdded, setDevotionalNotAdded ] = useState(false);
    const [ dateType, setDateType ] = useState('text');
    const [ imageError , setImageError ] = useState(false);
    const [ devotionalBody, setDevotionalBody ] = useState({
        title: "",
        coverUri: "",
        description: "",
        validPeriod: 0,
        validFrom: ''
    });

    let mediaType = 'cover';

    const devotionalUploadHandler = async () => {
        try{
            const response = await requests.postDevotional(devotionalBody);
            setDevotionalAdded(true);
        }catch(error){
            const { message } = apiErrorHandler(error);
            setDevotionalNotAdded(true);
        }finally{
            setLoading(false);
            devotionalTitleInput.current.value = '';
            devotionalDescriptionInput.current.value = '';
            devotionalImageInput.current.value = '';
            devotionalValidityPeriodInput.current.value = '';
            devotionalValidityFromInput.current.value = '';
            setImage([]);
        }
    }

    const generateMediaURiHandler = async (image) => {

        const formData = new FormData()
            formData.append(
            "file",
            image
        )

        try{
            const response = await requests.generateMediaUri(mediaType, formData)
            setDevotionalBody({ ...devotionalBody, coverUri: response.data.resourceUrl });
            setImageError(false);
        }catch(error){
            const { message } = apiErrorHandler(error);
            setImageError(true);
        }
    }

    useEffect( () => {
        
        window.scrollTo(0, 0);

    }, []);


    return(
        <div className = 'Verse-of-the-day'>
            <Nav />
            
            <div id = 'user-holder'>
                <button className = 'Admin-back-button' onClick = { () => history.goBack() }>Back</button>
                <p id = 'user'>Hi, { user?.firstName }</p>
            </div>

            <div className = 'Admin-content'>
                <h2 className = 'Admin-title'>Jubilee Devotional</h2>
                <label className = 'Admin-label' htmlFor = 'first name'>Title</label>
                <input
                    onChange = { (e) => setDevotionalBody({ ...devotionalBody, title: e.target.value}) } 
                    className = 'Input'
                    placeholder = 'Enter title here' 
                    ref = { devotionalTitleInput }
                />
                {/* <textarea
                    onChange = { (e) => setDevotionalBody({ ...devotionalBody, description: e.target.value}) } 
                    className = 'Input'
                    placeholder = 'Enter content here' 
                    ref = { devotionalDescriptionInput }
                    rows = '10'
                /> */}
                <label className = 'Admin-label' htmlFor = 'first name'>Content</label>
                <CKEditor 
                    editor = { ClassicEditor }
                    onChange = { (e, editor) => {
                        const data = editor.getData();
                        setDevotionalBody({ ...devotionalBody, description: ReactHTMLParser(data)[0]?.props?.children[0] })
                    }}
                />
                <label className = 'Admin-label' htmlFor = 'first name'>Validity Period</label>
                <input
                    onChange = { (e) => setDevotionalBody({ ...devotionalBody, validPeriod: Number(e.target.value)}) } 
                    className = 'Input'
                    placeholder = 'Enter validity period in days' 
                    type = 'number'
                    ref = { devotionalValidityPeriodInput }
                />
                <label className = 'Admin-label' htmlFor = 'first name'>Vaild From</label>
                <input
                    onChange = { (e) =>{ 
                        let date = new Date(e.target.value);
                        setDevotionalBody({ ...devotionalBody, validFrom: date.toISOString()}) 
                    }}
                    onFocus = { () => setDateType('date') }
                    onBlur = { () => setDateType('text') }
                    className = 'Input'
                    type = { dateType }
                    placeholder = 'Devotional should be available from ( enter date )'
                    ref = { devotionalValidityFromInput }
                />
                <label className = 'Admin-label' htmlFor = 'first name'>Cover Image</label>
                <div className = 'Admin-multiple-holder'>
                    <input
                        onChange = { (e) => setImage( e.target.files[0] )}
                        className = 'Input' 
                        type = 'file'
                        ref = { devotionalImageInput }
                    />
                    <button
                        onClick = { () => {
                          generateMediaURiHandler(image);
                        }}
                        id = 'multiple-button' className = 'Admin-button'
                    >
                        Upload
                    </button>
                </div>

                {
                    imageError === true ?

                    <p id = 'fellowship-image-error'>Image size should not exceed 2MB.</p>

                    : ''
                }

                <p style = {{ textAlign: 'start' }}>{ `${ devotionalBody.coverUri !== '' ?  '1 Image added' : 'No image added' }`}</p>
                { devotionalBody.coverUri}
                <div className = 'Admin-button-holder'>
                    <button
                        onClick ={ () => {
                            devotionalUploadHandler();
                            setLoading(true);

                        }}
                        className = 'Admin-button'
                        disabled = { devotionalBody.title === '' || devotionalBody.coverUri === '' || devotionalBody.description === '' || devotionalBody.validFrom === '' || devotionalBody.validPeriod === 0 }
                        style = { devotionalBody.title === '' || devotionalBody.coverUri === '' || devotionalBody.description === '' || devotionalBody.validFrom === '' || devotionalBody.validPeriod === 0 ? { opacity: '0.4', cursor: 'unset' } : { } }
                    >
                        Post Devotional 
                    </button>
                </div>
            </div>



            <Footer />

            {
                loading == true ?

                <Loading />

                : ''
            }

            {
                devotionalAdded == true ?

                <BlankModal>
                    <i style = {{ color: 'green' }} className = 'fas fa-check-circle Modal-icon'></i>
                    <h3 style = {{ marginTop: '1rem', marginBottom: '1rem' }}>Devotional Added successfully</h3>
                    <button 
                        className = 'Admin-button'
                        onClick = { () => {
                            setDevotionalAdded(false);
                        } }
                    >
                        close
                    </button>
                </BlankModal>

                : ''
            }

            {
                devotionalNotAdded == true ?

                <BlankModal>
                    <i style = {{ color: 'red' }} className = 'fas fa-exclamation Modal-icon'></i>
                    <h3 style = {{ marginTop: '1rem', marginBottom: '1rem' }}>Oooop! Error creating devotional. Please try again.</h3>
                    <button 
                        className = 'Admin-button'
                        onClick = { () => {
                            setDevotionalNotAdded(false);
                        } }
                    >
                        close
                    </button>
                </BlankModal>

                : ''
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return{
        user: state.user
    }
}

export default connect(mapStateToProps)(AdminDevotional);