import './fellowship.css';
import Nav from '../../nav/nav';
import Footer from '../../footer/footer';
import { useState } from 'react';
import { useRef } from 'react';
import { apiErrorHandler, requests } from '../../../api';
import Loading from '../../loading/loading';
import BlankModal from '../../modal/blank-modal/blank-modal';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactHTMLParser from 'react-html-parser';


const AdminFellowship = ({user}) => {

    let blogTitleInput = useRef();
    let blogDescriptionInput = useRef();
    let blogImageInput = useRef();
    const history = useHistory();
    const [ image, setImage ] = useState();
    const [ loading, setLoading ] = useState(false);
    const [ blogAdded, setBlogAdded ] = useState(false);
    const [ blogNotAdded, setBlogNotAdded ] = useState(false);
    const [ fellowshipBody, setFellowshipBody ] = useState({
        name: "",
        coverUri: "",
        description: ""
    });
    const [ imageError , setImageError ] = useState(false);

    let mediaType = 'cover';

    const fellowshipUploadHandler = async () => {
        try{
            const response = await requests.PostFellowships(fellowshipBody);
            setBlogAdded(true);
        }catch(error){
            const { message } = apiErrorHandler(error);
            setBlogNotAdded(true);
        }finally{
            setLoading(false);
            blogTitleInput.current.value = '';
            blogDescriptionInput.current.value = '';
            blogImageInput.current.value = '';
        }
    }


    const generateMediaURiHandler = async (image) => {

        const formData = new FormData()
            formData.append(
            "file",
            image
        )

        try{
            const response = await requests.generateMediaUri(mediaType, formData)
            setFellowshipBody({ ...fellowshipBody, coverUri: response.data.resourceUrl });
            setImageError(false);
        }catch(error){
            const { message } = apiErrorHandler(error);
            setImageError(true);
        }
    }


    useEffect( () => {
        
        window.scrollTo(0, 0);

    }, []);

    return(
        <div className = 'Verse-of-the-day'>
            <Nav />

            <div id = 'user-holder'>
                <button className = 'Admin-back-button' onClick = { () => history.goBack() }>Back</button>
                <p id = 'user'>Hi, { user?.firstName }</p>
            </div>

            <div className = 'Admin-content'>
                <h2 className = 'Admin-title'>Jubilee Fellowship</h2>
                <label className = 'Admin-label' htmlFor = 'first name'>Name</label>
                <input
                    onChange = { (e) => setFellowshipBody({ ...fellowshipBody, name: e.target.value}) } 
                    className = 'Input'
                    placeholder = 'Enter fellowship name here' 
                    ref = { blogTitleInput }
                />
                <label className = 'Admin-label' htmlFor = 'first name'>Description</label>
                {/* <input
                    onChange = { (e) => setFellowshipBody({ ...fellowshipBody, description: e.target.value}) } 
                    className = 'Input'
                    placeholder = 'Enter content here' 
                    ref = { blogDescriptionInput }
                /> */}
                
                <CKEditor 
                    editor = { ClassicEditor }
                    onChange = { (e, editor) => {
                        const data = editor.getData();
                        setFellowshipBody({ ...fellowshipBody, description: ReactHTMLParser(data)[0]?.props?.children[0] })
                    }}
                />
                <label className = 'Admin-label' htmlFor = 'first name'>Cover Image</label>
                <div className = 'Admin-multiple-holder'>
                    <input
                        onChange = { (e) => setImage( e.target.files[0] )}
                        className = 'Input' 
                        type = 'file'
                        ref = { blogImageInput }
                    />
                    <button
                        onClick = { () => {
                          generateMediaURiHandler(image);
                        }}
                        id = 'multiple-button' className = 'Admin-button'
                    >
                        Upload
                    </button>
                </div>

                {
                    imageError === true ?

                    <p id = 'fellowship-image-error'>Image size should not exceed 2MB.</p>

                    : ''
                }

                <p style = {{ textAlign: 'start' }}>{ `${ fellowshipBody.coverUri !== '' ?  '1 Image added' : 'No image added' }`}</p>
                { fellowshipBody.coverUri }

                <div className = 'Admin-button-holder'>
                    <button
                        onClick ={ () => {
                            fellowshipUploadHandler();
                            setLoading(true);
                        }}
                        className = 'Admin-button'
                        disabled = { fellowshipBody.name === '' || fellowshipBody.coverUri === '' || fellowshipBody.description === '' }
                        style = { fellowshipBody.name === '' || fellowshipBody.coverUri === '' || fellowshipBody.description === '' ? { opacity: '0.4', cursor: 'unset' } : {} }
                    >
                        Post Fellowship
                    </button>
                </div>
            </div>


            <Footer />

            {
                loading == true ?

                <Loading />

                : ''
            }

            {
                blogAdded == true ?

                <BlankModal>
                    <i style = {{ color: 'green' }} className = 'fas fa-check-circle Modal-icon'></i>
                    <h3 style = {{ marginTop: '1rem', marginBottom: '1rem' }}>Fellowship Added successfully</h3>
                    <button 
                        className = 'Admin-button'
                        onClick = { () => {
                            setBlogAdded(false);
                        } }
                    >
                        close
                    </button>
                </BlankModal>

                : ''
            }

            {
                blogNotAdded == true ?

                <BlankModal>
                    <i style = {{ color: 'red' }} className = 'fas fa-exclamation Modal-icon'></i>
                    <h3 style = {{ marginTop: '1rem', marginBottom: '1rem' }}>Oooop! Error creating fellowship. Please try again.</h3>
                    <button 
                        className = 'Admin-button'
                        onClick = { () => {
                            setBlogNotAdded(false);
                        } }
                    >
                        close
                    </button>
                </BlankModal>

                : ''
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return{
        user: state.user
    }
}

export default connect(mapStateToProps)(AdminFellowship);