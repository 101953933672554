import './blank-modal.css';


const BlankModal = ({ children, style }) => {
    return(
        <div style = { style } id = 'blank-modal-overlay' >
            <div className = 'Blank-modal'>
               {children}
            </div>
        </div>
    )
}


export default BlankModal;