import './find-us.css';
import { useHistory } from 'react-router';

const FindUs = () => {

    const history = useHistory();

    return(
        <div className = 'Find-us'>
            <h1>Worship with us <br /> this Sunday</h1>
            <button
                onClick = { () => {
                    history.push('/contactus')
                }}
                id = 'find-us-button'
            >
                Find Us
            </button>
        </div>
    )
}

export default FindUs;