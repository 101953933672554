import './forms.css';
import Nav from '../nav/nav';
import Footer from '../footer/footer';
import BackgroundImage from '../assets/forms header.jpg';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

const Forms = () => {

    const history = useHistory();
    
    useEffect( () => {
        
        window.scrollTo(0, 0);
        
    }, [])


    return(
        <div className = 'Forms'>
            <Nav />

            <header>
                <img src = { BackgroundImage } className = 'Header-image' alt = 'The header image' />
                <h1 className = 'Forms-header-text'>We are always glad to hear from you</h1>
            </header>
            
            <div id = 'form-holder-parent'>

                <div className = 'Form-holder'>
                    <div id = 'baby-dedication'></div>
                    <div className = 'Form-content'>
                        <p className = 'Form-title'>Baby dedication Form</p>
                        <button 
                            onClick = { () => {
                                history.push('/babydedication');
                            }}
                            className = 'Experience-button'>
                            Fill form 
                            <i className = 'fas fa-chevron-right Form-icon'></i>
                        </button>
                    </div>
                </div>

                
                <div className = 'Form-holder'>
                    <div id = 'naming-ceremony'></div>
                    <div className = 'Form-content'>
                        <p className = 'Form-title'>Naming Ceremony Form</p>
                        <button 
                            onClick = { () => {
                                history.push('/namingceremony');
                            }}
                            className = 'Experience-button'>
                            Fill form 
                            <i className = 'fas fa-chevron-right Form-icon'></i>
                        </button>
                    </div>
                </div>

                <div className = 'Form-holder'>
                    <div id = 'intention-to-wed'></div>
                    <div className = 'Form-content'>
                        <p className = 'Form-title'>Intention to wed</p>
                        <button 
                            onClick = { () => {
                                history.push('/intentiontowed');
                            }}
                            className = 'Experience-button'>
                            Fill form 
                            <i className = 'fas fa-chevron-right Form-icon'></i>
                        </button>
                    </div>
                </div>

                <div className = 'Form-holder'>
                    <div id = 'volunteer'></div>
                    <div className = 'Form-content'>
                        <p className = 'Form-title'>Volunteer Commitment Form</p>
                        <button 
                            onClick = { () => {
                                history.push('/volunteering');
                            }}
                            className = 'Experience-button'>
                            Fill form 
                            <i className = 'fas fa-chevron-right Form-icon'></i>
                        </button>
                    </div>
                </div>

                <div className = 'Form-holder'>
                    <div id = 'workers'></div>
                    <div className = 'Form-content'>
                        <p className = 'Form-title'>Potential Workers' Form</p>
                        <button 
                            onClick = { () => {
                                history.push('/potentialworker');
                            }}
                            className = 'Experience-button'>
                            Fill form 
                            <i className = 'fas fa-chevron-right Form-icon'></i>
                        </button>
                    </div>
                </div>

            </div>

            <Footer />
        </div>
    )
}

export default Forms;