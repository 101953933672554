import './fellowships.css';
import Nav from '../nav/nav';
import Footer from '../footer/footer';
import BackgroundImage from '../assets/expressions background.jpg';
import { useEffect } from 'react';
import { apiErrorHandler, requests } from '../../api';
import { useState } from 'react';

const Fellowships = () => {

    const [ fellowships, setFellowships ] = useState([]);
    const [ showFellowship, setShowFellowship ] = useState([]);
    const [ searchValue, setSearchValue ] = useState('');
    const [ searchResult, setSearchResult ] = useState([]);

    useEffect( () => {

        window.scrollTo(0, 0);

        const getFellowshipsHandler = async () => {
            try{
                const response = await requests.getFellowships();
                setFellowships(response.docs)
            }catch(error){
                const { message } = apiErrorHandler(error);
            }
        }

        getFellowshipsHandler();

    }, [])

    return(
        <div className = 'Forms'>
            <Nav />

            <header>
                <img src = { BackgroundImage } className = 'Header-image' alt = 'The header image' />
                <h1 className = 'Expressions-header-text'>Fellowships!</h1>
            </header>
            
            <div className = 'Fellowships-holder-parent'>
                {
                    showFellowship?.length < 1 ?

                    <div id = 'fellowships-holder'>
                        {
                            fellowships?.length > 0 && fellowships.map( (fellowship, index) => {
                                return  <div
                                            onClick = { () => setShowFellowship(fellowship) }
                                            key = { index }
                                            className = 'Fellowship-item'
                                        >
                                            <div className = 'Blog-image-holder'>
                                                <img className = 'Blog-cover-image' src = { fellowship.coverUri } alt = 'fellowship picture' />
                                            </div>
                                            <div className = 'Blog-item-bottom-holder'>
                                                <h3 className =  'Fellowship-name'>{ fellowship.name }</h3>
                                                <button id = 'fellowship-item-button' style = {{ border: 'none' }} className = 'Nav-item Radial-button'>Read more <i style = {{ marginLeft: '0.5rem' }} className = 'fas fa-chevron-right'></i></button>
                                            </div>

                                        </div>
                            })
                        }
                    </div>

                    : 

                    <div id = 'detailed-fellowship'>
                        <div id = 'detailed-fellowship-left'>
                            <img className = 'Blog-cover-image' src = { showFellowship.coverUri } alt = 'fellowship picture' />
                            <p id = 'list-fellowship-description'>{ showFellowship.description }</p>
                            <div id = 'fellowship-prev-button-holder'>
                                <button 
                                    onClick = { () => setShowFellowship([]) }
                                    id = 'find-us-button'
                                >
                                    <i style = {{ marginRight: '1rem' }} className = 'fas fa-chevron-left'></i>  
                                    Prev
                                </button>
                            </div>
                        </div>
                        <div id = 'detailed-fellowship-right'>
                            <div className = 'List-fellowship-search-holder'>
                                <div id = 'search-holder'>
                                    <input
                                        onChange = { (e) => {
                                            setSearchValue( e.target.value );
                                            const result = [];
                                            for(let i = 0; i < fellowships.length ; i++){
                                                if(fellowships[i].name.toLowerCase().includes(searchValue)){
                                                    result.push(fellowships[i]);
                                                    setSearchResult(result);
                                                }   
                                            }
                                        }}
                                        className = 'List-fellowship-search-input' 
                                        type = 'search' 
                                        placeholder = 'Search' 
                                    />
                                    <i className = 'fas fa-search List-fellowship-search-icon'></i>
                                </div>
                            </div>  
                            <div className = 'List-fellowship-item-holder'>
                                <h3 id = 'fellowship-search-title'>Fellowships</h3>
                                {
                                    searchValue == '' ?

                                    <div>
                                        {
                                            fellowships?.length > 0 && fellowships.map( (fellowship, index) => {
                                                return  <div 
                                                            onClick = { () => setShowFellowship(fellowship) }
                                                            className = 'List-fellowship-item'
                                                            key = { index }
                                                        >
                                                                <img className = 'Fellowship-list-cover-image' src = { fellowship.coverUri } alt = 'fellowship picture' />
                                                                <h5 id = 'fellowship-search-list-name'>{ fellowship.name }</h5>
                                                        </div>
                                            })
                                        }
                                    </div>

                                    : 

                                    <div>
                                        {
                                            searchResult?.length > 0 && searchResult.map( (result, index) => {
                                                return  <div 
                                                            onClick = { () => setShowFellowship(result) }
                                                            className = 'List-fellowship-item'
                                                            key = { index }
                                                        >
                                                                <img className = 'Fellowship-list-cover-image' src = { result.coverUri } alt = 'fellowship picture' />
                                                                <h5>{ result.name }</h5>
                                                        </div>
                                            })
                                        }
                                    </div>
                                    
                                }
                            </div>
                        </div>
                    </div>
                }
                
            </div>

            <Footer />
        </div>
    )
}

export default Fellowships;