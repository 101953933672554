import './our-church.css';
import Nav from '../../nav/nav';
import Footer from '../../footer/footer';
import Pastor from '../../assets/Pst Gbolahan Fowowe.jfif';
import BackgroundImage from '../../assets/our church header.jpg';
import OurVisionBackground from '../../assets/Our Vision.jpg';
import OurVisionImage1 from '../../assets/our vision image 1.jpg';
import OurVisionImage2 from '../../assets/our vision image 2.jpg';
import { useHistory } from 'react-router';
import { useEffect } from 'react';

const OurChurch = () => {

    const history = useHistory();
    
    useEffect( () => {
        
        window.scrollTo(0, 0);
        
    }, [])



    return(
        <div className = 'Our-church'>
            <Nav />

            <header>
                <img src = { BackgroundImage } className = 'Header-image' alt = 'The header image' />
                <p className = 'About-header-text'>About Us</p>
            </header>



            <div id = 'about-jubilee'>
                <p>
                    Jubilee was founded in December 1993 with 7 people sent out from Freedom Hall on
                    Jimoh Odutola, Eric Moore, Surulere. At that time, the church was known as Aguda 
                    Central Parish and was pastored by Gbenga Olayiwole. He was assisted by Sola 
                    Fola-Alade, both of whom are now pastoring thriving churches in the USA and UK
                    respectively.
                    <br />
                    <br />
                    In May 1994, Pastor Kemi Oluyele took over from Pastor Gbenga
                    Olayiwole who was posted out on another assignment. About mid 1994 we did not
                    have a definite location and we tagged ourselves as “church on the move”. 
                    In late 1994, it was a relief to find a stable place of worship at our present location 
                    (15, Williams Street, Off Adenuga Street, Aguda, Lagos).
                </p>
            </div>

            <div id = 'pastor-ojo-holder'>
                <img id = 'pastor-image' src = { Pastor } alt = "The pastor's image" />
                <div id = 'about-pastor'>
                    <h2>Our Pastor</h2>
                    <p>
                        
                        GBOLAHAN FOWOWE
                        <br />
                        Gbolahan Fowowe holds a B.Sc. degree in Building from Obafemi Awolowo University, Ile-Ife. 
                        <br /><br />

                        He is the Chief Executive Officer of Construction and Allied Services with over 30 years hands-on experience in the building construction industry, he has acquired diverse skills in start ups, business development and planning, strategic planning, construction management, contract negotiation, facilities management, human resource management and project management amongst others.
                        <br /><br />
                        
                        As a leader in ministry, he has served as Pastor in Charge of Parish at the following Parishes of The Redeemed Christian Church of God: Household of Faith Parish, Lion of Judah Parish, Jesus Sanctuary Parish, Church of the Nazarene Parish and Cornerstone Parish (My Father’s house).
                        <br /><br />

                        He is currently The Assistant Pastor In Charge of Province (APICP) of Lagos Province 15, Region 20, (Apapa family).
                        <br /><br />

                        He believes that our love for Christ, enthroning His kingdom here on earth and fulfilling purpose should be the core and focus of every heavenly bound believer who desires to be earthly impactful.
                        <br /><br />
                        
                        He is happily married to Tomi Fowowe and they are blessed with children.
                      
                    </p>
                    <div id = 'pastor-social-media'>
                        <a className = 'Pastor-social' href = '#'>
                            <i id = 'pastor-social-icon' className = 'fab fa-facebook-f' ></i>
                        </a>
                        <a className = 'Pastor-social' href = '#'>
                            <i id = 'pastor-social-icon' className = 'fab fa-instagram' ></i>
                        </a>
                        <a className = 'Pastor-social' href = '#'>
                            <i id = 'pastor-social-icon' className = 'fab fa-twitter' ></i>
                        </a>
                    </div>
                </div>
            </div>
            <div id = 'pastorate-holder'>
                <button onClick = { () => history.push('/ourpastorate') } id = 'pastorate-button' className = 'Experience-button'>Our Pastorate</button>
            </div>

            <div id = 'our-church-statement-holder'>
                <h2>Our Focus</h2>
                <div id = 'our-church-statement-content'>
                    <div className = 'Statement-holder'>
                        
                        <p>
                            We <b style = {{ color: 'black' }}>GROW</b> 
                            <br />
                            In our relationship with
                            <br /> God through
                            <br />
                            The <b style = {{ color: 'black' }}>Word</b>
                            <br />
                            <b style = {{ color: 'black' }}>Worship</b> & 
                                <br />
                                In <b>Prayer</b>
                        </p>
                    </div>

                    <div className = 'Statement-holder'>
                        
                        <p>
                            We <b style = {{ color: 'black' }}>GIVE</b>
                            <br />
                            Our
                            <br />
                            <b style = {{ color: 'black' }}>Time</b> 
                            <br />
                            <b style = {{ color: 'black' }}>Substance</b> 
                            <br />
                            <b style = {{ color: 'black' }}>Heart</b>
                            <br />
                            <br />
                            
                        </p>
                    </div>

                    <div className = 'Statement-holder'>
                        
                        <p>
                            We <b style = {{ color: 'black' }}>GO</b>
                            <br />
                            To the world through
                            <br />
                            <b style = {{ color: 'black' }}>Evangelism</b>
                            <br />
                            <b style = {{ color: 'black' }}>Outreaches</b> & 
                            <br />
                            <b style = {{ color: 'black' }}>Missions</b>
                        </p>
                    </div>
                </div>
            </div>

            <div id = 'our-vision-holder'>
                <img id = 'our-vision-background' src = { OurVisionBackground } alt = 'a background image' />
                <h1 id = 'our-vision-text'>Our Vision</h1>
            </div>

            <div id = 'vision-content'>
                <p>1. It is our goal to make heaven.
                    <br />
                    2. It is our goal to take as many people as possible with us. 
                    <br />
                    3. In order to accomplish our goals, holiness will be our lifestyle. 
                    <br />
                    4. In order to take as many people with us as possible, we will plant churches within five minutes walking distance in every
                    <br />
                    city and town of developing countries; and within five minutes driving distance in every city and town of developed countries. 
                    <br />
                    5. We will pursue these objectives until every nation in the world is reached for Jesus Christ our Lord.</p>
            </div>

            <div id = 'our-vision-images-holder'>
                <img className = 'Our-vision-image' src = { OurVisionImage1 } alt = 'a image' />  
                <img className = 'Our-vision-image' src = { OurVisionImage2 } alt = 'a image' />
            </div>


            <Footer />
        </div>
    )
}

export default OurChurch;