import './call-to-actions.css';
import SongOfTheWeek from '../../assets/wade.mp4';
import { useHistory } from 'react-router-dom';
import { apiErrorHandler, requests } from '../../../api';
import Loading from '../../loading/loading';
import { useEffect, useState } from 'react';

const CallToActions = () => {

    const history = useHistory();
    const [ song, setSong ] = useState({});
    const [ announcement, setAnnouncement ] = useState({});

    useEffect( () => {

        const getSong = async () => {
            let songUrl = '';
            try{
                const response = await requests.getMediaOfTheWeek('song');
                setSong(response.data);
                songUrl = response.data.videoUrl.replace('https://www.youtube.com/watch', 'https://www.youtube.com/embed');
            }catch(error){
                const message = apiErrorHandler(error);
            }
            setSong({
                ...song, videoUrl: songUrl
            });
        }


        const getAnnouncement = async () => {
            let announcementUrl = '';
            try{
                const response = await requests.getMediaOfTheWeek('announcement');
                setAnnouncement(response.data);
                announcementUrl = response.data.videoUrl.replace('https://www.youtube.com/watch', 'https://www.youtube.com/embed');
            }catch(error){
                const message = apiErrorHandler(error);
            }

            setAnnouncement({
                ...announcement, videoUrl: announcementUrl
            });
        }
        

        getSong();
        getAnnouncement();



    }, [])

    return(
        <div className = 'Action'>
            <div>
                <div className = 'Action-holder'>
                    <div id = 'action-1'>
                        <div className = 'Action-button-holder'>
                            <button
                                onClick = { () => {
                                    history.push('/sermons')
                                }}
                                className = 'Experience-button Call-button'
                            >
                                Messages and more
                            </button>
                        </div>
                    </div>
                    <div id = 'action-2'>
                        <div className = 'Action-button-holder'>
                            <button
                                onClick = { () => {
                                    history.push('/becomeamember');
                                }}
                                className = 'Experience-button Call-button'
                        >
                            Become a member
                        </button>
                        </div>
                    </div>
                </div>
                <div id = 'announcement-holder'>
                    <iframe className = 'Media-iframe' id = 'song-of-the-week' src = { song.videoUrl } title = 'Song of the week'></iframe>
                 
                </div>  
            </div>

            <div>
                <div className = 'Action-holder'>
                    <div id = 'action-3'>
                        <div className = 'Action-button-holder'>
                            <button 
                                onClick = { () => {
                                    history.push('/give')
                                }}
                                className = 'Experience-button Call-button'
                            >
                                Give
                            </button>
                        </div>
                    </div>
                    <div id = 'action-4'>
                        <div className = 'Action-button-holder'>
                            <button
                                onClick = { () => {
                                    history.push('/devotional');
                                }} 
                                className = 'Experience-button Call-button'
                            >
                                Download our devotional
                            </button>
                        </div>
                    </div>
                </div>
                <div id = 'song-of-the-week-holder'>
                    <iframe className = 'Media-iframe' id = 'song-of-the-week' src = { announcement.videoUrl } title = 'Announcement' ></iframe>
                </div>  
            </div>

        </div>
    )
}

export default CallToActions;