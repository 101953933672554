import './our-pastorate.css';
import Nav from '../../nav/nav';
import Footer from '../../footer/footer';
import { useState, useEffect } from 'react';
import BackgroundImage from '../../assets/our pastorate header.jpg';
import { apiErrorHandler, requests } from '../../../api';
import Loading from '../../loading/loading';

const OurPastorate = () => {

    const [ pastors, setPastors ] = useState([]);
    const [ openReadMore, setOpenReadMore ] = useState([]);
    const [ hovered, setHovered ] = useState(false);
    const [ viewPastor, setViewPastor ] = useState([]);
    const [ loading, setLoading ] = useState(false);

    useEffect( () => {

        
        window.scrollTo(0, 0);


        const getPastorsHandler = async () => {
            try{
                const response = await requests.getPastors();
                setPastors(response.docs);
            }catch(error){  
                const { message } = apiErrorHandler(error);
            }finally{
                setLoading(true);
            }
        }

        getPastorsHandler();
    }, []);



    return(
        <div className = 'Our-pastorate'>
            <Nav />

                <header>
                    <img src = { BackgroundImage } className = 'Header-image' alt = 'The header image' />
                    <h1 className = 'Header-text'>Our Pastorate</h1>
                </header>

                {
                    viewPastor?.length < 1 ? 

                    <div id = 'pastors-holder'>
                        {
                            pastors?.length > 0 && pastors.map( ( pastor, index) => {
                                return  <div 
                                            onClick = { () => {
                                                setViewPastor(pastor) ;
                                            }}
                                            onMouseEnter = { () => {
                                                setOpenReadMore([ ...openReadMore, index ]);
                                                setHovered(true);
                                            }}
                                            onMouseLeave = { () => {
                                                for(let i = 0; i < openReadMore.length; i++){
                                                    if( openReadMore[i] === index ){
                                                        openReadMore.splice(i, 1)
                                                    }
                                                }
                                                setHovered(false);
                                            } }
                                            className = 'Pastors' 
                                            key = { index }
                                        >
                                            <img className = 'Pastor-image' src = { pastor.coverUris[0] } />
                                            <div className = 'Pastor-name-holder'>
                                                <h3 className = 'Pastor-name'>{ `${ pastor.firstName + ' ' + pastor.lastName}` }</h3>
                                                <p className = 'Read-more' style = { openReadMore.includes(index) && hovered === true ? { visibility: 'visible' } : { visibility: 'hidden' } }>Read more <i style = {{ marginLeft: '1rem' }} className = 'fas fa-chevron-right'></i></p>
                                            </div>
                                        </div>
                            })
                        }
                    </div>

                    : 

                    
                    <div id = 'pastors-holder'>
                        <div id = 'pastor-details-holder'>
                            {
                                viewPastor?.coverUris.length < 2 ?
                                
                	            <img id = 'pastorate-modal-image' src = { viewPastor.coverUris[0] } />

                                : 
                                
                	            <img id = 'pastorate-modal-image' src = { viewPastor.coverUris[1] } />

                            }
                            <p id = 'pastorate-detail'>{ viewPastor.biography }</p>
                        </div>
                        <div id = 'pastorate-button-holder'>
                            <button onClick = { () => setViewPastor([]) } id = 'pastorate-button' className = 'Experience-button'>Our Pastorate</button>
                        </div>
                    </div>

                }
            <Footer />

            {
                !loading ?

                <Loading />

                : ''
            }

        </div>
    )
}

export default OurPastorate;