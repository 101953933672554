import './admin.css';
import Nav from '../nav/nav';
import Footer from '../footer/footer';
import { useHistory } from 'react-router';
import { useEffect } from 'react';
import { connect } from 'react-redux';


const AdminPage = ({ user }) => {

    const history = useHistory();

    useEffect( () => {
        
        window.scrollTo(0, 0);
        
    }, [])



    return(
        <div className = 'Verse-of-the-day'>
            <Nav />

            <div id = 'user-holder'>
                <button className = 'Admin-back-button' onClick = { () => history.goBack() }></button>
                <p id = 'user'>Hi, { user.firstName }</p>
            </div>

            <div id = 'admin-page-content'>
                
                <h1>Admin</h1>
                <div id = 'admin-page-content-holder'>

                    {
                        user.role.toLowerCase().includes('super_admin') ?
                        
                        <div className = 'Admin-item-holder'>
                            <button onClick = { () => history.push('/addadmin')} className = 'Admin-page-button'>Add admin personel</button>
                        </div>

                        : ''
                    }

{
                        user.role.toLowerCase().includes('super_admin') ?
                        
                        <div className = 'Admin-item-holder'>
                            <button onClick = { () => history.push('/adminusers')} className = 'Admin-page-button'>Get all admin personels</button>
                        </div>

                        : ''
                    }



                    <div className = 'Admin-item-holder'>
                        <button onClick = { () => history.push('/adminblogupload')} className = 'Admin-page-button'>Jubilee Blog</button>
                    </div>

                    <div className = 'Admin-item-holder'>  
                        <button onClick = { () => history.push('/adminverseupload')} className = 'Admin-page-button'>Verse of the day</button>
                    </div>

                    <div className = 'Admin-item-holder'>   
                        <button onClick = { () => history.push('/adminfellowship')} className = 'Admin-page-button'>Fellowships</button>
                    </div>

                    <div className = 'Admin-item-holder'>   
                        <button onClick = { () => history.push('/admingallery')} className = 'Admin-page-button'>Gallery</button>
                    </div>

                    <div className = 'Admin-item-holder'>   
                        <button onClick = { () => history.push('/adminscheduleevent')} className = 'Admin-page-button'>Schedule Event</button>
                    </div>

                    <div className = 'Admin-item-holder'>   
                        <button className = 'Admin-page-button'>Featured Sermons</button>
                    </div>

                    <div className = 'Admin-item-holder'>
                        <button onClick = { () => history.push('/adminpastorupload')} className = 'Admin-page-button'>Pastorate</button>
                    </div>

                    <div className = 'Admin-item-holder'>
                        <button onClick = { () => history.push('/jubileeadmindevotional')} className = 'Admin-page-button'>Jubilee Devotional</button>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

const mapStateToProps = ( state ) => {
    return{
        user: state.user
    }
}


export default connect(mapStateToProps)(AdminPage);