import './contact-us.css';
import Nav from '../nav/nav';
import Footer from '../footer/footer';
import BackgroundImage from '../assets/Laughing\ black\ lady.jpg';
import SocialMedia from '../social-media/social-media';
import Map from '../google-map/google-map';
import { useEffect } from 'react';

const ContactUs = () => {


    useEffect( () => {
        
        window.scrollTo(0, 0);

    }, [])


    return(
        <div className = 'Contact-us'>
            <Nav />

            <header>
                <img src = { BackgroundImage } className = 'Header-image' alt = 'The header image' />
                <h1 className = 'Header-text'>We'd love to hear <br /> from you!</h1>
            </header>

            <div id = 'contact-form-holder'>
                <div className = 'Contact-inputs-holder'>
                    <div className = 'Input-holder'>
                        <input className = 'Contact-input' type = 'text' placeholder = 'First Name' />
                    </div>
                    <div className = 'Input-holder'>
                        <input className = 'Contact-input' type = 'text' placeholder = 'Last Name' />
                    </div>
                </div>
                <div className = 'Contact-inputs-holder'>
                    <div className = 'Input-holder'>
                        <input className = 'Contact-input' type = 'text' placeholder = 'Email' />
                    </div>
                    <div className = 'Input-holder'>
                        <input className = 'Contact-input' type = 'text' placeholder = 'Phone Number' />
                    </div>
                </div>
                <div id = 'textarea-holder'>
                    <textarea placeholder = 'Type your message here' id = 'contact-textarea' />
                </div>
                <button id = 'contact-button' className = 'Nav-item Radial-button'>SUBMIT</button>

            </div>

            <div id = 'find-us-holder'>
                <h1>Find Us</h1>

                {/* <Map
                    googleMapURL = { `https://maps.googleapis.com/maps/api/js?v=3.exp&library=geometry,drawing,places&key=AIzaSyBDHYYwtMjz7HuBljcYR7O1Jk5Fi3suA0w` }
                    loadingElement = { <div id = 'map-loading-element' /> }
                    containerElement = { <div style={{ height: `1000px`}} /> }
                    mapElement = { <div style={{ height: `100%`, borderRadius: '10px' }} /> }
                /> */}

                <div className = "Mapouter">
                    <div className = "Gmap_canvas">
                        <iframe 
                            width="600" 
                            height="500" 
                            id="gmap_canvas" 
                            src="https://maps.google.com/maps?q=williams%20close%20aguda%20surulere%20Lagos&t=&z=13&ie=UTF8&iwloc=&output=embed" 
                            frameborder="0" 
                            scrolling="no" 
                            marginheight="0" 
                            marginwidth="0">
                        </iframe>
                    </div>
                </div>

                
            </div>

            <SocialMedia />

            <Footer />
        </div>
    )
}

export default ContactUs;