import './pastorate.css';
import Nav from '../../nav/nav';
import Footer from '../../footer/footer';
import { useState, useEffect } from 'react';
import { apiErrorHandler, requests } from '../../../api';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactHTMLParser from 'react-html-parser';
import Loading from '../../loading/loading';
import BlankModal from '../../modal/blank-modal/blank-modal';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';


const Pastorate = ({user}) => {

    const history = useHistory();
    const [ images, setImages ] = useState();
    const [ loading, setLoading ] = useState(false);
    const [ pastorAdded, setPastorAdded ] = useState(false);
    const [ imageError , setImageError ] = useState(false);
    const [ pastorNotAdded, setPastorNotAdded ] = useState(false);
    const [ pastorate, setPastorate ] = useState({
            firstName: "",
            lastName: "",
            otherName: "",
            biography: "",
            coverUris: []
          });
    // const [ progress, setProgress ] = useState(null);
    let mediaType = 'cover';

    const pastorUploadHandler = async () => {

        try{
            const response = await requests.createPastor(pastorate);
            setPastorAdded(true);
        }catch(error){
            const { message } = apiErrorHandler(error);
            setPastorNotAdded(true);
        }finally{
            setLoading(false);
        }
    }

    const generateMediaURiHandler = async (image) => {

        const formData = new FormData()
            formData.append(
            "file",
            image
        )

        try{
            const response = await requests.generateMediaUri(mediaType, formData)
            setPastorate({ ...pastorate, coverUris: [ ...pastorate.coverUris, response.data.resourceUrl ]});
            setImageError(false);
        }catch(error){
            const { message } = apiErrorHandler(error);
            setImageError(true);
        }
    }

    useEffect( () => {
        
        window.scrollTo(0, 0);

    }, []);

    return(
        <div className = 'Verse-of-the-day'>
            <Nav />

            <div id = 'user-holder'>
                <button className = 'Admin-back-button' onClick = { () => history.goBack() }>Back</button>
                <p id = 'user'>Hi, { user.firstName }</p>
            </div>

            <div className = 'Admin-content'>
                <h2 className = 'Admin-title'>Upload Pastor</h2>
                <label className = 'Admin-label' htmlFor = 'first name'>First name</label>
                <input
                    onChange = { (e) => setPastorate({ ...pastorate, firstName: e.target.value }) }
                    placeholder = 'Enter first name here'
                    className = 'Input'
                    name = 'first name'
                />
                <label className = 'Admin-label' htmlFor = 'last name'>Last name</label>
                <input
                    onChange = { (e) => setPastorate({ ...pastorate, lastName: e.target.value }) } 
                    className = 'Input'
                    placeholder = 'Enter last name here' 
                    name = 'last name'
                />
                <label className = 'Admin-label' htmlFor = 'middle name'>Middle name</label>
                <input
                    onChange = { (e) => setPastorate({ ...pastorate, otherName: e.target.value }) } 
                    className = 'Input'
                    placeholder = 'Enter middle name here' 
                    name = 'middle name'
                />
                <label className = 'Admin-label' htmlFor = 'biography'>Pastor Information</label>
                {/* <textarea
                    onChange = { (e) => setPastorate({ ...pastorate, biography: e.target.value }) }
                    placeholder = 'Enter pastor information here'
                    className = 'Admin-textarea'
                    rows = '5'
                    name = 'biography'
                /> */}
                <CKEditor 
                    editor = { ClassicEditor }
                    onChange = { (e, editor) => {
                        const data = editor.getData();
                        setPastorate({ ...pastorate, biography: ReactHTMLParser(data)[0]?.props?.children[0] });
                    }}
                />

                <label className = 'Admin-label' htmlFor = 'biography'>Add Image</label>
                <div className = 'Admin-multiple-holder'>
                    <input
                        onChange = { (e) => setImages( e.target.files[0] )}
                        className = 'Input' 
                        placeholder = 'Enter middle name here'
                        type = 'file'
                    />
                    <button
                        onClick = { () => {
                          generateMediaURiHandler(images);
                        }}
                        id = 'pastorate-upload-button' className = 'Admin-button'
                    >
                        Upload
                    </button>
                </div>

                {
                    imageError === true ?

                    <p id = 'fellowship-image-error'>Image size should not exceed 2MB.</p>

                    : ''
                }

                <p style = {{ textAlign: 'start' }}>Please add two images, one alone and another with the pastor's spouse.</p>
                <p style = {{ textAlign: 'start' }}>{ `${(pastorate.coverUris?.length ? pastorate.coverUris.length: 0) + ' ' + 'image(s)' + ' ' + 'added.' }`}</p>
{/* 
                <div className = 'Progress-holder'>
                    <div 
                        className = 
                        {   
                            progress == 0.1 ? 'Progress-10' :  
                            progress == 0.2 ? 'Progress-20' : 
                            progress == 0.3 ? 'Progress-30' :  
                            progress == 0.4 ? 'Progress-40' :
                            progress == 0.5 ? 'Progress-50' :
                            progress == 0.6 ? 'Progress-60' :
                            progress == 0.7 ? 'Progress-70' :
                            progress == 0.8 ? 'Progress-80' :
                            progress == 0.9 ? 'Progress-90' :
                            progress == 1 ? 'Progress-100' :  ''
                        }
                    >
                        <p className = 'Progress-text'>
                            { 
                                progress == 0.1 ? '10%' :  
                                progress == 0.2 ? '20%' : 
                                progress == 0.3 ? '30%' :  
                                progress == 0.4 ? '40%' :
                                progress == 0.5 ? '50%' :
                                progress == 0.6 ? '60%' :
                                progress == 0.7 ? '70%' :
                                progress == 0.8 ? '80%' :
                                progress == 0.9 ? '90%' :
                                progress == 1 ? '100%' :  ''
                            }
                        </p>
                    </div>
                </div> */}
                
                <div className = 'Admin-button-holder'>
                    <button
                        onClick ={ () => {
                            pastorUploadHandler();
                            setLoading(true);
                        }}
                        className = 'Admin-button'
                        disabled = { pastorate.firstName === '' || pastorate.lastName === '' || pastorate.biography === '' || pastorate.coverUris.length < 1 }
                        style = { pastorate.firstName === '' || pastorate.lastName === '' || pastorate.biography === '' || pastorate.coverUris.length < 1 ? { opacity: '0.4', cursor: 'unset' } : {} }
                    >
                        Post
                    </button>
                </div>
            </div>


            <Footer />

            {
                loading == true ?

                <Loading />

                : ''
            }

            {
                pastorAdded == true ?

                <BlankModal>
                    <i style = {{ color: 'green' }} className = 'fas fa-check-circle Modal-icon'></i>
                    <h3 style = {{ marginTop: '1rem', marginBottom: '1rem' }}>Pastor Added successfully</h3>
                    <button 
                        className = 'Admin-button'
                        onClick = { () => {
                            setPastorAdded(false);
                        } }
                    >
                        close
                    </button>
                </BlankModal>

                : ''
            }

            {
                pastorNotAdded == true ?

                <BlankModal>
                    <i style = {{ color: 'red' }} className = 'fas fa-exclamation Modal-icon'></i>
                    <h3 style = {{ marginTop: '1rem', marginBottom: '1rem' }}>Oooop! Error creating pastor. Please try again.</h3>
                    <button 
                        className = 'Admin-button'
                        onClick = { () => {
                            setPastorNotAdded(false);
                        } }
                    >
                        close
                    </button>
                </BlankModal>

                : ''
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return{
        user: state.user
    }
}

export default connect(mapStateToProps)(Pastorate);