import './upcoming-events.css';
import { useState, useEffect } from 'react';
import { apiErrorHandler, requests } from '../../../api';
import BlankModal from '../../modal/blank-modal/blank-modal';

const UpcomingEvents = () => {

    const date = new Date();
    const month = date.getMonth();

    const [ events, setEvents ] = useState([]);  
    const [ pushedEvent, setPushedEvent ] = useState();
    const [ index, setIndex ] = useState(0);
    const [ eventOn, setEventOn ] = useState(false);
    
    useEffect(() => {

        const getEvents = async () => {
            try{
                const response = await requests.getEvent();
                setEvents(response.docs);
            }catch(error){
                const { message } = apiErrorHandler(error);
            }
        }

        getEvents();

    }, [])

    const scrollEventsForwardHandler = () => {
        let nextEvent = events.shift();
        events.push(nextEvent);
        setIndex( index + 1 );
    }

    const scrollEventsBackwardHandler = () => {
        let nextEvent = events.pop();
        events.unshift(nextEvent);
        setIndex( index + 1 );
    }



    return(
        <div className = 'Upcoming-events'>
            <div id = 'upcoming-events-sub'>
                <div id = 'upcoming-title'>
                    { month === 0 ? <p id = 'month'>{ `${ 'This' + ' ' + 'January'} ` }</p> : ''}
                    { month === 1 ? <p id = 'month'>{ `${ 'This' + ' ' + 'February'} ` }</p> : ''}
                    { month === 2 ? <p id = 'month'>{ `${ 'This' + ' ' + 'March'} ` }</p> : ''}
                    { month === 3 ? <p id = 'month'>{ `${ 'This' + ' ' + 'April'} ` }</p> : ''}
                    { month === 4 ? <p id = 'month'>{ `${ 'This' + ' ' + 'May'} ` }</p> : ''}
                    { month === 5 ? <p id = 'month'>{ `${ 'This' + ' ' + 'June'} ` }</p> : ''}
                    { month === 6 ? <p id = 'month'>{ `${ 'This' + ' ' + 'July'} ` }</p> : ''}
                    { month === 7 ? <p id = 'month'>{ `${ 'This' + ' ' + 'August'} ` }</p> : ''}
                    { month === 8 ? <p id = 'month'>{ `${ 'This' + ' ' + 'September'} ` }</p> : ''}
                    { month === 9 ? <p id = 'month'>{ `${ 'This' + ' ' + 'October'} ` }</p> : ''}
                    { month === 10 ? <p id = 'month'>{ `${ 'This' + ' ' + 'November'} ` }</p> : ''}
                    { month === 11 ? <p id = 'month'>{ `${ 'This' + ' ' + 'December'} ` }</p> : ''}
                    <div id = 'demacator'></div>
                    <h1 id = 'upcoming'>Upcoming <br /> Events</h1>
                </div>
                <div id = 'events-holder'>
                    <i
                        onClick = { scrollEventsBackwardHandler }
                        className = 'fas fa-chevron-left Events-icon'
                    ></i>
                    <div id = 'events-holder-sub'>
                        {
                            events?.length > 0 && events.map( (event, index) => {
                                const date = new Date(event.date);
                                const eventDate = date.getDate().toString();
                                const eventMonth = date.getMonth();
                                const eventDay = date.getDay();
                                return  <div className = 'Event' key = { index }>

                                            <div className = 'Event-date-holder'>
                                                <h2>
                                                    { 
                                                        eventDate?.slice(1, 2) === '1' && eventDate?.slice(0, 1) !== '1' ? `${ eventDate }ST` :
                                                        eventDate?.slice(1, 2) === '2' && eventDate?.slice(0, 1) !== '1' ? `${ eventDate }ND` :
                                                        eventDate?.slice(1, 2) === '3' && eventDate?.slice(0, 1) !== '1' ? `${ eventDate }RD` :
                                                        `${ eventDate }TH` 
                                                    }
                                                    <br /> 
                                                    { eventMonth === 0 ? 'JAN' : ''}
                                                    { eventMonth === 1 ? 'FED' : ''}
                                                    { eventMonth === 2 ? 'MAR' : ''}
                                                    { eventMonth === 3 ? 'APR' : ''}
                                                    { eventMonth === 4 ? 'MAY' : ''}
                                                    { eventMonth === 5 ? 'JUN' : ''}
                                                    { eventMonth === 6 ? 'JUL' : ''}
                                                    { eventMonth === 7 ? 'AUG' : ''}
                                                    { eventMonth === 8 ? 'SEP' : ''}
                                                    { eventMonth === 9 ? 'OCT' : ''}
                                                    { eventMonth === 10 ? 'NOV' : ''}
                                                    { eventMonth === 11 ? 'DEC' : ''}
                                                </h2>

                                            </div>

                                            <h3 className = 'Event-name'>{ event.name }</h3>

                                            <p className = 'Event-venue'>
                                                <i id = 'event-icon' className = 'fas fa-clock'></i>
                                                { event.date.slice(11, 13) > 12 ? `${event.date.slice(11, 16)}pm` : `${event.date.slice(11, 16)}am` }
                                            </p>

                                            <p className = 'Event-venue'>
                                                <i id = 'event-icon' className = 'fas fa-map-marker-alt'></i>
                                                { event.venue }
                                            </p>

                                            <div className = 'Event-register-holder'>
                                                <button
                                                    onClick = { () => {
                                                        setEventOn(true);
                                                        setPushedEvent(event);
                                                    }}
                                                    className = 'Event-register'
                                                >
                                                    Register
                                                </button>     
                                            </div>

                                        </div>
                            })
                        }
                    </div>
                    
                </div>
            </div>
            
            <i
                onClick = { scrollEventsForwardHandler }
                className = 'fas fa-chevron-right Events-icon'
            ></i>

            {

                eventOn === true ?

                <BlankModal onClick = { () => setEventOn(false)}>

                    <div id = 'event-close-holder' >
                        <i 
                            onClick = { () => setEventOn(false)} 
                            className = 'fas fa-times'
                            id = 'event-close-button'
                        ></i>
                    </div>  

                    <h3 id = 'pushed-event-name'>{ pushedEvent?.name }</h3>
                    <p id = 'pushed-event-description'>{ pushedEvent?.description }</p>
                    <p id = 'pushed-event-time'>
                        <i id = 'event-icon' className = 'fas fa-clock'></i>
                        { pushedEvent?.date.slice(11, 13) > 12 ? `${pushedEvent?.date.slice(11, 16)}pm` : `${pushedEvent?.date.slice(11, 16)}am` }
                    </p>
    
                    <div id = 'event-form-holder'>
    
                        <div className = 'Prayer-input-holder'>
                            <input className = 'Prayer-input' type = 'text' placeholder = 'Name' />
                        </div>
        
                        <div className = 'Prayer-input-holder'>
                            <input className = 'Prayer-input' type = 'text' placeholder = 'Phone Number' />
                        </div>
    
                    </div>

                    <button id = 'contact-button' className = 'Nav-item Radial-button'>Register</button>
    
                </BlankModal>

                : ''

            }

        </div>
    )
}

export default UpcomingEvents;