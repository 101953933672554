import Nav from '../../nav/nav';
import Footer from '../../footer/footer';
import { useState} from 'react';
import { useRef } from 'react';
import { apiErrorHandler, auth } from '../../../api';
import Loading from '../../loading/loading';
import BlankModal from '../../modal/blank-modal/blank-modal';
import { useHistory } from 'react-router';
import { useEffect } from 'react';

const ResetPassword = (props) => {

    const history = useHistory();

    let passwordInput = useRef();
    let confirmPasswordInput = useRef();
    const [ loading, setLoading ] = useState(false);
    const [ tokenSent, setTokenSent] = useState(false);
    const [ tokenNotSent, setTokenNotSent ] = useState(false);
    const [ tokenLoading, setTokenLoading ] = useState(false);
    const [ passwordResetDone, setPasswordResetDone] = useState(false);
    const [ verifiedToken, setVerifiedToken ] = useState(false)
;    const [ passwordResetNotDone, setPasswordResetNotDone ] = useState(false);
    const [ resetPasswordBody, setResetPasswordBody ] = useState({
        password: '',
        token: props?.params?.match?.token,
        id: props?.params?.match?.id
    });
    const [ confirmPassword, setConfirmPassword ] = useState('');

    useEffect( () => {

        window.scrollTo(0, 0);

        const VerifyTokenHandler = async () => {
            try{
                const response = auth.verifyToken(props?.params?.match?.token, props?.params?.match?.id);
            }catch(error){
                const { message } = apiErrorHandler(error);
                setVerifiedToken(true);
            }finally{
                setLoading(true);
            }
        }

        VerifyTokenHandler();

    }, [])


    const ResetPasswordHandler = async () => {
        try{
            const response = await auth.resetPassword(resetPasswordBody);
            setPasswordResetDone(true);
        }catch(error){
            const { message } = apiErrorHandler(error);
            setPasswordResetNotDone(true);
        }finally{
            passwordInput.current.value = '';
            confirmPasswordInput.current.value = '';
            setLoading(false);
        }
    }

    const resendEmailHandler = async () => {
        try{
            const response = await auth.requestToken({ email: props?.params?.match?.email });
            setTokenSent(true);
        }catch(error){
            const { message } = apiErrorHandler(error);
            setTokenNotSent(true);
        }finally{
            setTokenLoading(false);
        }
    }


    return(
        <div className = 'Verse-of-the-day'>
            <Nav />

            <div className = 'Admin-content'>
                <h2 className = 'Admin-title'>Password Reset</h2>
                <input
                    onChange = { (e) => {
                        setResetPasswordBody({
                            ...resetPasswordBody, password: e.target.value
                        })
                    } } 
                    className = 'Input'
                    placeholder = 'Enter password here' 
                    ref = { passwordInput }
                />
                <div id = 'password-instruction-holder'>
                    <p>(Password length must be greate than eight)</p>
                </div>
                <input
                    onChange = { (e) => {
                        setConfirmPassword(e.target.value);
                    } } 
                    className = 'Input'
                    placeholder = 'Confirm password' 
                    ref = { confirmPasswordInput }
                />
                <div className = 'Admin-button-holder'>
                    <button
                        onClick ={ () => {
                            ResetPasswordHandler();
                            setLoading(true);

                        }}
                        disabled = { resetPasswordBody.password !== confirmPassword|| resetPasswordBody.password === '' || resetPasswordBody.password.length < 9 }
                        style = { resetPasswordBody.password !== confirmPassword|| resetPasswordBody.password === ''|| resetPasswordBody.password.length < 9 ? { opacity: '0.4' } : {} }
                        className = 'Admin-button'>Reset Password</button>
                </div>
            </div>




            <Footer />

            {
                loading ?

                <Loading />

                : ''
            }

            {
                passwordResetDone == true ?

                <BlankModal>
                    <i style = {{ color: 'green' }} className = 'fas fa-check-circle Modal-icon'></i>
                    <h3 style = {{ marginTop: '1rem', marginBottom: '1rem' }}>Password reset successful</h3>
                    <button 
                        className = 'Admin-button'
                        onClick = { () => {
                            history.push('/adminlogin')
                        } }
                    >
                        Proceed to login
                    </button>
                </BlankModal>

                : ''
            }

            {
                passwordResetNotDone == true ?

                <BlankModal>
                    <i style = {{ color: 'red' }} className = 'fas fa-exclamation Modal-icon'></i>
                    <h3 style = {{ marginTop: '1rem', marginBottom: '1rem' }}>Oooop! Error reseting password. Please try again.</h3>
                    <button 
                        className = 'Admin-button'
                        onClick = { () => {
                            setPasswordResetNotDone(false);
                        } }
                    >
                        close
                    </button>
                </BlankModal>

                : ''
            }

            {
                verifiedToken == true ?

                <BlankModal>
                    <i style = {{ color: 'red' }} className = 'fas fa-exclamation Modal-icon'></i>
                    <h3 style = {{ marginTop: '1rem', marginBottom: '1rem' }}>Oooop! Error reseting password. Please try again.</h3>
                    <button 
                        className = 'Admin-button'
                        onClick = { () => {
                            resendEmailHandler();
                            setTokenLoading(true);
                        } }
                    >
                        Resend email
                    </button>
                </BlankModal>

                : ''
            }

            {/* {
                tokenLoading == true ?

                <Loading />

                : ''
            } */}

            {
                tokenSent == true ?

                <BlankModal>
                    <i style = {{ color: 'green' }} className = 'fas fa-check-circle Modal-icon'></i>
                    <h3 style = {{ marginTop: '1rem', marginBottom: '1rem' }}>Password reset request successful</h3>
                    <p>An email has been sent to your mailbox</p>
                    <button 
                        className = 'Admin-button'
                        onClick = { () => {
                            history.push('/adminlogin')
                        } }
                    >
                        close
                    </button>
                </BlankModal>

                : ''
            }

            {
                tokenNotSent == true ?

                <BlankModal>
                    <i style = {{ color: 'red' }} className = 'fas fa-exclamation Modal-icon'></i>
                    <h3 style = {{ marginTop: '1rem', marginBottom: '1rem' }}>Oooop! Error requesting password reset. Please try again.</h3>
                    <button 
                        className = 'Admin-button'
                        onClick = { () => {
                            history.push('/')
                        } }
                    >
                        close
                    </button>
                </BlankModal>

                : ''
            }

        </div>
    )
}

export default ResetPassword;