import './home.css';
import Nav from '../nav/nav';
import Verse from '../verse/verse';
import SocialMedia from '../social-media/social-media';
import { useEffect, useState } from 'react';
import Footer from '../footer/footer';
import Statements from './statements/statements';
import FindUs from './find-us/find-us';
import JubileeBlog from './jubilee-blog/jubilee-blog';
import GallerySlider from './gallery-slider/gallery-slider';
import CallToActions from './call-to-actions/call-to-actions';
import UpcomingEvents from './upcoming-events/upcoming-events';
import BackgroundVideo from '../assets/jubilee-mashup-compress.mp4';
import { useHistory } from 'react-router-dom';

const Home = () => {

    const [ displayVerse, setDisplayVerse ] = useState(false);

    const setVerse = () => {
        setTimeout(() => {
            setDisplayVerse(true);
        }, 2000);
    }

    const history = useHistory();

    useEffect(() => {

        setVerse();

    }, [])

    return(
        <div className = 'Home'>
            <Nav />
            <header>
                {/* <img src = { Header } id = 'home-header' alt = 'The header image'/> */}
                <video autoPlay loop id = 'video-header'>
                    <source src = { BackgroundVideo } />
                </video>
                <div id = 'header-text'>
                    <p>Welcome to <br /> Church</p>
                    <button className = 'Home-experience-button'>
                        Experience our service
                        <i id = 'down-icon' className = 'fas fa-play-circle' ></i>
                    </button>
                </div>
                <SocialMedia />
            </header>

            <div id = 'b-holder'>
                <div id = 'b-1'>
                    <h1 id = 'b-header'>Grow, Give, Go</h1>
                    <p>
                        We GROW
                        In our relationship with God through
                        The Word
                        Worship &
                        In Prayer

                        <br />
                        <br />

                        We GIVE
                        Our
                        Time
                        Substance
                        Heart

                        <br />
                        <br />

                        We GO
                        To the world through
                        Evangelism,
                        Outreaches &
                        Missions
                        
                    </p>
                </div>
                <div id = 'b-2'>
                    <div id = 'b-2-holder'>
                        <div>
                            <h5>We are glad you're here!</h5>
                            <p>See what God can do through</p>
                        </div>
                        <button 
                            onClick = { () => { history.push('/give')} }
                            className = 'B-2-button'
                        >
                            <i id = 'b-2-icon' className = 'fas fa-hand-holding-usd' ></i>
                            Giving
                        </button>
                        <button 
                            onClick = { () => history.push('/fellowships')}
                            className = 'B-2-button'
                        >
                            <i id = 'b-2-icon' className = 'fas fa-users' ></i>
                            Fellowships
                        </button>
                        <button
                            onClick = { () => history.push('/sermons')} 
                            className = 'B-2-button'
                        >
                            <i id = 'b-2-icon' className = 'fas fa-chalkboard-teacher' ></i>
                            Teachings
                        </button>
                        <button 
                            onClick = { () => history.push('/expressions') }
                            className = 'B-2-button'
                        >
                            <i id = 'b-2-icon' className = 'fas fa-star' ></i>
                            Expressions
                        </button>
                        <button
                            onClick = { () => history.push('/forms')}
                            className = 'B-2-button'
                        >
                            <i id = 'b-2-icon' className = 'fas fa-clipboard-list' ></i>
                            Forms
                        </button>

                    </div>
                </div>
            </div>

            <CallToActions />

            <Statements />

            <GallerySlider />

            <UpcomingEvents />

            <JubileeBlog />

            <FindUs />

            {
                displayVerse ?

                    <Verse onClick = {() => {
                        setDisplayVerse(!displayVerse);
                    }} />

                : ''
            }
            <Footer />
        </div>
    )
}

export default Home;