import './footer.css';
import { Link } from 'react-router-dom';
import Logo from '../assets/jubilee-logo-red.png';

const Footer = () => {
    return(
        <div className = 'Footer'>
            <div id = 'footer-1'>

                <div className = 'Footer-item'>
                    <h1>LINKS</h1>
                    <div className = 'Footer-link-holder'>
                        <Link className = 'Footer-link' to = '/ourchurch'>About Us</Link>
                    </div>
                    <div className = 'Footer-link-holder'>
                        <Link className = 'Footer-link' to = '/fellowships'>Fellowships</Link>
                    </div>
                    <div className = 'Footer-link-holder'>
                        <Link className = 'Footer-link' to = '/'>Internet Bible</Link>
                    </div>
                    <div className = 'Footer-link-holder'>
                        <Link className = 'Footer-link' to = '/sermons'>Messages and More</Link>
                    </div>
                </div>

                <div className = 'Footer-item'>
                    <h1>FOR YOU</h1>
                    <div className = 'Footer-link-holder'>
                        <Link className = 'Footer-link' to = '/'>Live Stream</Link>
                    </div>
                    <div className = 'Footer-link-holder'>
                        <Link className = 'Footer-link' to = '/devotional'>Devotionals</Link>
                    </div>
                    <div className = 'Footer-link-holder'>
                        <Link className = 'Footer-link' to = '/blogs'>Our Blog</Link>
                    </div>
                    <div className = 'Footer-link-holder'>  
                        <Link className = 'Footer-link' to = '/prayerrequest'>Prayer Request</Link>
                    </div>
                </div>

            </div>

            <div id = 'footer-2'>
                <div className = 'Footer-item'>
                    <img id = 'logo' src = { Logo } alt = 'The logo' />
                    <p id = 'footer-address'>RCCG Jubilee Christian Center. <br />15, Williams close, Aguda Surulere. <br /> Lagos State, Nigeria.</p>
                    <div>
                        <a href = 'https://facebook.com/experiencejubilee'>
                            <i id = 'footer-facebook' className = 'fab fa-facebook'></i>
                        </a>
                        <a href = 'https://instagram.com/experiencejubilee'>
                            <i id = 'footer-instagram' className = 'fab fa-instagram'></i>
                        </a>
                        <a href = 'https://twitter.com/jubileetweet'>
                            <i id = 'footer-twitter' className = 'fab fa-twitter'></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;