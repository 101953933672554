import './gallery.css';
import Nav from '../../nav/nav';
import Footer from '../../footer/footer';
import { useState } from 'react';
import { useRef } from 'react';
import { apiErrorHandler, requests } from '../../../api';
import Loading from '../../loading/loading';
import BlankModal from '../../modal/blank-modal/blank-modal';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';


const AdminGallery = ({user}) => {

    let galleryCaptionInput = useRef();
    let galleryImageInput = useRef();
    const history = useHistory();
    const [ image, setImage ] = useState();
    const [ loading, setLoading ] = useState(false);
    const [ blogAdded, setBlogAdded ] = useState(false);
    const [ g2G, setG2G ] = useState(false);
    const [ galleryContent, setGalleryContent ] = useState({
        caption: '',
        uri: ''
    })
    const [ blogNotAdded, setBlogNotAdded ] = useState(false);
    const [ imageError , setImageError ] = useState(false);
    const [ galleryBody, setGalleryBody ] = useState({
    });

    let mediaType = 'cover';
    let categoryList;

    const galleryUploadHandler = async () => {
        try{
            const response = await requests.postGallery(galleryBody)
            setBlogAdded(true);
        }catch(error){
            const { message } = apiErrorHandler(error);
            setBlogNotAdded(true);
        }finally{
            setLoading(false);
            galleryCaptionInput.current.value = '';
            galleryImageInput.current.value = '';
            setGalleryContent({
                ...galleryContent, uri: '', caption: ''
            })
        }
    }


    const generateMediaURiHandler = async (image) => {

        const formData = new FormData()
            formData.append(
            "file",
            image
        )

        try{
            const response = await requests.generateMediaUri(mediaType, formData);
            setGalleryContent({
                ...galleryContent, uri: response.data.resourceUrl
            });
            setG2G(true);
            setImageError(false);
        }catch(error){
            const { message } = apiErrorHandler(error);
            setImageError(true);
        }
    }

    useEffect( () => {

        window.scrollTo(0, 0);

        setGalleryBody({
            ...galleryBody, images: [ galleryContent ]
        })
        

    }, [ galleryContent.uri !== '' && galleryContent.caption !== '' ])

    return(
        <div className = 'Verse-of-the-day'>
            <Nav />

            <div id = 'user-holder'>
                <button className = 'Admin-back-button' onClick = { () => history.goBack() }>Back</button>
                <p id = 'user'>Hi, { user?.firstName }</p>
            </div>

            <div className = 'Admin-content'>
                <h2 className = 'Admin-title'>Jubilee Gallery</h2>
                <label className = 'Admin-label' htmlFor = 'first name'>Caption</label>
                <input
                    onChange = { (e) => {
                        setGalleryContent({
                            ...galleryContent, caption: e.target.value
                        })
                    }}
                    className = 'Input'
                    placeholder = 'Enter image caption here' 
                    ref = { galleryCaptionInput }
                />
                <label className = 'Admin-label' htmlFor = 'first name'>Image</label>
                <div className = 'Admin-multiple-holder'>
                    <input
                        onChange = { (e) => setImage( e.target.files[0] )}
                        className = 'Input' 
                        type = 'file'
                        ref = { galleryImageInput }
                    />
                    <button
                        onClick = { () => {
                          generateMediaURiHandler(image);
                        }}
                        id = 'multiple-button' className = 'Admin-button'
                        disabled = { galleryContent.caption == '' }
                        style = { galleryContent.caption == '' ? { opacity: '0.4' } : {}}
                    >
                        Upload
                    </button>
                </div>

                {
                    imageError === true ?

                    <p id = 'fellowship-image-error'>Image size should not exceed 2MB.</p>

                    : ''
                }

                <p style = {{ textAlign: 'start' }}>{ `${ galleryContent.uri !== '' ?  '1 Image added' : 'No image added' }`}</p>
                { galleryContent.uri}
                <div className = 'Admin-button-holder'>
                    <button
                        onClick ={ () => {
                            galleryUploadHandler();
                            setLoading(true);
                            
                        }}
                        className = 'Admin-button'
                        disabled = { g2G == false }
                        style = { g2G == false ? { opacity: '0.4' } : {} }
                    >
                        Post Image
                    </button>
                </div>
            </div>


            <Footer />

            {
                loading == true ?

                <Loading />

                : ''
            }

            {
                blogAdded == true ?

                <BlankModal>
                    <i style = {{ color: 'green' }} className = 'fas fa-check-circle Modal-icon'></i>
                    <h3 style = {{ marginTop: '1rem', marginBottom: '1rem' }}>Image Added successfully</h3>
                    <button 
                        className = 'Admin-button'
                        onClick = { () => {
                            setBlogAdded(false);
                        } }
                    >
                        close
                    </button>
                </BlankModal>

                : ''
            }

            {
                blogNotAdded == true ?

                <BlankModal>
                    <i style = {{ color: 'red' }} className = 'fas fa-exclamation Modal-icon'></i>
                    <h3 style = {{ marginTop: '1rem', marginBottom: '1rem' }}>Oooop! Error adding image. Please try again.</h3>
                    <button 
                        className = 'Admin-button'
                        onClick = { () => {
                            setBlogNotAdded(false);
                        } }
                    >
                        close
                    </button>
                </BlankModal>

                : ''
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return{
        user: state.user
    }
}

export default connect(mapStateToProps)(AdminGallery);